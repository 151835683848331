package views

import DocsCache
import androidx.compose.runtime.*
import controls.Di
import controls.Icon
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.DocType
import net.sergeych.intecowork.doc.IcwkDocument
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.Div

@Composable
fun FolderTree(
    currentId: Long?,
    showLastContents: Boolean = false,
    showIcons: Boolean = false,
    onSelected: (Long?) -> Unit
) {
    val path = remember { mutableStateListOf<IcwkDocument?>() }
    val currentFolderContent = remember { mutableStateListOf<IcwkDocument>() }
    var ready by remember { mutableStateOf(false) }

    val step = 0.7.em

    LaunchedEffect(currentId) {
        ready = false
        path.clear()
        var f = currentId?.let { DocsCache.getDoc(it) }
        while (f != null) {
            path.add(0, f)
            f = f.parentId?.let { DocsCache.getDoc(it) }
        }
        path.add(0, null)
        ready = true
    }
    if (showLastContents) {
        LaunchedEffect("cf", currentId) {
            launch {
                DocsCache.openFolder(currentId)?.collect {
                    currentFolderContent.clear()
                    currentFolderContent.addAll(it)
                }
            }
        }
    }

//    if (!ready) {
//        Icon.ArrowRepeat.render("d-inline", "me-0", "pe-0", "spin2")
//    }
    for ((i, d) in path.withIndex()) {
        Div({
            style {
                paddingLeft(step * (i + 1))
            }
        }) {
            val isLast = i == path.lastIndex
            Di("cursor-pointer", {
                onClick {
                    onSelected(d?.docId)
                }
            }) {
                if (showIcons) Icon.FolderOpen.render("p-0", "m-0", "me-1")
                +(d?.titleOrDefault ?: "/")
            }
            if (isLast && showLastContents) {
                for (f in currentFolderContent.filter { it.type == DocType.Folder }) {
                    Div({
                        style {
                            paddingLeft(step)
                        }
                        classes("cursor-pointer")
                        onClick {
                            onSelected(f.docId)
                        }
                    }) {
                        if (showIcons) Icon.FolderFill.render("p-0", "m-0", "me-1")
                        +f.titleOrDefault
                    }
                }
            }
        }
    }
}