package views

import Router
import androidx.compose.runtime.*
import client
import controls.*
import net.sergeych.intecowork.api.ApiStorage
import net.sergeych.intecowork.api.ApiUser
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Text
import tools.rememberDebouncer
import tools.toDataSize
import withConfirm
import kotlin.time.Duration.Companion.seconds

@Composable
operator fun CharSequence.unaryPlus() {
    Text(this.toString())
}

@Composable
fun Profile() {
    var stats by mutableStateOf<ApiStorage?>(client.storageState.value)

    LaunchedEffect(true) {
        client.storageState.collect { stats = it }
    }

    LoggedIn { user ->

        var name by remember { mutableStateOf(user.name) }
        var nameState by remember { mutableStateOf<Boolean?>(true) }
        var nameError by remember { mutableStateOf<String?>(null) }

        fun checkName(): Boolean {
            nameError = ApiUser.checkName(name)
            nameState = nameError == null
            return nameError == null
        }

        val debouncer = rememberDebouncer(30.seconds) {
            if (checkName()) {
                nameState = null
                println("debouncer")
                try {
                    name = client.setMyName(name)
                    nameState = true
                } catch (t: Throwable) {
                    nameError = "недопустимое имя"
                    t.printStackTrace()
                    nameState = false
                }
            }
        }

        Di("row") {
            Di("col-12") {
                Heading("Учетная запись")

                Props {
                    p("логин для входа", user.loginName)
                    p("имя, видимое для других пользователей") {
                        textField(name, isValid = nameState, message = nameError, additionalClasses = null,
                            attrs = {
                                onFocusOut {
                                    println("focusout")
                                    debouncer.executeNow()
                                }
                            }) {
                            name = it
                            if (checkName()) {
                                debouncer.schedule()
                                nameState = null
                                nameError = null
                            }
                        }
                    }
                    p("хранилище") {
                        stats?.let { s ->
                            if (s.used >= s.limit) {
                                Di("alert alert-warning mt-1") {
                                    Txt("Хранилище переполнено", "fw-bold")
                                    +"""Установлен режим только для чтения. 
                                Вам нужно дополнительно """
                                    Spa("fw-bold") {
                                        +(s.used - s.limit).toDataSize()
                                    }
                                    +" "
                                    +"""
                                хранилища,
                                ваш текущий лимит ${s.limit.let { if (it > 0) it.toDataSize() else "нулевой" }},
                                использовано ${s.used.toDataSize()}.
                            """.trimIndent()
                                    +" "
                                    BtnLink("Активируйте код", alertLink = true) {
                                        ModalCodeActivation()
                                    }
                                    +" для увеличения лимита хранения ("
                                    RouterLink(
                                        "/help_locked",
                                        "подробнее о заблокированном состоянии",
                                        alertLink = true
                                    )
                                    +")."
                                }
                            } else {
                                Text("использовано ${s.used.toDataSize()} из  ${s.limit.toDataSize()}, или ${s.percentUsed}%")
                            }
                        } ?: Di("spinner-border spinner-sm") {}
                    }
                    p("подписка") {
                        ListActiveCodes()
                        Btn("Активировать код", Icon.CheckLg, outline = true, mt = 2) {
                            ModalCodeActivation()
                        }
                    }
                    p("") {
                        Hr()
                        Btn("выйти из системы", Icon.BoxArrowLeft, Variant.Secondary, mt = 2) {
                            withConfirm(
                                "Вы действительно хотите выйти из системы?",
                            ) {
                                Toaster.launchCatching {
                                    client.logout()
                                    info("выполнен выход из учетной записи")
                                }
                            }
                        }
                    }
                    p("") {
                        Hr()
                        Di("float-end") {
                            Btn("удалить учетную запись", Icon.Danger, Variant.Danger, mt = 2) {
                                Router.push("/profile/delete")
                            }
                        }
                    }
                }
            }
        }
    }
}
