package views

import androidx.compose.runtime.*
import controls.*
import kotlinx.browser.window
import net.sergeych.intecowork.api.ApiUserDetails
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement

private enum class HomeGuestMode {
    Landing, Registration, Authentication
}

@Composable
fun LoggedIn(useFluid: Boolean = false, f: @Composable ElementScope<HTMLDivElement>.(ApiUserDetails) -> Unit) {
    var mode by remember { mutableStateOf(HomeGuestMode.Landing) }

    @Composable
    fun backButton() {
        Bn({
            classes("btn-link")
            onClick { mode = HomeGuestMode.Landing }
        }) {
            Text("отмена")
        }

    }
    val footer: ContentBuilder<HTMLDivElement> = {
        Di("p-0 m-0 mt-5 mb-3 w-100", {
            style {
                position(Position.Absolute)
                left(0.px)
                right(0.px)
//                height(30.percent)
            }
        }) {
            Hr()
            Di("container mt-3 mb-4") {
                Table({
                    classNames("w-100 d-none d-md-table")
                }) {
                    Tbody {
                        Tr {
                            Td({
                                style {
                                    width(33.percent)
                                    whiteSpace("nowrap")
                                }
                            }) {
                                linkToBTLA()
                            }
                            Td {
                                linkToBugTracker("w-100 text-center")
                            }
                            Td({
                                style {
                                    width(33.percent)
                                    whiteSpace("nowrap")
                                }
                                classes("text-end")
                            }) {
                                linkToTelegram()
                            }
                        }
                    }
                }
                Di("d-md-none text-center") {
                    linkToBTLA()
                    linkToBugTracker()
                    linkToTelegram()
                }
            }
        }
    }

    Template(useFluid, footerNotLoggedIn = if( mode == HomeGuestMode.Landing ) footer else null) { currentUser ->
        if (currentUser != null)
            f(currentUser)
        else {
//            CenteredNarrowForm {
            if (mode != HomeGuestMode.Landing) {
                Di("text-center") {
                    H1 {
                        Img("/logo-candy1.svg") {
                            style {
                                height(1.1.em)
                            }
                            classes("me-1")
                        }
                        Text("доколлабр.рф")
                    }
                    P({ classes("text-secondary") }) { Text("Бета верcия проекта") }
                }
            }
            when (mode) {
                HomeGuestMode.Landing ->
                    LandingPage { mode = it }

                HomeGuestMode.Registration -> Registration {
                    Bn({
                        classes("btn-success")
                        onClick { mode = HomeGuestMode.Authentication }
                    }) {
                        Text("Уже зарегистрирован")
                    }
                    backButton()
                }

                HomeGuestMode.Authentication -> Authentication {
                    Bn({
                        classes("btn-secondary")
                        onClick { mode = HomeGuestMode.Registration }
                    }) {
                        Text("Зарегистрироваться")
                    }
                    backButton()
                }
//            }
            }
        }
    }
}

@Composable
fun linkToTelegram() {
    A("https://t.me/docollabr", {
        target(ATarget.Blank)
        classes("text-dark")
    }) {
        +"Tелеграм канал"
    }
}

@Composable
fun linkToBugTracker(styles: String?=null) {
    Div({
        styles?.let { classNames(it)}
    }) {
        A("mailto:help@docollabr.ru", {
            classNames("text-dark")
        }) {
            +"Cообщить об ошибке"
        }
    }
}

@Composable
fun linkToBTLA() {
    A("https://docollabr.ru/De-d8c8tp70#TnshSoVLf99t3S_aq7MeXaGVFa7Hy7D64nJoDPsEXeY", {
        target(ATarget.Blank)
        classes("text-dark")
    }) {
        +"Cоглашение бета-тестера"
    }
}

@Composable
private fun LandingPage(setMode: (HomeGuestMode) -> Unit) {
    Di("text-center") {
        Img("/logo-candy1.svg") {
            classNames("mt-5 mb-3")
            style {
                width(9.em)
            }
        }
    }
    Di("text-center fw-bold m-2") {
        +"Приглашаем вас к бета-тестированию текстового редактора для совместной работы"
    }
    Di("text-center fs-2 fw-bold m-3") {
        +"Docollabr"
    }
    P {
        +"Мы разработали "
        Bold("До")
        +"кументы с "
        Bold("коллаб")
        +"о"
        Bold("р")
        +"""ацией, текстовый редактор для совместной работы, на базе сквозного шифрования.
            Мы уделили максимум внимания безопасности, чтобы содержание ваших документов было доступно 
            только вам, и тем, с кем вы сами решите поделиться доступом. Провайдеры, операторы, 
            все прочие, и даже мы сами, никогда не сможем узнать что же содержится в ваших документах.
        """.trimIndent()
    }
    P {
        +"""
            Все шифрование происходит на вашем устройстве, поэтому документы открываются чуть дольше. 
            Мы сделали свой редактор так, чтобы исключить возможность утечки информации из ваших документов,
            Это было непросто. В нем есть пока ошибки, поэтому нам и нужна ваша помощь, чтобы сделать его лучше.
        """.trimIndent()
    }
    P {
        +"""
            Мы знаем, что люди чаще пользуются мобильными устройствами, чем ПК. Тем не менее, в создания текстовых 
            документов ключевую роль занимают именно персональные компьютеры. 
            На данный момент мы не готовы предложить полноценное редактирование текста с мобильных устройств,
            но уже сейчас на любом устройстве вы можете читать документы, и управлять доступом к ним, в том числе
            и удалять их. 
        """.trimIndent()
    }
    P {
        +"""
            Чтобы приступить к тестированию подпишитесь на наш телеграм канал, чтобы получить уведомления об 
            изменениях:
        """.trimIndent()
    }
    Di("w-100 text-center my-3") {
        Btn("Подписаться на телеграм канал", Icon.Send, Variant.Primary) {
            window.open("https://t.me/docollabr", target = "")
        }
    }
    P {
        +"""
            Мы ценим вклад каждого, кто помогает нам с улучшением продукта в период бета-тестирования, 
            и обещаем приятное вознаграждение для тех, кто пойдет с нами по этому пути!
        """.trimIndent()
    }
    P {
        +"""
            Мы постоянно работаем над улучшением продукта, и во время работы вы можете столкнуться с 
            определенными ограничениями. Мы еще раз просим вас: 
        """.trimIndent()
        A("https://docollabr.ru/De-d8c8tp70#TnshSoVLf99t3S_aq7MeXaGVFa7Hy7D64nJoDPsEXeY", {
            target(ATarget.Blank)
        }) {
            +" пожалуйста, прочитайте соглашение бета-тестера"
        }
    }
    Di("m-3 fw-bold text-center") {
        +"""
            Теперь, когда вы его прочитали, давайте приступим к регистрации:
        """.trimIndent()
    }

    Row {
        Col("sm-6", { classes("mb-2") }) {
            Bn({
                primary("w-100")
                onClick { setMode(HomeGuestMode.Registration) }
            }) {
                Text("Регистрация")
            }
        }
        Col("sm-6") {
            Bn({
                success("w-100")
                onClick { setMode(HomeGuestMode.Authentication) }
            }) {
                Text("Существующий тестер")
            }
        }
    }
}


