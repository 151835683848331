package document

import editor.*
import editor.operations.AUDTransaction
import kotlinx.coroutines.flow.*
import net.sergeych.intecowork.api.BlockType
import net.sergeych.intecowork.doc.DocBlock
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.mptools.withReentrantLock
import tools.randomId

val docLogger = PackageLogger("DOC MODIFY", "OrangeRed", DebugList.save)
/**
 * document is a set of block. It __coult not be serialized__ because we store blocks separately in an encrypted
 * storage. So document holds a registry of blocks, the cache and a way to load blocks by request. Slo it hoslds
 * the unused blocks until saving them to the storage.
 */
class Doc {

    //    class Registry : Mergeable<Registry> {
//
//    }
    sealed class Event {
        abstract val block: Block

        data class NewBlock(override val block: Block) : Event()
        data class UpdateBlock(override val block: Block) : Event()
        data class DeleteBlock(override val block: Block) : Event()
        data class RedrawBlock(override val block: Block, val caret: Caret?, val style: TextStyle?) : Event()
        data class UpdateCaretBlock(override val block: Block, val caret: Caret?) : Event()
    }

    private val mutexQueue = DocQueueAndMutex()

    // Use QueueAndMutext instead of events:
//    private val _events = MutableSharedFlow<Event>(extraBufferCapacity = 50)
    private val _events = mutexQueue

    val events: SharedFlow<Event> = _events

    private var dirty = mutableSetOf<String>()
    // Use QueueAndMutext instead of the mutex too:
//    val mutex = Mutex()
    val mutex = mutexQueue

    private val _docIsDirty = MutableStateFlow<Boolean>(false)
    val docIsDirty: StateFlow<Boolean> = _docIsDirty.asStateFlow()

    val firstBlock: Block
        get() {
            if (isEmpty()) throw IllegalStateException("empty doc has no root paragraph")
            checkSanity()
            return this[firstBlockGuid!!]
        }
    val lastBlock: Block
        get() {
            if (isEmpty()) throw IllegalStateException("empty doc has no root paragraph")
            checkSanity()
            return this[lastBlockGuid!!]
        }
    private val cachedBlocks = mutableMapOf<String, Block>()

    private val guids = mutableSetOf<String>()

    private var firstBlockGuid: String? = null

    private var lastBlockGuid: String? = null

    fun appendBlock(_block: Block) {
        var block = _block
        checkSanity(block)
        val guid = block.guid
        if (firstBlockGuid == null) {
            firstBlockGuid = guid
            lastBlockGuid = guid
            block = block.copy(prevBlockGuid = null, nextBlockGuid = null)
        } else {
            block = block.copy(prevBlockGuid = lastBlockGuid, nextBlockGuid = null)
            updateBlock(lastBlockGuid!!) { it.copy(nextBlockGuid = guid) }
            lastBlockGuid = guid
        }
        addToStructure(block)
        _events.smartEmit(Event.NewBlock(block))
    }

    fun insertBlockAfter(existingBlockGuid: String?, newBlock: Block, shouldSetDirty: Boolean = true): Block {
//        console.log("DOC: insert ${newBlock.guid} after ${existingBlockGuid}")
        var nextBlockGuid: String? = null
        var b: Block? = null

        if (existingBlockGuid != null) {
            // insert after given block
            val prevBlock = this[existingBlockGuid]
            nextBlockGuid = prevBlock.nextBlockGuid

            updateBlock(prevBlock.guid, shouldSetDirty = shouldSetDirty) {
                it.copy(nextBlockGuid = newBlock.guid)
            }

            b = newBlock.copy(prevBlockGuid = prevBlock.guid, nextBlockGuid = nextBlockGuid)
        } else {
            // insert before first block
            nextBlockGuid = firstBlockGuid

            b = newBlock.copy(prevBlockGuid = null, nextBlockGuid = nextBlockGuid)
            firstBlockGuid = newBlock.guid
        }

        addToStructure(b, shouldSetDirty)
        nextBlockGuid?.let {
            updateBlock(it, shouldSetDirty = shouldSetDirty) {
                it.copy(prevBlockGuid = b.guid)
            }
        } ?: run { lastBlockGuid = newBlock.guid }

        calculateViewProperties()
//        console.log("insertBlockAfter: emit NewBlock(${b.guid})")
        _events.smartEmit(Event.NewBlock(b))
        return b
    }

    // Safe. No need to check links
    fun removeBlockSafe(guid: String, shouldSetDirty: Boolean = true) {
        if (guid !in guids) throw IllegalArgumentException("remove: block is not in doc")
        if (firstBlockGuid == lastBlockGuid && lastBlockGuid == guid) throw BugException("Can't delete only block")

        val block = get(guid)

        if (guid == firstBlockGuid) firstBlockGuid = block.nextBlockGuid
        else if (guid == lastBlockGuid) lastBlockGuid = block.prevBlockGuid

        block.prevBlockGuid?.let {
            var prev = get(it)
            prev = prev.copy(nextBlockGuid = block.nextBlockGuid)
            updateLinks(prev, shouldSetDirty)
        }

        block.nextBlockGuid?.let {
            var next = get(it)
            next = next.copy(prevBlockGuid = block.prevBlockGuid)
            updateLinks(next, shouldSetDirty)
        }

        guids.remove(guid)
        cachedBlocks.remove(guid)
//        calculateViewProperties()
        if (shouldSetDirty) setDirty(guid)
        _events.smartEmit(Event.DeleteBlock(block.copy(prevBlockGuid = null, nextBlockGuid = null)))

//        removeBlock(block, shouldSetDirty)
    }
    // Dangerous: if removing blocks from some list links should be updated in list after each remove
    fun removeBlock(block: Block, shouldSetDirty: Boolean = true) {
        val guid = block.guid
        if (guid !in guids) throw IllegalArgumentException("remove: block is not in doc")

        if (firstBlockGuid == lastBlockGuid && lastBlockGuid == guid) {
            val emptyText = Fragment.StyledSpan(text = "")
            val emptyParagraph = Fragment.Paragraph(paragraphStyle = ParagraphStyle.heading, elements = listOf(emptyText))
            updateBlock(block.copy(paragraph = emptyParagraph), shouldSetDirty)
            return
        }

        if (guid == firstBlockGuid) firstBlockGuid = block.nextBlockGuid
        else if (guid == lastBlockGuid) lastBlockGuid = block.prevBlockGuid
        block.prevBlockGuid?.let {
            updateBlock(it, shouldSetDirty = shouldSetDirty) {
                it.copy(nextBlockGuid = block.nextBlockGuid)
            }
        }
        block.nextBlockGuid?.let {
            updateBlock(it, shouldSetDirty = shouldSetDirty) {
                it.copy(prevBlockGuid = block.prevBlockGuid)
            }
        }
        guids.remove(guid)
        cachedBlocks.remove(guid)
        calculateViewProperties()
        if (shouldSetDirty) setDirty(guid)
        _events.smartEmit(Event.DeleteBlock(block.copy(prevBlockGuid = null, nextBlockGuid = null)))
    }

    fun updateBlock(guid: String, shouldRecalculate: Boolean? = false, shouldSetDirty: Boolean = true, f: (Block) -> Block) {
        f(get(guid)).also {
            cachedBlocks[guid] = it
            if (shouldRecalculate == true) calculateViewProperties()
            _events.smartEmit(Event.UpdateBlock(it))
        }
        if (shouldSetDirty) setDirty(guid)
    }

    fun updateLinks(block: Block, shouldSetDirty: Boolean = true) {
        cachedBlocks[block.guid] = block
        _events.smartEmit(Event.UpdateBlock(block))

        if (shouldSetDirty) setDirty(block.guid)
    }

    fun updateBlockSafe(block: Block, shouldSetDirty: Boolean = true) {
        val guid = block.guid
        if (guid !in guids)
            throw BadStructureException("can't update block: not in doc")

        val existing = get(guid)
        val updated = block.copy(prevBlockGuid = existing.prevBlockGuid, nextBlockGuid = existing.nextBlockGuid)

        cachedBlocks[guid] = updated
        calculateViewProperties()
        _events.smartEmit(Event.UpdateBlock(updated))

        if (shouldSetDirty) setDirty(guid)
    }

    /**
     * update a block in the document, flag it to save to storage
     */
    fun updateBlock(block: Block, shouldSetDirty: Boolean = true) {
        val guid = block.guid
        if (guid !in guids)
            throw BadStructureException("can't update block: not in doc")
        cachedBlocks[guid] = block
        calculateViewProperties()
        _events.smartEmit(Event.UpdateBlock(block))

        if (shouldSetDirty) setDirty(guid)
    }

    fun sendRedrawBlock(block: Block, ct: DocContext) {
        _events.smartEmit(Event.RedrawBlock(block, if (ct.showCaret) ct.caret else null, ct.defaultTextStyle))
    }

    fun sendUpdateBlock(block: Block) {
        _events.smartEmit(Event.UpdateBlock(block))
    }

    fun sendCaretBlockUpdate(caretBlock: Block, c: Caret?) {
        _events.smartEmit(Event.UpdateCaretBlock(caretBlock, c))
    }

    fun calculateViewProperties() {
        val list = allBlocks
        val guidsToUpdate = mutableSetOf<String>()

        list.forEach {
            guidsToUpdate += it.calculateViewProperties(list)?.toSet() ?: emptySet()
        }

        guidsToUpdate.forEach {
            if (it in guids) {
                _events.smartEmit(Event.RedrawBlock(get(it), null, null))
            }
        }
    }

    fun printState() {
        println("doc state: $firstBlockGuid-$lastBlockGuid")
        println("  blocks: ${guids.size} / ${cachedBlocks.size}")
    }

    operator fun get(guid: String): Block {
        cachedBlocks[guid]?.let { return it }
        if (guid !in guids) {
            println("failed to get block ${guid}")
            println("doc")
            guids.forEach { println(it) }
            println("/doc")
            throw BlockNotFoundException("not in this doc")
        }
        TODO("load from storage")
    }

    fun safeGet(guid: String): Block? {
        return cachedBlocks[guid]
    }

    private fun addToStructure(block: Block, shouldSetDirty: Boolean = true) {
        val guid = block.guid
        guids.add(guid)
        cachedBlocks[guid] = block

        if (shouldSetDirty) setDirty(guid)
    }

    fun initializeWithBlocks(blocks: List<Block>) {
        blocks.forEach { block ->
            val guid = block.guid
            guids.add(guid)
            cachedBlocks[guid] = block
        }

        firstBlockGuid = blocks.first().guid
        lastBlockGuid = blocks.last().guid
    }

    private fun setDirty(guid: String) {
        dirty += guid
        if (!_docIsDirty.value) _docIsDirty.value = true
    }

    fun resetDirty() {
        dirty.clear()
        _docIsDirty.value = false
    }

    fun isDirty(): Boolean {
        return !dirty.isEmpty()
    }

    suspend fun withLock(name: String = "default", block: suspend () -> Unit) {
        val s = randomId(3)
        if (DebugList.docLock) console.log("[LOCK ${name}:$s]: wait")
        mutex.withReentrantLock {
            if (DebugList.docLock) console.warn("[LOCK ${name}:$s]: run")
            block()
            if (DebugList.docLock) console.warn("[LOCK ${name}:$s]: done")
        }
        if (DebugList.docLock) console.log("[LOCK ${name}:$s]: released")
    }

    fun checkSanity(newBlock: Block? = null) {
        newBlock?.let { b ->
            if (b.guid in guids)
                throw BadStructureException("already in doc")
        }
        if ((lastBlockGuid == null && firstBlockGuid != null) ||
            firstBlockGuid != null && lastBlockGuid == null
        )
            DocException("first/last invalid: first=$firstBlockGuid last=$lastBlockGuid")
    }

    fun append(builder: Builder.() -> Unit) {
        Builder(this).builder()
    }

    fun isEmpty(): Boolean = guids.isEmpty()

    fun isBlank(): Boolean {
        for (b in blocks) {
            console.log("isBlank: $b : [${b.plainText}]")
            console.log("       : ${b.isBlank}")
            if (!b.isBlank) return false
        }
        return true
    }

    enum class Hint {
        NoTitle, NoBody
    }

    fun userHint(): Hint? {
        val bb = blocks.asSequence().take(3).toList()
        if( bb.size == 1 )
            return if( bb.first().isBlank ) Hint.NoTitle else null
        if( bb.size == 2 )
            if( !bb[0].isBlank && bb[1].isBlank ) return Hint.NoBody
        return null
    }

    fun hasTitleOnly(): Boolean {
        val beginning: List<Block> = blocks.asSequence().take(3).toList()
        return beginning.size == 2 && beginning[1].isBlank
    }

    fun nextBlock(from: Block?): Block? {
        return from?.let { it.nextBlockGuid?.let { this[it] } }
    }

    fun prevBlock(from: Block?): Block? {
        return from?.let { it.prevBlockGuid?.let { this[it] } }
    }

    fun nextBlockFocusable(from: Block?): Block? {
        var b = nextBlock(from)
        while(b != null && b.isFocusable == false) b = nextBlock(b)

        return b
    }

    fun prevBlockFocusable(from: Block?): Block? {
        var b = prevBlock(from)
        while(b != null && b.isFocusable == false) b = prevBlock(b)

        return b
    }

    val blocks: Iterator<Block>
        get() = object : Iterator<Block> {

            private var nextGuid: String? = firstBlockGuid

            override fun hasNext(): Boolean = nextGuid != null

            override fun next(): Block = get(nextGuid!!).also { nextGuid = it.nextBlockGuid }

        }

    val allBlocks get() = mutableListOf<Block>().also { for (x in blocks) it.add(x) }

    val allBlocksCopy get() = allBlocks.map { it.copy() }

    companion object {

        class Builder(val doc: Doc = Doc(), private val style: ParagraphStyle? = null) {

            fun p(newStyle: ParagraphStyle? = null, pb: SpanBuilder.() -> Unit) {
                val root = SpanBuilder(Fragment.Paragraph(listOf(), newStyle ?: style)).also { it.pb() }.build()
                doc.appendBlock(Block(root))
            }

            fun title(pb: SpanBuilder.() -> Unit) {
                val root = SpanBuilder(
                    Fragment.Paragraph(
                        listOf(),
                        ParagraphStyle(defaultTextStyle = TextStyle.heading)
                    )
                ).also { it.pb() }.build()
                doc.appendBlock(Block(root))
            }

            fun h1(pb: SpanBuilder.() -> Unit) {
                val root = SpanBuilder(
                    Fragment.Paragraph(
                        listOf(),
                        ParagraphStyle(defaultTextStyle = TextStyle.heading1)
                    )
                ).also { it.pb() }.build()
                doc.appendBlock(Block(root))
            }

            fun h2(pb: SpanBuilder.() -> Unit) {
                val root = SpanBuilder(
                    Fragment.Paragraph(
                        listOf(),
                        ParagraphStyle(defaultTextStyle = TextStyle.heading2)
                    )
                ).also { it.pb() }.build()
                doc.appendBlock(Block(root))
            }

            fun h3(pb: SpanBuilder.() -> Unit) {
                val root = SpanBuilder(
                    Fragment.Paragraph(
                        listOf(),
                        ParagraphStyle(defaultTextStyle = TextStyle.heading3)
                    )
                ).also { it.pb() }.build()
                doc.appendBlock(Block(root))
            }

            fun h4(pb: SpanBuilder.() -> Unit) {
                val root = SpanBuilder(
                    Fragment.Paragraph(
                        listOf(),
                        ParagraphStyle(defaultTextStyle = TextStyle.heading4)
                    )
                ).also { it.pb() }.build()
                doc.appendBlock(Block(root))
            }

            fun img(url: String) {
                val root = Fragment.Paragraph(
                    listOf(
//                    Fragment.StyledSpan(" ",),
                        Fragment.LinkedImage(url),
                        Fragment.StyledSpan("" + invisibleNBSP)
                    )
                )
                println("image root: $root")
                console.log(root)
                doc.appendBlock(Block(root))
//                        Fragment.Paragraph(listOf(Fragment.LinkedImage("/printing_press.jpg")))
//                    )
//                )
            }

        }

        operator fun invoke(doc: Doc = Doc(), builder: Builder.() -> Unit): Doc {
            return Builder(doc).also { it.builder() }.doc
        }
    }
}