package views

import androidx.compose.runtime.*
import client
import controls.*
import net.sergeych.intecowork.api.ApiStorage
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Text
import tools.toDataSize

@Composable
fun StorageInfo() {
    var stats by remember { mutableStateOf<ApiStorage?>(client.storageState.value) }

    LaunchedEffect(true) {
        client.storageState.collect {
            stats = it
        }
    }

    stats?.let { s ->
        if (s.used >= s.limit) {
            Di("alert alert-warning mt-1") {
                Txt("Хранилище переполнено", "fw-bold")
                +"""Установлен режим только для чтения. 
                                Вам нужно дополнительно """
                Spa("fw-bold") {
                    +(s.used - s.limit).toDataSize()
                }
                +" "
                +"""
                                хранилища,
                                ваш текущий лимит ${s.limit.let { if (it > 0) it.toDataSize() else "нулевой" }},
                                использовано ${s.used.toDataSize()}.
                            """.trimIndent()
                +" "
                BtnLink("Активируйте код", alertLink = true) {
                    ModalCodeActivation()
                }
                +" для увеличения лимита хранения ("
                RouterLink(
                    "/help_locked",
                    "подробнее о заблокированном состоянии",
                    alertLink = true
                )
                +")."
            }
        } else {
            Di("alert alert-light small text-center") {
                Text("использовано ${s.used.toDataSize()}")
                Br()
                Text("Свободно ${s.available.toDataSize()}, ${s.available * 100 / s.limit}%")
            }
        }
    } ?: Di("alert alert-light small text-center") {
        Text("загружаю информацию…")
    }

}