package net.sergeych.parsec3

import net.sergeych.mp_tools.decodeBase64Compact
import net.sergeych.mp_tools.encodeToBase64Compact
import org.w3c.dom.Storage
import org.w3c.dom.set

/**
 * Default KV storage in browser. Use if with `localStorage` or `sessionStorage`. Uses
 * prefix for storage values to not to collide with other data
 */
class BrowserKVStorage(keyPrefix: String, private val bst: Storage) : KVStorage {

    private val prefix = "$keyPrefix:"
    fun k(key: String) = "$prefix$key"
    override fun get(key: String): ByteArray? {
        return bst.getItem(k(key))?.decodeBase64Compact()
    }

    override fun set(key: String, value: ByteArray?) {
        val corrected = k(key)
        if (value == null)
            bst.removeItem(corrected)
        else
            bst.set(corrected, value.encodeToBase64Compact())
    }

    override val keys: Set<String>
        get() {
            val kk = mutableListOf<String>()
            for (i in 0 until bst.length) {
                val k = bst.key(i) ?: break
                if( k.startsWith(prefix)) {
                    kk += k.substring(prefix.length)
                }
            }
            return kk.toSet()
        }
}