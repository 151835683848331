package net.sergeych.intecowork.api

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlin.math.floor

@Serializable
data class ApiStorage(
    val limit: Long,
    val used: Long,
    val expiresAt: Instant? = null
) {
    val available = limit - used
    val percentUsed by lazy { floor(used * 100.0 / limit).toInt() }

    val percentUsedString by lazy { "$percentUsed%"}
}

