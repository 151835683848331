package net.sergeych.intecowork.api

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class ApiEvent {

    /**
     * true if this event _is likely caused storage usage change_.
     */
    val isStorageChangingEvent: Boolean by lazy {
        when (this) {
            is User.LimitsChanged, is User.CodeActivated,
            is Doc.New, is Doc.Erased, is Doc.BodyChanged -> true
            else -> false
        }
    }

    @Serializable
    @SerialName("ping")
    data class Ping(val serverTime: Instant) : ApiEvent()

    @Serializable
    @SerialName("user")
    sealed class User : ApiEvent() {
        @Serializable
        @SerialName("storage")
        data class LimitsChanged(
            val storage: ApiStorage,
            val nextCheckTime: Instant
        ) : User()

        @Serializable
        @SerialName("activated")
        data class CodeActivated(val codeId: Long) : User()
    }

    @Serializable
    @SerialName("doc")
    sealed class Doc : ApiEvent() {

        abstract val docId: Long

        @Serializable
        @SerialName("new")
        data class New(override val docId: Long) : Doc()

        @Serializable
        @SerialName("trashed")
        data class Trashed(override val docId: Long) : Doc()

        @Serializable
        @SerialName("restored")
        data class Restored(override val docId: Long) : Doc()

        @Serializable
        @SerialName("erased")
        data class Erased(override val docId: Long) : Doc()

        @Serializable
        @SerialName("meta")
        data class MetaChanged(override val docId: Long, val lastSerial: Long) : Doc()

        @Serializable
        @SerialName("shr")
        data class ShareChanged(override val docId: Long, val isShared: Boolean) : Doc()

        @Serializable
        @SerialName("changed")
        data class BodyChanged(override val docId: Long, val lastSerial: Long) : Doc()
    }
}