package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.document
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement

@Composable
fun Authentication(additionalContent: ContentBuilder<HTMLDivElement>) {
    var loginName by remember { mutableStateOf("") }
//    var loginName by remember { mutableStateOf("testUser") }
    var loginError by remember { mutableStateOf<Boolean>(false) }
    var password by remember { mutableStateOf("") }
//    var password by remember { mutableStateOf("12345qwert") }
    var inProgress by remember { mutableStateOf(false) }

    fun startLogin() {
        inProgress = true
        globalLaunch {
            if (!client.login(loginName, password))
                Toaster.error("неправильный логин и/или пароль")
            inProgress = false
        }
    }

    fun canLogin(): Boolean = !(loginError || loginName.isBlank() || password.isBlank() || inProgress)

    if (inProgress) {
        WaitMessage("Аутентификация")
    } else {
        Div {
            H3 { Text("Вход:") }
            val loginId = textField(
                loginName,
                "Имя учетной записи 1",
                message = if (loginError) "не должно содержать пробелов в начале и конце" else null,
                attrs = { onKeyUp { if (it.getNormalizedKey() == "Enter") startLogin() } }
            ) {
                loginName = it
                loginError = loginName.trim() != loginName
            }
            textField(
                password,
                "Пароль",
                type = InputType.Password,
                attrs = { onKeyUp { if (it.getNormalizedKey() == "Enter") startLogin() } }
            ) {
                password = it
            }
            Bn({
                classNames("btn-primary me-2")
                if (!canLogin())
                    disabled()
                onClick {
                    startLogin()
                }
            }) {
                Text("Войти")
            }
            additionalContent()
            LaunchedEffect("loginFocus") {
                (document.getElementById(loginId) as? HTMLInputElement)?.focus()
            }
        }
    }
}
