package document

import editor.Box
import editor.Point
import kotlinx.browser.document
import kotlinx.serialization.Serializable
import kotlin.math.min

@Serializable
sealed interface IParagraph {
    val elements: List<Fragment>
    val children: Map<String, Fragment>
    val paragraphStyle: ParagraphStyle?
    val guid: String
    fun nextFragment(from: Fragment): Fragment?
    fun prevFragment(from: Fragment): Fragment?
    fun calculateViewProperties(blocks: List<Block>): List<String>?
//    fun nextTextFragment(from: Fragment): Fragment?
//    fun prevTextFragment(from: Fragment): Fragment?

    val isVisible: Boolean

    // Fragment declarations start
    val lastOffset: Int
    val isEmpty: Boolean
    val isNotEmpty: Boolean
    val isBlank: Boolean
    val plainText: String

    fun find(byGuid: String): Fragment?
    // Fragment declarations end

    fun caretSpan(caret: Caret): Fragment.StyledSpan {
        val span = find(caret.spanId)
            ?: throw BugException("Caret ${caret} not in IParagraph ${guid}")
        if (span !is Fragment.StyledSpan)
            throw BugException("Caret ${caret} span is not StyledSpan")

        return span
    }

    fun contains(c: Caret): Boolean {
        return c.path.indexOf(guid) != -1
    }

    fun caretContainer(caret: Caret): Fragment.Paragraph {
        val containerId = caret.containerId

        if (guid == containerId) {
            if (this !is Fragment.Paragraph)
                throw BugException("Caret ${caret} container ${containerId} is not Paragraph")
            return this
        }

        val container = find(containerId)
            ?: throw BugException("Caret ${caret} not in IParagraph ${guid}")

        if (container !is Fragment.Paragraph)
            throw BugException("Caret ${caret} container ${containerId} is not Paragraph")

        return container
    }

    fun indexOf(guid: String): Int {
        return elements.indexOfFirst { it.guid == guid }
    }

    fun nextVisibleTextFragment(from: Fragment): Fragment.StyledSpan? {
        val nextFragment = nextTextFragment(from) ?: return null
        return if (nextFragment.isVisible) nextFragment
        else nextVisibleTextFragment(nextFragment)
    }

    fun prevVisibleTextFragment(from: Fragment): Fragment.StyledSpan? {
        val prevFragment = prevTextFragment(from) ?: return null
        return if (prevFragment.isVisible) prevFragment
        else prevVisibleTextFragment(prevFragment)
    }

    fun nextTextFragment(from: Fragment): Fragment.StyledSpan? {
        return nextTextFragment(from.guid)
    }

    fun nextTextFragment(from: String): Fragment.StyledSpan? {
        val nextTerminal = nextTerminalFragment(from) ?: return null
        return if (nextTerminal is Fragment.StyledSpan) nextTerminal
        else nextTextFragment(nextTerminal)
    }

    fun prevTextFragment(from: Fragment): Fragment.StyledSpan? {
        return prevTextFragment(from.guid)
    }

    fun prevTextFragment(from: String): Fragment.StyledSpan? {
        val prevTerminal = prevTerminalFragment(from) ?: return null
        return if (prevTerminal is Fragment.StyledSpan) prevTerminal
        else prevTextFragment(prevTerminal)
    }

    fun getTextFragments(): List<Fragment.StyledSpan> {
        var fragment: Fragment.StyledSpan? = firstTextFragment()
        val fragments = mutableListOf<Fragment.StyledSpan>()

        while(fragment != null) {
            fragments += fragment
            fragment = nextTextFragment(fragment)
        }

        return fragments
    }

    fun getClosestTextFragment(point: Point): Fragment.StyledSpan? {
        val spans = getTextFragments()
        if (spans.isEmpty()) return null

        val spanBoxes = mutableMapOf<String, Box>()

        spans.forEach {
            document.getElementById(it.guid)?.let { el ->
                spanBoxes[it.guid] = Box(el.getBoundingClientRect())
            }
        }

        val closestYDistance = spanBoxes.minOf { it.value.yDistanceTo(point) }
        val spanBoxesY = spanBoxes.filter { it.value.yDistanceTo(point) == closestYDistance }

        spanBoxesY.minByOrNull { it.value.xDistanceTo(point) }?.let {
            val spanId = it.key
            val spanBox = it.value
            val span = find(spanId) as Fragment.StyledSpan

//            console.log("getClosestTextFragment: closest spanBox", span, spanBox.top, spanBox.bottom, point.y)

            return span
        }?: run {
            return null
        }
    }

//    fun normalize(exclude: List<String> = emptyList()): IParagraph?

    fun prevTerminalFragment(from: Fragment): Fragment? {
        return neighbourTerminalFragment(from.guid, false)
    }

    fun nextTerminalFragment(from: Fragment): Fragment? {
        return neighbourTerminalFragment(from.guid, true)
    }

    fun prevTerminalFragment(from: String): Fragment? {
        return neighbourTerminalFragment(from, false)
    }

    fun nextTerminalFragment(from: String): Fragment? {
        return neighbourTerminalFragment(from, true)
    }

    fun neighbourTerminalFragment(from: String, isRightNeighbour: Boolean): Fragment? {
        val fromPath = pathToFragment(from)
        if (fromPath.isEmpty()) return null

        val reversedPath = fromPath.reversed()
        var i = 1

        while (i <= reversedPath.size) {
            val parent = if (i == reversedPath.size) this else reversedPath[i]
            val child = reversedPath[i-1]

            if (parent is IParagraph) {
                val list = if (isRightNeighbour) parent.elements else parent.elements.reversed()

                val childIndex = list.indexOf(child)
                if (childIndex < list.size - 1) {
                    val nextChild = list[childIndex + 1]
                    if (nextChild is IParagraph) {
                        if (isRightNeighbour) return nextChild.firstTerminalFragment()
                        else return nextChild.lastTerminalFragment()
                    }
                    else return nextChild
                }
            }

            i++
        }

        return null
    }

    fun pathToFragment(byGuid: String, isReversedSearch: Boolean? = false): List<Fragment> {
        var i = 0

        var list = elements
        if (isReversedSearch == true) list = elements.reversed()

        while(i < list.size) {
            val child = list[i]
            if (child.guid == byGuid) return listOf(child)
            if (child is IParagraph && child.find(byGuid) != null)
                return listOf(child) + child.pathToFragment(byGuid, isReversedSearch)
            i++
        }

        return emptyList()
    }

    fun pathToFragment(f: Fragment, isReversedSearch: Boolean? = false): List<Fragment> {
        return pathToFragment(f.guid, isReversedSearch)
    }

    fun getAddress(f: Fragment, isReversedSearch: Boolean? = false): List<String> {
        return getAddress(f.guid, isReversedSearch)
    }

    fun getAddress(byGuid: String, isReversedSearch: Boolean? = false): List<String> {
        return pathToFragment(byGuid, isReversedSearch).map { it.guid }
    }

    fun lastTerminalFragment(): Fragment {
        val last = elements.last()
        if (last is IParagraph) return last.lastTerminalFragment()
        return last
    }

    fun firstTerminalFragment(): Fragment {
        val first = elements.first()
        if (first is IParagraph) return first.firstTerminalFragment()
        return first
    }

    fun lastTextFragment(): Fragment.StyledSpan {
        val fragment = lastTerminalFragment()
        if (fragment is Fragment.StyledSpan) return fragment
        else return prevTextFragment(fragment) ?:
        throw BugException("block.lastStyledSpan: there's Paragraph both without StyledSpan and nested Paragraph")
    }

    fun firstTextFragment(): Fragment.StyledSpan {
        val fragment = firstTerminalFragment()
        if (fragment is Fragment.StyledSpan) return fragment
        else return nextTextFragment(fragment) ?:
        throw BugException("block.lastStyledSpan: there's Paragraph both without StyledSpan and nested Paragraph")
    }

//    fun getTextFragments(): List<Fragment.StyledSpan> {
//        val first = firstTextFragment()
//        var cursor: Fragment.StyledSpan? = first
//        val list = mutableListOf(first)
//
//        while(cursor != null) {
//            cursor = nextTextFragment(cursor)
//            if (cursor != null) list.add(cursor)
//        }
//
//        return list
//    }

    fun caretAt(guid: String, offset: Int): Caret? {
        val path = pathToFragment(guid)
        if (path.isEmpty()) return null

        val terminalFragment = path.last()
        // TODO: need to add marker for fragments that can contain caret
        if (terminalFragment !is Fragment.StyledSpan) return null

        // FIXME: in some cases offset is not 0
        val isOffsetInvalid = terminalFragment.text.isEmpty() && offset != 0
        if (isOffsetInvalid)
            console.log("caretAt: WARNING invalid caret offset at ${guid} offset=${offset} and text is empty")
        var correctedOffset = if (!isOffsetInvalid) offset else 0

        // FIXME: caretAtPoint produces out of range offset sometimes
        val span = find(guid) as Fragment.StyledSpan
        correctedOffset = min(span.lastOffset, correctedOffset)

        return Caret(path.map { it.guid }, correctedOffset).withParent(this.toFragment())
    }

    fun caretAt(offset: Int, preferRight: Boolean = false): Caret? {
        var fragment: Fragment.StyledSpan? = firstTextFragment()
        var offsetLeft = offset

        while (fragment != null && offsetLeft > fragment.text.length) {
            offsetLeft -= fragment.text.length
            fragment = nextTextFragment(fragment)
        }

        if (fragment == null) return null

        if (preferRight && offsetLeft == fragment.lastOffset) {
            nextTextFragment(fragment)?.let {
                return caretAt(it.guid, 0)
            }
        }

        return caretAt(fragment.guid, offsetLeft)
    }

    fun caretAtEnd(): Caret {
        val last = lastTextFragment()
        return Caret(getAddress(last), last.lastOffset).withParent(this.toFragment())
    }

    fun caretAtStart(): Caret {
        val first: Fragment.StyledSpan = firstTextFragment()
        return Caret(getAddress(first), 0).withParent(this.toFragment())
    }

    fun toFragment(): Fragment {
        return this as? Fragment ?: throw BugException("Can't cast IParagraph ${guid} to Fragment")
    }

    fun replaceFragment(updated: Fragment): IParagraph = makeCopy(
        elements.map { f ->
            if (f.guid == updated.guid)
                updated
            else if (f is IParagraph) f.replaceFragment(updated) as Fragment
            else f
        }
    )

    fun replaceFragments(oldFragments: List<Fragment>, newFragments: List<Fragment>): IParagraph {
        val indexOfFirst = elements.indexOfFirst { it.guid == oldFragments.first().guid }

        if (indexOfFirst == -1) return makeCopy(
            elements.map { f ->
                if (f is IParagraph) f.replaceFragments(oldFragments, newFragments) as Fragment
                else f
            },
            paragraphStyle, guid
        )

        val indexOfLast = elements.indexOfFirst { it.guid == oldFragments.last().guid }

        return makeCopy(
            elements.slice(0 until indexOfFirst)
                    + newFragments
                    + elements.slice(indexOfLast + 1 until elements.size),
            paragraphStyle, guid
        )
    }

    fun removeFragment(guid: String): IParagraph = makeCopy(
        elements.map { f ->
            if (f.guid == guid) null
            else if (f is IParagraph) f.removeFragment(guid) as Fragment
            else f
        }.filterNotNull(),
        paragraphStyle, this.guid
    )

    fun makeCopy(
        elements: List<Fragment> = this.elements,
        paragraphStyle: ParagraphStyle? = this.paragraphStyle,
        guid: String = this.guid,
        isVisible: Boolean = this.isVisible
    ): IParagraph

//    fun copy(): T

    fun all(): Sequence<Fragment> = sequence {
        for (element in elements) {
            if( element is Fragment.Paragraph) yieldAll(element.all())
            else yield(element)
        }
    }

    operator fun plus(other: IParagraph): IParagraph =
        makeCopy(elements = elements + other.elements)

    operator fun plus(other: Fragment.Paragraph): Fragment.Paragraph {
        if (this !is Fragment.Paragraph)
            throw BugException("Can't add paragraph to IParagraph with different type")

        return makeCopy(elements + other.elements)
    }

    operator fun plus(other: Fragment): IParagraph =
        if(other is IParagraph)
            makeCopy(elements = elements + other.elements)
        else
            makeCopy(elements = elements + other)

    fun getOffset(caret: Caret): Int {
        var offset = 0
        var isFound = false
        val targetGuid = caret.spanId

        fun addOffset(p: IParagraph) {
            var i = 0

            do {
                var target = p.elements[i]

                when(target) {
                    is IParagraph -> addOffset(target)
                    is Fragment.StyledSpan -> {
                        if (target.guid == targetGuid) {
                            offset += caret.offset
                            isFound = true
                        } else offset += target.text.length
                    }
                    else -> {}
                }

                i++
            } while (!isFound && i < p.elements.size)
        }

        addOffset(this)

        if (!isFound) throw BugException("OffsetCaret: can't find span ${targetGuid} in IParagraph ${this.guid}")

        return offset
    }

    fun getLocalOffsetCaret(caret: Caret): OffsetCaret {
        var offset = 0
        var isFound = false
        var targetGuid = caret.path.last()

        fun getOffset(p: IParagraph) {
            var i = 0

            do {
                var target = p.elements[i]

                when(target) {
                    is IParagraph -> getOffset(target)
                    is Fragment.StyledSpan -> {
                        if (target.guid == targetGuid) {
                            offset += caret.offset
                            isFound = true
                        } else offset += target.text.length
                    }
                    else -> {}
                }

                i++
            } while (!isFound && i < p.elements.size)

            // paragraph's plain text ends with virtual '\n'
            if (!isFound) offset += 1
        }

        getOffset(this)

        if (!isFound) throw BugException("OffsetCaret: can't find span ${targetGuid} in IParagraph ${this.guid}")

        return OffsetCaret(this, offset)
    }

    fun findAll(fn: (f: Fragment) -> Boolean): List<Fragment> {
        val found = mutableListOf<Fragment>()

        elements.forEach {
            if (fn(it)) found += it
            else {
                when(it) {
                    is IParagraph -> found += it.findAll(fn)
                    else -> {}
                }
            }
        }

        return found
    }

    fun printFull(level: Int = 0) {
        fun print(msg: String, l: Int = level) {
            var padding = ""
            for (i in 0..l) padding += "    "

            console.log(padding + msg)
        }

        when(this) {
            is Block -> print("Block (${guid}) {")
            is Fragment.Paragraph -> print("Paragraph (${guid}) {")
            is Fragment.TableParagraph -> print("Table (${guid}) {")
        }

        elements.forEach {
            when(it) {
                is Fragment.StyledSpan -> print("span (${it.guid}): '${it.text}'", level + 1)
                is Fragment.Frame -> print("frame (${it.guid})", level + 1)
                is Fragment.LinkedImage -> print("linkedImage (${it.guid})", level + 1)
                is Fragment.Paragraph -> it.printFull(level + 1)
                is Fragment.StoredImage -> print("storedImage (${it.guid})", level + 1)
                is Fragment.TableParagraph -> it.printFull(level + 1)
            }
        }

        print("}")
    }

    fun caretRight(c: Caret): Caret? {
        val span = find(c.spanId) as? Fragment.StyledSpan ?: return null
        if (c.offset + 1 <= span.text.length) return c.copy(offset = c.offset + 1)
        val nextText = nextTextFragment(span) ?: return null

        return caretAt(nextText.guid, 0)
    }

    fun caretLeft(c: Caret): Caret? {
        val span = find(c.spanId) as? Fragment.StyledSpan ?: return null
        if (c.offset - 1 >= 0) return c.copy(offset = c.offset - 1)
        val prevText = prevTextFragment(span) ?: return null

        return caretAt(prevText.guid, prevText.lastOffset)
    }

    fun getPlainCaret(plainOffset: Int): Caret {
        var offset = plainOffset
        var targetGuid: String? = null

        fun getOffset(p: IParagraph) {
            var i = 0

            do {
                var target = p.elements[i]

                when(target) {
                    is IParagraph -> getOffset(target)
                    is Fragment.StyledSpan -> {
                        if (target.text.length >= offset) targetGuid = target.guid
                        else offset -= target.text.length
                    }
                    else -> {}
                }

                i++
            } while (targetGuid == null && i < p.elements.size)

            // paragraph's plain text ends with virtual '\n'
            if (targetGuid == null) offset -= 1
        }

        getOffset(this)

        return targetGuid?.let {
            Caret(getAddress(it), offset).withParent(this.toFragment())
        } ?: throw BugException("OffsetCaret: no span in IParagraph#${this.guid} with offset $plainOffset")
    }
}

fun getPlainText(p: IParagraph): String {
    var plain = ""

    var i = 0

    do {
        var target = p.elements[i]

        when(target) {
            is IParagraph -> plain += getPlainText(target)
            is Fragment.StyledSpan -> plain += target.text
            else -> {}
        }

        i++
    } while (i < p.elements.size)

    return plain + '\n'
}
