package net.sergeych.intecowork.api

class Multiresult<T> protected constructor(private val result: Any?, @Suppress("UNUSED_PARAMETER") _dummy: Boolean) {

    constructor(vararg codes: ErrorCode) : this(Failure(codes.toSet()), true)

    constructor(result: T) : this(result, true)

    class Failure(val codes: Set<ErrorCode>)

    val isOk = result !is Failure
    val isError = !isOk
    @Suppress("UNCHECKED_CAST")
    fun resultOrNull(): T? = result as? T

    @Suppress("UNCHECKED_CAST")
    fun resultOrThrow(): T =
        if (isOk) result as T
        else throw IllegalStateException("Multiresult is error: $this")

    val errorCodesOrNull by lazy { (result as? Failure)?.codes }

    /**
     * Set of error codes. Returns empty set if it is a success
     */
    val errorCodes by lazy {
        errorCodesOrNull ?: setOf()
    }

    operator fun contains(code: ErrorCode): Boolean = code in errorCodes

    override fun toString(): String {
        return if (isOk) {
            if( result == Unit ) "OK"
            else "OK(${resultOrNull()})"
        }
        else "Error:${errorCodes}"
    }
}

//fun <R> multiresult(text: String = "неизвестная ошибка в блоке protect", f: () -> R): Multiresult<R> {
//    try {
//        return Multiresult(f())
//    } catch (t: Throwable) {
//        return Multiresult(ErrorCode.UnknownError.apply { cause = t })
//    }
//}
