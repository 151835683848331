package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.I
import org.w3c.dom.HTMLElement

@Suppress("unused")
enum class Icon(val styleClass: String) {
    Search("search"),
    UnderConstruction("cone-striped"),
    Info("info-circle"),
    Building("building"),
    PersonRolodex("person-rolodex"),
    Person("person"),
    Company("briefcase"),
    People("people"),
    PersonAdd("person-add"),
    Send("send"),
    Danger("radioactive"),
    Help1("life-preserver"),
    Keyboard("keyboard"),
    RichText("file-earmark-richtext"),
    Recycle("recycle"),
    PersonGear("person-gear"),
    Gear("gear-wide-connected"),
    BoxArrowLeft("box-arrow-left"),
    Image("image"),
    XCircle("x-circle"),
    XCircleFill("x-circle-fill"),
    ExclamationTriangle("exclamation-triangle"),
    ClipboardPlus("clipboard2-plus"),
    ClipboardPlusFill("clipboard2-plus-fill"),
    Eye("eye"),
    CloudCheck("cloud-check"),
    CloudSlash("cloud-slash"),
    CloudUpload("cloud-upload"),
    CloudPlus("cloud-plus"),
    CloudArrowUp("cloud-arrow-up"),
    CloudDownload("cloud-download"),
    ArrowRepeat("arrow-repeat"),
    ArrowBarLeft("arrow-bar-left"),
    ArrowLeft("arrow-left"),
    ArrowBarRight("arrow-bar-right"),
    ArrowRight("arrow-right"),
    Table("table"),
    Trash("trash2"),
    TrashFill("trash2-fill"),
    AlignStart("align-start"),
    TextLeft("text-left"),
    TextRight("text-right"),
    TextCenter("text-center"),
    TextJustify("justify"),
    ThreeDotsVertical("three-dots-vertical"),
    SortUp("sort-up"),
    SortDown("sort-down"),
    SendCheck("send-check"),
    ListNumbered("list-ol"),
    ListBulleted("list-ul"),
    List("list"),
    Download("download"),
    Printer("printer"),
    SpellCheck("spellcheck"),
    Link("link-45deg"),
    Pen("pen"),
    ChatLeftText("chat-left-text"),
    Check("check"),
    CheckLg("check-lg"),
    ;

    @Composable
    fun render(attrs: AttrBuilderContext<HTMLElement>?=null) {
        I({
            classes("bi", "bi-$styleClass")
            attrs?.invoke(this) ?: classes("me-1")
        })
    }

    @Composable
    fun <T: CSSUnit>render(size: CSSSizeValue<T>,vararg classNames: String) {
        render { style { fontSize(size) }; classes(*classNames) }
    }

}