package net.sergeych.intecowork.api

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.boss_serialization_mp.decodeBoss
import net.sergeych.intecowork.IcwkClient
import net.sergeych.unikrypto.*

@Serializable
data class ApiDoc(
    val id: Long = 0,
    val owner: ApiUser,
    val type: DocType,
    val lastSerial: Long,
    val createdAt: Instant,
    val updatedAt: Instant,
    val trashedAt: Instant?=null,
    val size: Long
) {
    enum class Group {
        All, Owned, Shared, Trashed
    }
}

@Serializable
class ApiDocAccess(
    val doc: ApiDoc,
    val role: DocRole,
    val keyContainer: ByteArray?,
    val metaBlock: ApiBlock?,
    val stateBlock: ApiBlock?,
) {
    suspend fun docKey(client: IcwkClient): SymmetricKey? {
        if (keyContainer == null)
            throw IllegalArgumentException("в доступе остутствует ключ-контейнер (роль ${role})")
        return Container.decrypt<SymmetricKey>(keyContainer, client.mainRing())
    }
}

@Serializable
class ApiCreateDocArgs(
    val type: DocType,
    val container: ByteArray,
    val blocks: List<ApiBlock>,
) {
    constructor(type: DocType, keyContainer: ByteArray, vararg blocks: ApiBlock) : this(
        type,
        keyContainer,
        blocks.toList()
    )
}

/**
 * Everything that has block structure (and therefore part of the IcwkDocument document)
 * must implement this:
 */
interface BlockItem {
    val type: BlockType
    val serial: Long
    val guid: String
    val nextGuid: String?
    val prevGuid: String?
    val tag: String?
    val utag: String?
    val data: ByteArray?
    val docId: Long?
    val userId: Long?
    val lastModifiedByUserId: Long?

    /**
     * A readonly property that represents a monotonous long value assigned when
     * the block is stored in the cloud. Null value means the block is not stored.
     * It is not possible to change this id.
     */
    val sequenceId: Long?
}

/**
 * The raw api block. Its data are almost always encrypted and should be normally
 * used only to encrypt and decrypt in the context of some [IcwkDocument], which owns it
 * and has the key for it.
 */
@Serializable
data class ApiBlock(
    override val type: BlockType,
    override val serial: Long = 0,
    override val guid: String = randomId(18),
    override val nextGuid: String? = null,
    override val prevGuid: String? = null,
    override val tag: String? = null,
    override val utag: String? = null,
    override val data: ByteArray?,
    override val docId: Long? = null,
    override val userId: Long? = null,
    override val lastModifiedByUserId: Long? = null,
    override val sequenceId: Long? = null
) : BlockItem {
    inline fun <reified T : Any> decodeOrNull(key: DecryptingKey): T? =
        decryptOrNull(key)?.decodeBoss<T>()

    fun decryptOrNull(key: DecryptingKey): ByteArray? = data?.let {
        try { key.etaDecrypt(it) } catch(_: Throwable) { null }
    }

    /**
     * Block author is a non-null id of a user that has changed it the last
     */
    val authorId: Long by lazy {
        lastModifiedByUserId ?: userId ?: throw InternalError("Block has no author")
    }

    fun decrypt(key: DecryptingKey): ByteArray =
        decryptOrNull(key) ?: throw IllegalArgumentException("отсутствуют данные для расшифровки")

    inline fun <reified T : Any> decode(key: DecryptingKey): T =
        decodeOrNull(key) ?: throw IllegalArgumentException("невозможно распаковать блок: нет данных")

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ApiBlock

        if (type != other.type) return false
        if (serial != other.serial) return false
        if (guid != other.guid) return false
        if (nextGuid != other.nextGuid) return false
        if (prevGuid != other.prevGuid) return false
        if (tag != other.tag) return false
        if (utag != other.utag) return false
        if (data != null) {
            if (other.data == null) return false
            if (!data.contentEquals(other.data)) return false
        } else if (other.data != null) return false
        if (docId != other.docId) return false
        if (userId != other.userId) return false
        if (lastModifiedByUserId != other.lastModifiedByUserId) return false
        return sequenceId == other.sequenceId
    }

    private val cachedHashCode by lazy {
        var result = type.hashCode()
        result = 31 * result + serial.hashCode()
        result = 31 * result + guid.hashCode()
        result = 31 * result + (nextGuid?.hashCode() ?: 0)
        result = 31 * result + (prevGuid?.hashCode() ?: 0)
        result = 31 * result + (tag?.hashCode() ?: 0)
        result = 31 * result + (utag?.hashCode() ?: 0)
        result = 31 * result + (data?.contentHashCode() ?: 0)
        result = 31 * result + (docId?.hashCode() ?: 0)
        result = 31 * result + (userId?.hashCode() ?: 0)
        result = 31 * result + (lastModifiedByUserId?.hashCode() ?: 0)
        31 * result + (sequenceId?.hashCode() ?: 0)
    }
    override fun hashCode(): Int = cachedHashCode

    companion object {
        inline fun <reified T> encrypt(
            type: BlockType,
            key: SymmetricKey,
            payload: T,
            nextGuid: String? = null,
            prevGuid: String? = null,
            tag: String? = null,
            utag: String? = null,
            guid: String = randomId(18),
            docId: Long? = null,
        ): ApiBlock {
            val data: ByteArray? = when (payload) {
                null -> null
                is ByteArray -> payload
                else -> BossEncoder.encode(payload)
            }
            return ApiBlock(
                type,
                nextGuid = nextGuid,
                prevGuid = prevGuid,
                tag = tag,
                utag = utag,
                guid = guid,
                data = data?.let { key.etaEncrypt(it) },
                docId = docId
            )
        }

        inline fun <reified T> create(
            type: BlockType,
            payload: T,
            nextGuid: String? = null,
            prevGuid: String? = null,
            tag: String? = null,
            utag: String? = null,
            guid: String = randomId(18),
        ): ApiBlock {
            val data: ByteArray? = when (payload) {
                null -> null
                is ByteArray -> payload
                else -> BossEncoder.encode(payload)
            }
            return ApiBlock(
                type,
                nextGuid = nextGuid,
                prevGuid = prevGuid,
                tag = tag,
                utag = utag,
                guid = guid,
                data = data
            )
        }
    }


}

@Serializable
data class ApiDocMeta(
    val revision: Int = 1,
    val title: String? = null,
    val description: String? = null,
    val keywords: List<String>? = null,
    val author: String? = null,
    val copyright: String? = null,
    val format: String? = null,
)

/**
 * This is under construction - could be removed or rewritten
 */
@Serializable
data class ApiMember(
    val user: ApiUser,
    val role: DocRole,
    val docId: Long = 0,
)

/**
 * The information about changes in some block serial. See [ApiBlock] in particular and
 * [BlockItem] implementors in general.
 */
@Serializable
data class ApiBlockSerial(
    val guid: String,
    val serial: Long,
)

@Serializable
class UpdateBlocksArgs(
    val docId: Long,
    val lastSerial: Long,
    val blocks: List<ApiBlock>,
)

/**
 * Result of attempt to modify document structure, including its list part (acyclic linear
 * graph ob blocks of type [BlockType.Body].
 */
@Serializable
sealed class ApiDocUpdateResult {
    abstract val description: String

    /**
     * Document is already modified and requires merge. Get updated blocks
     * using [Intecowork.getBlocks] with `sinceSerial` parameter set to last known by
     * you.
     */
    @Serializable
    @SerialName("e_modified")
    object MergeRequired : ApiDocUpdateResult() {
        override val description = "документ обновлен, необходимо слияние"
    }

    /**
     * Unspecified modification error, like network failed. Client should wait,
     * optionally try to merge, and retry the operation.
     */
    @Serializable
    @SerialName("e_failure")
    class Failure(override val description: String) : ApiDocUpdateResult()

    /**
     * After applying changes doc's structure is broken (e.g. there are orphaned blocks,
     * no start or end block or the graph is not linear acyclic (must be).
     */
    @Serializable
    @SerialName("e_structure")
    class BadStructure(override val description: String) : ApiDocUpdateResult()

    /**
     * Updates are accepted. The system returns number of changed serials. Client should
     * alter serials in the specified blocks accordingly.
     */
    @Serializable
    @SerialName("ok")
    class Success(val modifications: List<ApiBlockSerial>) : ApiDocUpdateResult() {
        override val description = "документ изменен, изменений ${modifications.size}"
    }

    override fun toString(): String = description
}

/**
 * Application-specific state of the viewer/editor saved on per user when
 * collaboartion/sharing is in efect. Note that this state occupy space in
 * the user's storage, not document owner's.
 */
@Serializable
data class DocState(
    val packedState: ByteArray? = null,
    val accessedAt: Instant = Clock.System.now(),
) {

    inline fun <reified T : Any> decode(): T? =
        packedState?.let { it.decodeBoss<T>() }

    inline fun <reified T : Any> update(state: T): DocState {
        return DocState(BossEncoder.encode<T>(state))
    }

    companion object {
        inline fun <reified T : Any> pack(state: T): DocState =
            DocState(BossEncoder.encode(state))
    }
}

@Serializable
class ApiGetDocBodyArgs(
    val docId: Long,
    val fromGuid: String? = null,
    val accessHandle: String? = null,
    val count: Int = 100,
)


@Serializable
class ApiEnumerateDocsArgs(
    val type: DocType? = DocType.Text,
    val offset: Int = 0,
    val count: Int = 10,
    val group: ApiDoc.Group = ApiDoc.Group.All
) {
}

@Serializable
class ApiDocHeader(
    val id: Long,
    val userId: Long,
    val lastSerial: Long,
    val createdAt: Instant,
    val updatedAt: Instant,
    val trashedAt: Instant?
)

@Serializable
class ApiDocUpdateShareArgs(
    val docId: Long,
    val userId: Long,
    val role: DocRole,
    val keyContainer: ByteArray,
)

@Serializable
class ApiDocDeleteShareArgs(
    val docId: Long,
    val userId: Long
)

