package net.sergeych.intecowork.tools

import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableSharedFlow
import net.sergeych.mp_tools.globalLaunch

/**
 * Emit an event immediately if it is possible without suspending, otherwise
 * allocate a new coroutine in the global context that will emit it ASAP.
 *
 * __Important warning.__ When sending fast multiple events in a suspended flow
 * it does not guarantee order as this function does not block at all anc race
 * condition could occur. If the order is important, emit it from a coroutine and
 * use mutex as need
 */
fun <T> MutableSharedFlow<T>.smartEmit(value: T): Job? {
    return if (tryEmit(value)) null
    else globalLaunch { emit(value) }
}

