package net.sergeych.intecowork.api

import kotlinx.serialization.Serializable


/**
 * Detailed user info is disclosed to the user self and to the system administration.
 * Others rely on [ApiUser] public data. Params that aren't included in it are:
 *
 * @param loginName unique name known to system only, selected at registration
 * @param isSearchable if false, user will not appear in other's searches by name
 */
@Serializable
data class ApiUserDetails(
    val loginName: String,
    val name: String,
    val nameIsApproved: Boolean = false,
    val isSearchable: Boolean = true,
    val id: Long = 0,
) {
    constructor(onlyName: String) : this(onlyName, onlyName)

    val asApiUser: ApiUser = ApiUser(id, name, nameIsApproved)
}

