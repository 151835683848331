package document

import document.ParagraphStyle.Companion.Predefined
import editor.RenderMode
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.*
import org.w3c.dom.HTMLElement

/**
 * Paragraph style could be empty except the [name], which defines predefined style
 * taken from the document's stylesheet
 */
@Suppress("unused")
@Serializable
data class ParagraphStyle(
    val marginBefore: Int? = null,
    val marginAfter: Int? = null,
    val lineSpacing: Float? = null,
    val defaultTextStyle: TextStyle? = null,
    /**
     * The name of the style: most important part (the rest should be null as for now,
     * or most of it).
     *
     * The name of predefined styles should match [Predefined.styleName], which is the name
     * of its [Predefined.style] [ParagraphStyle.name], with substitution of null name to default
     * [Predefined.Normal] name.
     */
    val name: String? = null,
    val indentLevel: Int? = null,
    val listStyle: List? = null,
    val firstLineIndent: Int? = null,
    val textAlign: TextAlign? = null,
) {
    enum class List {
        Bullets,
        Numbers
    }

    enum class TextAlign {
        Left, Right, Center, Justify
    }

    fun assignToScope(scope: AttrsScope<HTMLElement>, mode: RenderMode = RenderMode.EDITOR) {
        with(scope) {
            defaultTextStyle?.buildAttributes(this)
            style {
                lineSpacing?.let { lineHeight(it.toString()) }

                val iLevel = indentLevel ?: 0

                if (listStyle == null) {
                    if (iLevel in 1..MaxIndentLevel) {
                        if (mode == RenderMode.DOCX) marginLeft((iLevel * 3 * 16).px)
                        else paddingLeft((iLevel * 3).em)
                    }
                } else {
                    val listIndent = iLevel + 1

                    if (listIndent in 1..MaxIndentLevel + 1) {
                        if (mode == RenderMode.DOCX) marginLeft((listIndent * 3 * 16).px)
                        else paddingLeft((listIndent * 4 - 1.5).em)
                    }
                }

                position(Position.Relative)
            }
            marginBefore?.let { classes("mt-$it") }
            marginAfter?.let { classes("mb-$it") }
            when (textAlign) {
                null, TextAlign.Left -> {
                    style { textAlign("left") }
                }

                TextAlign.Right -> {
                    style { textAlign("right") }
                }

                TextAlign.Center -> {
                    style { textAlign("center") }
                }

                TextAlign.Justify -> {
                    style { textAlign("justify") }
                }
            }
        }
    }

    /**
     * 3-way merge changes: this style is a source, as was received from the server
     * originally (or at creation time), [serverValue] as received from the server
     * and [userValue] as set by the user (as it is now in the document).
     *
     * __Please use it when merging external changes!__
     *
     * @return the style to be applied to the resulting block (root paragraph)
     */
    fun merge3(serverValue: ParagraphStyle, userValue: ParagraphStyle): ParagraphStyle {
        fun <T> m(was: T, their: T, my: T): T = if (was == my) their else my
        return ParagraphStyle(
            m(marginBefore, serverValue.marginBefore, userValue.marginBefore),
            m(marginAfter, serverValue.marginAfter, userValue.marginAfter),
            m(lineSpacing, serverValue.lineSpacing, userValue.lineSpacing),
            m(defaultTextStyle, serverValue.defaultTextStyle, userValue.defaultTextStyle),
            m(name, serverValue.name, userValue.name),
            m(indentLevel, serverValue.indentLevel, userValue.indentLevel),
            m(listStyle, serverValue.listStyle, userValue.listStyle),
            m(firstLineIndent, serverValue.firstLineIndent, userValue.firstLineIndent),
            m(textAlign, serverValue.textAlign, userValue.textAlign)
        )

    }

    /**
     * Combine with other style with this one having priority: use [other] components only if
     * this component is null.
     */
    fun combineWith(other: ParagraphStyle): ParagraphStyle {
        return ParagraphStyle(
            marginBefore ?: other.marginBefore,
            marginAfter ?: other.marginAfter,
            lineSpacing ?: other.lineSpacing,
            defaultTextStyle ?: other.defaultTextStyle,
            name ?: other.name,
            indentLevel ?: other.indentLevel,
            listStyle ?: other.listStyle,
            firstLineIndent ?: other.firstLineIndent,
            textAlign ?: other.textAlign
        )
    }

//
// sergeych: I've switched it to a data class so we most likely can use built-in:
//
//    override fun equals(other: Any?): Boolean {
//        return other is ParagraphStyle && other.defaultTextStyle == defaultTextStyle && other.marginBefore == marginBefore
//                && other.marginAfter == marginAfter && other.lineSpacing == lineSpacing
//    }

    companion object {

        /**
         * Predefined paragraph styles. These styles can't be renamed, but could have been
         * customized per-document or per-stylesheet later.
         *
         * __All paragraph styles should rely on paragraphStyle.name solely!__
         *
         * If the user would later try to rename it, the new style should be created based on
         * existing cone (copy) with a new name.
         */
        enum class Predefined(val style: ParagraphStyle) {
            Title(ParagraphStyle(name = "Заголовок")),
            Subtitle(ParagraphStyle(name = "Подзаголовок")),
            Heading1(ParagraphStyle(name = "Заголовок 1")),
            Heading2(ParagraphStyle(name = "Заголовок 2")),
            Heading3(ParagraphStyle(name = "Заголовок 3")),
            Heading4(ParagraphStyle(name = "Заголовок 4")),
            Heading5(ParagraphStyle(name = "Заголовок 5")),
            Normal(ParagraphStyle(name = "Обычный")),
            ;

            /**
             * Immutable name of the predefined paragraph style
             */
            val styleName by lazy { style.name ?: "Обычный" }
        }

        //
        // TODO: remove these constant, change the logic to rely on paragraphStyle.name instead!
        //
        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Normal"))
        val title = ParagraphStyle(name = "Title")

        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Title"))
        val heading = ParagraphStyle(name = "Heading", defaultTextStyle = TextStyle.heading)

        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Subtitle"))
        val subheading = ParagraphStyle(name = "Subheading", defaultTextStyle = TextStyle.subheading)

        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Heading1"))
        val heading1 = ParagraphStyle(name = "Heading1", defaultTextStyle = TextStyle.heading1)

        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Heading2"))
        val heading2 = ParagraphStyle(name = "Heading2", defaultTextStyle = TextStyle.heading2)

        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Heading3"))
        val heading3 = ParagraphStyle(name = "Heading3", defaultTextStyle = TextStyle.heading3)

        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Heading4"))
        val heading4 = ParagraphStyle(name = "Heading4", defaultTextStyle = TextStyle.heading4)

        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Heading5"))
        val heading5 = ParagraphStyle(name = "Heading5", defaultTextStyle = TextStyle.heading4)

        @Deprecated("Use predefined style instead", ReplaceWith("Predefined.Normal"))
        val normal = ParagraphStyle(name = "Normal")

        // Maximum indent level, inclusive (so 0 - no indent, 1..5 - indents)
        const val MaxIndentLevel = 5
        const val MaxFirstLineIndent = 5
    }
}