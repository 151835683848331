package editor.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.rememberCoroutineScope
import editor.DOCUMENT_CONTENT_ID
import editor.DocContext
import editor.RenderMode
import editor.operations.MoveDirection
import editor.operations.caretBoxDOM
import editor.plugins.domObserverLogger
import editor.plugins.domVersion
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.INSTANT
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

val CaretCSS = "blinking-cursor"
val CaretId = "_caret"

/**
 * Render caret and scroll document to fit
 */
@Composable
fun renderCaret(dc: DocContext, mode: RenderMode, renderVersion: String? = null) {
    if (!mode.isInteractive) return

    val scope = rememberCoroutineScope()
    val observer = dc.domObserver

    SideEffect {
        scope.launch {
            val container = window.document.getElementById(DOCUMENT_CONTENT_ID)
//            console.warn("side effect")
            val box = dc.caretBoxDOM() ?: return@launch
//            console.warn("side effect continue")

            container?.let {
                window.document.documentElement?.let { html ->
                    val b3 = box.height * 3
                    when (dc.lastMove.direction) {
                        MoveDirection.UP -> {
//                            console.warn("scroll on mouse up")
                            if (html.scrollTop != 0.0 && box.top - html.scrollTop < b3) {
//                            html.scrollTop -= b3
                                window.scrollTo(
                                    ScrollToOptions(
                                        top = html.scrollTop - b3,
                                        behavior = ScrollBehavior.INSTANT
                                    )
                                )
                            }
                        }

                        MoveDirection.DOWN -> {
//                            console.warn("scroll on mouse down")
                            if (html.scrollTop < html.scrollHeight && (html.scrollTop + html.clientHeight - box.bottom) < b3) {
//                            html.scrollTop += b3
//                                console.warn(html.scrollTop, b3)
                                window.scrollTo(
                                    ScrollToOptions(
                                        top = html.scrollTop + b3,
                                        behavior = ScrollBehavior.INSTANT
                                    )
                                )
                            }
                        }

                        else -> {
//                            console.warn("no direction")
                        }
                    }
                }
            }
        }

//        logger.warning { "Caret recomposed" }
//        dc.domObserver.finish("caret")
    }
    domObserverLogger.log("schedule caret recomposition ver=${renderVersion}")

    Div({
        id(CaretId)
        classes(CaretCSS)
        domVersion(dc.domObserver, CaretId, renderVersion)
    }) { Text("|") }
}