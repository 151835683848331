package views

import controls.*
import modalDialg
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.Text

fun ShowSecret(secret: String) {
    globalLaunch {
        modalDialg {
            staticBackdrop()
            xl()
            heading("Сохраните секретный ключ!")
            body {
                Di("mv-3") {
                    Text("Секретный ключ, указанный ниже, позволит вам сбросить пароль если вы его забудете. ")
                    Bold("Этот секретный ключ больше никогда не будет показан, поэтому сохраните его сечас, ")
                    Text(
                        """
              |скопируте и сохраните в надежном месте, или сфотографируйте и сохраните фотографию. 
              | если злоумышленник сможет похитить секретный ключ, ваш аккаунт может быть украден.
          """.trimMargin()
                    )
                    Danger(" Без секретного ключа забытый пароль и доступ к вашим данным нельзя будет восстановить!")
                }
                Di("text-center h3") {
                    Copyable(secret, maxLength = 64)
                }
            }
            footer {
                Di("w-100 text-center") {
                    Btn(
                        "Я подтверждаю, что сохранил этот секретный ключ",
                        Icon.ExclamationTriangle,
                        Variant.Warning,
                        outline = false,) {
                        close()
                    }
                }
            }
        }
    }
}