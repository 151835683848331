package views

import DocsCache
import Router
import androidx.compose.runtime.*
import client
import controls.*
import document.Doc
import document.ParagraphStyle
import editor.DocContext
import editor.operations.caretToHome
import editor.operations.getState
import editor.plugins.importBlocks
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.*
import net.sergeych.intecowork.doc.DocBlock
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.colspan
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.files.File
import tools.*
import withConfirm
import kotlin.js.Promise

val reSpace = """\s+""".toRegex()

suspend fun createDocument() {
//    Toaster.launchCatching {
    val doc = Doc {
        p(ParagraphStyle.heading) {
            t("")
        }
    }
    val dc = DocContext(doc)
    dc.caret = dc.caretToHome()

    val newDoc = IcwkDocument.create(client, DocType.Text, DocState.pack(dc.getState()))
    newDoc.modify {
        put(DocBlock.pack(BlockType.Body, guid = doc.firstBlock.guid) {
            doc.firstBlock
        })
    }
    newDoc.mergeAll()
    Router.push("/documents/${newDoc.docId}")
//    }
}

fun docxToJSON(file: File): Promise<dynamic> {
    val json = js("docxtojson.default(file)")

    return json
}

suspend fun importDOCX(file: File) {
    val json = docxToJSON(file).await()
    val blocks = importBlocks(json)
    val doc = Doc()
    doc.initializeWithBlocks(blocks)
    doc.calculateViewProperties()
    val dc = DocContext(doc)
    dc.caret = dc.caretToHome()
    val newDoc = IcwkDocument.create(client, DocType.Text, DocState.pack(dc.getState()))
    newDoc.modify {
        doc.allBlocks.forEach {
            put(DocBlock.pack(BlockType.Body, guid = it.guid, prev = it.prevBlockGuid, next = it.nextBlockGuid) {
                it
            })
        }
    }
    newDoc.mergeAll()
    newDoc.setTitle(file.name)
    Router.push("/documents/${newDoc.docId}")
}

data class MarkedDoc(
    val doc: IcwkDocument,
    val checked: Boolean = false
)

@Composable
fun Directory() {
    val scope = rememberCoroutineScope()
    var storedMode: Int? by storedParam<Int>()
    var selectedMode by remember { mutableStateOf(storedMode ?: 0) }
    var sortBy by remember { mutableStateOf(2) }
    var sortReverse by remember { mutableStateOf(false) }
    val list = mutableStateListOf<MarkedDoc>()
    var search by remember { mutableStateOf("") }

    var importDOCXFile: File? by remember { mutableStateOf(null) }
    val importFileCtrlId = remember { randomId(11) }

    fun compare(mda: MarkedDoc, mdb: MarkedDoc): Int {
        val a = mda.doc
        val b = mdb.doc
        val cmp = when (sortBy) {
            0 -> a.titleOrDefault.compareTo(b.titleOrDefault)
            1 -> a.lastCloudSize.compareTo(b.lastCloudSize)
            2 -> a.updatedAt.compareTo(b.updatedAt)
            else -> throw Exception("invalid sort index")
        }
        return if ((sortBy == 0 && sortReverse) || (sortBy != 0 && !sortReverse)) -cmp else cmp
    }

    fun resort() {
        list.sortWith(::compare)
    }

    LaunchedEffect(selectedMode, search) {
        val source = when (selectedMode) {
            0 -> DocsCache.allFlow
            1 -> DocsCache.myFlow
            2 -> DocsCache.sharedFlow
            3 -> DocsCache.trashedFlow
            else -> throw InternalError("недопустимы индекс группы файлов")
        }
        source.collect { src ->
            list.clear()
            val searchParts = search.trim().lowercase().split(reSpace).map { it.trim() }.filter { it.isNotBlank() }
            list.addAll(src
                .filter { s ->
                    if (searchParts.isNotEmpty()) {
                        val nparts =
                            s.titleOrDefault.lowercase().split(reSpace).map { it.trim() }.filter { it.isNotBlank() }
                        nparts.any { np -> searchParts.any { sp -> np.startsWith(sp) } }
                    } else true
                }
                .map { MarkedDoc(it) }
                .sortedWith(::compare))
        }
    }

    @Composable
    fun ListHeader(
        sortIndex: Int,
        text: String,
        colSpan: Int = 1,
        showSince: String? = null,
        align: String = "center"
    ) {
        Td({
            if (colSpan > 1) colspan(colSpan)
            style {
                cursor("pointer")
                whiteSpace("nowrap")
            }
            classes("text-$align", "px-1")
            showSince?.let { classes("d-none", "d-$it-table-cell") }
            onClick {
                if (sortIndex == sortBy) sortReverse = !sortReverse
                else {
                    sortBy = sortIndex
                    sortReverse = false
                }
                resort()
            }
        }) {
            Text(text)
            if (sortIndex == sortBy) {
                if (!sortReverse) Icon.SortUp.render(1.em)
                else Icon.SortDown.render(1.em)
            }
        }
    }

    SidebarLayout("Документы", {
        Hr({ classes("m-2") })

        Btn("создать", Icon.RichText, variant = Variant.Success, cls = "w-100", mb = 2, outline = false) {
            scope.launch {
                createDocument()
            }
        }

        Di("m-2 mx-3") {
            for ((i, s) in arrayOf("Показать все", "Мои документы", "Где я соавтор", "Удаленные").withIndex()) {
                Di("form-check") {
                    val buttonId = "list-type:$i"
                    Input(InputType.Radio) {
                        classes("form-check-input")
                        checked(i == selectedMode)
                        id(buttonId)
                        onChange {
                            selectedMode = i
                            storedMode = selectedMode
                            closeOffcanvas()
                        }
                    }
                    Label(buttonId, {
                        classes("form-check-label")
                        style { cursor("pointer") }
                    }) { Text(s) }
                }
            }
        }

        Hr({ classNames("my-2") })
        Ulist {
            Ulink(
                Icon.RichText,
                "импорт .docx",
            ) {
                window.document.getElementById(importFileCtrlId)?.let {
                    // FIXME: sometimes doesn't work in chrome. After chrome reopen it usually works
                    (it as HTMLInputElement).click()
                }
            }
        }
        val selected = list.filter { it.checked }.map { it.doc }
        if (selected.size > 0) {
            Ulist {
                DeleteMarked(selectedMode, selected)
                if (selectedMode == 3) EraseMarked(selected)
            }
        }
        if (selectedMode == 3 && list.size > 0) {
            Ulist { ClearRecycleBin(list.map { it.doc }) }
        }
    }, {
        DisposableEffect(true) {
            onDispose {
                showTopTitle(true)
            }
        }
        Di("input-group") {
            TextInput(search) {
                classNames("form-control")
                placeholder("поиск по именам")
                onInput { search = it.value }
                onFocusIn {
                    showTopTitle(false)
                }
                onFocusOut {
                    showTopTitle(true)
                }
            }
            Span({ classNames("input-group-text") }) { Icon.Search.render() }
        }
    }) {
        Input(InputType.File) {
            attr("id", importFileCtrlId)
            style { display(DisplayStyle.None) }
            // FIXME: need to set value
            onChange {
                scope.launch {
                    val file = it.target.files?.asList()?.firstOrNull()

                    console.log("on change")

                    file?.let {
                        Toaster.launchCatching(onFinally = { importDOCXFile = null }) {
                            importDOCXFile = it
                            console.log("file set")
                            importDOCX(it)
                        }
                    }
                }
            }
        }
        Row {
            Col("12", { classes("mt-2") }) {
//            TabsHeader(selectedMode, listOf("все", "мои", "чужие", "корзина")) {
//                selectedMode = it
//            }
                importDOCXFile?.let {
                    WaitPanel("Импортирую ${it.name}")
                }
                if (list.isEmpty()) {
                    if (DocsCache.loading) {
                        WaitPanel("загружаю список документов")
                    } else {
                        Di("mt-3 alert alert-secondary") {
                            Text("В данный момент таких документов нет. ")
                            when (selectedMode) {
                                0, 1 -> Text("Создайте документ при помощи кнопки \"создать\" выше.")
                                2 -> Text(
                                    """
                            Здесь отображаются документы, к которым Вам предоставили доступ другие участники.
                            Сейчас документы в совместном доступе отсутствуют.""".trimIndent()
                                )

                                3 -> Text(
                                    """
                            Это корзина, здесь появляются документы, которые Вы удаляете. Документы хранятся в корзине
                            неограниченно, пока Вы сами их не восстановите или не уничтожите. Когда место в хранилище
                            подойдет к концу, я предупрежу и предложу очистить корзину.
                        """.trimIndent()
                                )
                            }
                        }
                    }
                } else {
                    var checkAllState by remember { mutableStateOf(false) }
                    if (selectedMode == 3)
                        ShowHelpOnce(
                            "recycle", """
                                Файлы хранятся в корзине неограниченно долго, пока вы их явно не уничтожите, или не
                                восстановите.
                                
                                Документы, находящиеся в корзине, по прежнему доступны другим участникам и по публичным 
                                ссылкам, пока не будут явно уничтожены владельцем.
                            """.trimIndent()
                        )
                    ResponsiveTable {
                        Tr({ classes("bg-light", "fs-6") }) {
                            Td {
                                CheckboxInput(checkAllState) {
                                    classes("form-check-input")
                                    onChange {
                                        checkAllState = !checkAllState
                                        for ((i, d) in list.withIndex()) {
                                            list[i] = d.copy(checked = checkAllState)
                                        }
                                    }
                                }
                            }
                            Td {}
                            ListHeader(0, "название", align = "left")
                            ListHeader(1, "размер")
                            ListHeader(2, "изменён", showSince = "sm")
                            Td {}
                        }
                        for ((i, d) in list.withIndex()) {
                            Tr({
                                style { cursor("pointer") }
                                onClick { Router.push("/documents/${d.doc.docId}") }
                            }) {
                                Td({ style { width(1.percent) } }) {
                                    CheckboxInput(d.checked) {
                                        classes("form-check-input")
                                        onClick {
                                            list[i] = d.copy(checked = !d.checked)
                                            it.stopPropagation()
                                        }
                                    }
                                }
                                Td({
                                    style { width(1.percent); whiteSpace("nowrap") }
                                }) {
                                    var hasLink by remember { mutableStateOf<Boolean?>(null) }
                                    var hasShare by remember { mutableStateOf<Boolean?>(null) }
                                    Icon.RichText.render(1.3.em, "m-0", "p-0")
                                    when (hasShare) {
                                        null -> Icon.ArrowRepeat.render(0.9.em, "d-inline", "me-0", "pe-0", "spin2")
                                        true -> {
                                            when (d.doc.role) {
                                                DocRole.Owner -> Icon.People.render(0.9.em, "ms-0", "me-0", "p-0")
                                                DocRole.Writer -> Icon.Pen.render(0.9.em, "ms-0", "me-0", "p-0")
                                                DocRole.Reader -> Icon.Eye.render(0.9.em, "ms-0", "me-0", "p-0")
                                                DocRole.Commenter -> Icon.ChatLeftText.render(
                                                    0.9.em,
                                                    "ms-0",
                                                    "me-0",
                                                    "p-0"
                                                )

                                                else -> {}
                                            }
                                        }

                                        false -> {}
                                    }
                                    when (hasLink) {
                                        null -> if (hasShare != null)
                                            Icon.ArrowRepeat.render(0.9.em, "d-inline", "me-0", "pe-0", "spin2")
                                        true -> Icon.Link.render(0.9.em, "ms-0", "me-1", "p-0")
                                        false -> {}
                                    }
                                    LaunchedEffect(d.doc.docId) {
                                        fun refresh() {
                                            launch {
                                                hasLink = d.doc.getPublicShare() != null
                                            }
                                            launch {
                                                hasShare = d.doc.role != DocRole.Owner ||
                                                        d.doc.collaborators().size > 1
                                            }
                                        }
                                        refresh()
                                        d.doc.events().collect {
                                            if (it is ApiEvent.Doc.ShareChanged) {
                                                println("sharechanged/view for ${d.doc.docId}")
                                                // the problemL it can arrive before the document changes
                                                // in cache, so we give it the chance (yield is enough I think)
                                                delay(100)
                                                refresh()
                                            }
                                        }
                                    }
                                }
                                Td {
                                    Text(d.doc.titleOrDefault)
                                }
                                Td({
                                    classes("pe-2")
                                    style {
                                        width(1.percent)
                                        whiteSpace("nowrap")
                                        textAlign("right")
                                    }
                                }) {
                                    Text(d.doc.lastCloudSize.toDataSize())
                                }
                                Td({
                                    style {
                                        width(1.percent)
                                        whiteSpace("nowrap")
                                    }
                                    classes("d-none", "d-sm-table-cell")
                                }) {
                                    RelativeTime(d.doc.updatedAt, showDateOnDays = true)
//                                Text(d.updatedAt.shortString())
                                }
                                Td({
                                    style {
                                        width(1.percent)
                                        whiteSpace("nowrap")
                                    }
                                }) {
                                    if (selectedMode != 3) {
                                        BtnLink(
                                            icon = Icon.Recycle, variant = Variant.Danger,
                                            tip = "в корзину"
                                        ) {
                                            withConfirm(
                                                if (d.doc.role.isOwner)
                                                    """
                                            Вы действительно хотите переместить документ "${d.doc.titleOrDefault}
                                            в корзину?
                                        """".trimIndent()
                                                else """
                                            Вы действительно хотите удалить доступ к общему документу "${d.doc.titleOrDefault}?
                                            Это действие может отменить только владелец, документа вновь пригласив Вас.
                                            
                                        """.trimIndent(),
                                                yesVariant = Variant.Danger,
                                                icon = Icon.Recycle
                                            ) {
                                                console.log("deleting")
                                                Toaster.launchCatching {
                                                    d.doc.moveToTrash()
                                                    info("Документ перемещен в корзину")
                                                }
                                            }
                                        }
                                    } else {
                                        BtnLink(icon = Icon.Recycle, variant = Variant.Success, tip = "восстановить") {
                                            withConfirm(
                                                """
                                            Вы действительно хотите восстановить документ "${d.doc.titleOrDefault}"
                                            из корзины?
                                        """.trimIndent(),
                                                yesVariant = Variant.Success,
                                                icon = Icon.Recycle
                                            ) {
                                                console.log("restoring")
                                                Toaster.launchCatching {
                                                    d.doc.restoreFromTrash()
                                                    info("Документ восстановлен из корзины")
                                                }
                                            }
                                        }
                                        BtnLink(icon = Icon.Trash, variant = Variant.Danger, tip = "уничтожить") {
                                            withConfirm(
                                                """
                                            Вы действительно хотите необратимо уничтожить документ "${d.doc.titleOrDefault}"
                                            и освободить занимаемое им место?
                                            """.trimIndent(),
                                                yesVariant = Variant.Danger,
                                                icon = Icon.Trash,
                                                iconVariant = Variant.Danger
                                            ) {
                                                console.log("destroying")
                                                Toaster.launchCatching {
                                                    d.doc.erase()
                                                    info("Документ уничтожен")
                                                }
                                            }
                                        }

                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

@Composable
fun ClearRecycleBin(source: Collection<IcwkDocument>) {
    Ulink(
        Icon.Trash,
        "очистить корзину",
        extraClasses = "text-danger"
    ) {
        globalLaunch { eraseList(source) }
    }
}

@Composable
fun DeleteMarked(selectedMode: Int, selected: List<IcwkDocument>) {
    val count = selected.size
    val t = (if (selectedMode != 3) "удалить " else "восстановить ") + pluralizeDocWord(count)

    Ulink(
        if (selectedMode != 3) Icon.Trash else Icon.Recycle,
        t
    ) {
        if (selectedMode != 3) {
            globalLaunch {
                val countDelete = selected.count { it.role == DocRole.Owner }
                val lostShare = selected.size - countDelete
                withConfirm(
                    title = "Подтвердите операцию:",
                    body = {
                        Ul {
                            if (countDelete > 0) {
                                Li {
                                    +"${pluralizeDocWord(countDelete)} ${
                                        pluralize(
                                            countDelete,
                                            "будет помещен",
                                            "будут помещены",
                                        )
                                    } в корзину."
                                }
                            }
                            if (lostShare > 0) {
                                Li {
                                    +"Вы потеряете доступ к ${
                                        pluralize(
                                            lostShare,
                                            "$lostShare документу",
                                            "$lostShare документам"
                                        )
                                    }, эта операция не может быть отменена."
                                }
                            }

                        }
                    }
                ) {
                    selected.forEach { d -> globalLaunch { Router.runCatching { d.moveToTrash() } } }
                }
            }
        } else {
            globalLaunch { for (d in selected) Router.runCatching { d.restoreFromTrash() } }
        }
    }
}

@Composable
fun EraseMarked(selected: List<IcwkDocument>) {
    val count = selected.size

    Ulink(
        Icon.ExclamationTriangle,
        "уничтожить ${pluralizeDocWord(count)}",
        extraClasses = "text-danger"
    ) {
        globalLaunch { eraseList(selected) }
    }
}

suspend fun eraseList(selected: Collection<IcwkDocument>) {
    val count = selected.size
    val countDelete = selected.count { it.role == DocRole.Owner }
    val lostShare = selected.count { it.collaborators().size > 1 }
    val lostPublic = selected.count { it.getPublicShare() != null }
    withConfirm(
        icon = Icon.Trash,
        iconVariant = Variant.Danger,
        title = "уничтожить ${pluralizeDocWord(count)}?",
        body = {
            Ul {
                if (countDelete > 0)
                    Li {
                        Text(
                            "${pluralizeDocWord(countDelete)} будут невосстановимо ${
                                pluralize(
                                    countDelete,
                                    "уничтожен",
                                    "уничтожены"
                                )
                            }."
                        )
                    }
                if (lostShare > 0)
                    Li {
                        Text(
                            """$lostShare ${
                                pluralize(
                                    lostShare,
                                    "из них будет удален также у соавторов",
                                    "из них будут удалены также у соавторов"
                                )
                            }"""
                        )
                    }
                if (lostPublic > 0) {
                    Li {
                        Text(
                            """${pluralizeDocWord(lostPublic)}
                                    |${
                                pluralize(
                                    lostPublic,
                                    "станет недоступен",
                                    "станут недоступны"
                                )
                            } по публичной ссылке.
                                """.trimMargin()
                        )
                    }
                }
                Spa("fw-bold") { +"Эта операция необратима." }
            }
        }) {
        selected.forEach { globalLaunch { Toaster.launchCatching { it.erase() } } }
    }

}

val IcwkDocument.titleOrDefault: String
    get() = if (title.isNullOrBlank()) "без имени ${docId}" else title!!