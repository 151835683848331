package views

import Router
import androidx.compose.runtime.*
import client
import controls.Di
import controls.Icon
import controls.RouterLink
import controls.Row
import controls.classNames
import kotlinx.browser.window
import net.sergeych.intecowork.api.ApiStorage
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun Heading(title: String, showStorageWarning: Boolean = false,
            content: ContentBuilder<HTMLDivElement>? = null) {
    var storage: ApiStorage? by remember { mutableStateOf(client.storageState.value) }


    if( showStorageWarning) {
        LaunchedEffect(true) {
            client.storageState.collect { storage = it }
        }
        if (storage?.let { it.available <= 0 } == true) {
            Di("alert alert-danger") {
                Text("Хранилище переполнено, ")
                RouterLink("/profile", "пополните его", {
                    classes("alert-link")
                })
                Text(", или удалите часть файлов")
            }
        }
    }

    Row {
        Di("col-auto") {
            HeadingTitle(title)
        }
        content?.let { c ->
            Di("col-md") {
                c()
            }
        }
//        if (window.location.pathname != "/") {
//            Di("col float-end mt-3") {
//                BtnLink("<< назад") { Router.back() }
//            }
//        }
    }
}

@Composable
fun HeadingTitle(title: String) {
    H2 {
        if (window.location.pathname != "/") {
            Icon.ArrowLeft.render {
                style { cursor("pointer") }
                onClick { Router.back() }
                classes("me-1")
            }
            Text(title)
        } else {

            Logo()

            Di("d-inline d-sm-none") {
                Text(title)
            }
            Di("d-none d-sm-inline") {
                Text(title)
            }
        }
    }
}

@Composable
fun Logo(value: CSSSizeValue<*> = 1.3.em,clsNames: String? = null) {
    Img("/logo-candy1.svg") {
        style {
            height(value)
            cursor("pointer")
        }
        classes("me-1")
        clsNames?.let { classNames(it)}
        onClick {
            Router.push("/")
        }
    }
}