package net.sergeych.intecowork.doc

import net.sergeych.bipack.BipackEncoder
import net.sergeych.intecowork.api.*


/**
 * This is a context to collect changes to some [IcwkDocument] to send them to the cloud
 * in a transactional manner.
 *
 * - use [put] to add new [DocBlock] or modify existing.
 * - use [remove] to remove existing blocks.
 * - be sure that the resulting structure of [BlockType.Body] blocks is not corrupted
 * - set [BlockItem.serial] of your block to 0 to _unconditional iverwrite_ it. Do not do
 * it on [BlockType.Body] blocks unless absolute necessity.
 *
 */
class DocModificationContext(private val doc: IcwkDocument) {

    private val modifications = mutableMapOf<String, ApiBlock>()
    private var isCommitted = false

    /**
     * Put the list of ready encrypted API blocks. Usually application should use
     * [DocBlock] - based [put] instead.
     */
    fun put(blocks: List<ApiBlock>) {
        if (isCommitted)
            throw IllegalStateException("нельзя вносить изменения после завершения операции")
        for (b in blocks) {
            if (b.guid in modifications)
                throw IllegalArgumentException("попытка повторного добавления блока")

            modifications[b.guid] = when(b.docId) {
                null -> b.copy(docId = doc.docId)
                doc.docId -> b
                else -> throw IllegalArgumentException("блок уже принадлежит к другому документу")
            }
        }
    }
    /**
     * Put the ready encrypted API block. Usually application should use
     * [DocBlock] - based [put] instead.
     */
    fun put(vararg blocks: ApiBlock) = put(blocks.toList())

    /**
     * Add new or update existing [docBlock], performing document encryption
     * automatically.
     */
    fun put(docBlock: DocBlock): ApiBlock =
            docBlock.encryptFor(doc).also { put(it) }

    /**
     * Add new or update existing [CommentThread] instance. It is encoded using v2 format,
     * and this allows to put comments directly.
     */
    fun <T>putComment(comment: CommentThread<T>): ApiBlock =
        put(DocBlock(
            BlockType.Comment,
            BipackEncoder.encode(comment.serializer!!, comment),
            utag=comment.id,
            guid= comment.guid!!,
            serial = comment.serial))

    /**
     * Add a collection of new or update existing [docBlocks], performing document encryption
     * automatically.
     */
    fun put(docBlocks: Collection<DocBlock>): List<ApiBlock> =
            docBlocks.map { it.encryptFor(doc).also { put(it) } }

    /**
     * Remove existing blocks by their `guids`
     */
    fun remove(vararg guids: String) {
        guids.forEach {
            put(ApiBlock(BlockType.DeletionMark, guid = it, data = null))
        }
    }

    /**
     * Remove one or more existing [ApiBlock]
     */
    fun remove(vararg blocks: ApiBlock) {
        remove(*blocks.map { it.guid }.toTypedArray())
    }

    /**
     * remove one or more existing [DocBlock]
     */
    fun remove(vararg blocks: DocBlock) {
        remove(*blocks.map { it.block.guid }.toTypedArray())
    }

    internal suspend fun commit(): ApiDocUpdateResult {
        isCommitted = true
        return doc.client.call(
            IcwkApi.docUpdateBlocks,
            UpdateBlocksArgs(doc.docId, doc.lastSerial, modifications.values.toList())
        )
    }

}