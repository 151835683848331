package editor.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.rememberCoroutineScope
import document.Caret
import document.Fragment
import document.ParagraphStyle
import document.TextStyle
import editor.*
import editor.operations.setNamedParagraphTextStyle
import editor.plugins.domVersion
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import views.ParagraphTextStyle

private enum class RomanSymbol(val decimal: Int) {
    I(1),
    IV(4),
    V(5),
    IX(9),
    X(10),
    XL(40),
    L(50),
    XC(90),
    C(100),
    CD(400),
    D(500),
    CM(900),
    M(1000);

    companion object {
        fun closest(value: Int): RomanSymbol? =
            RomanSymbol.entries
                .sortedByDescending { it.decimal }
                .firstOrNull { value >= it.decimal }
    }
}

fun intToRomanNumeral(num: Int): String = RomanSymbol.closest(num)
    .let { symbol ->
        if (symbol != null) {
            "$symbol${intToRomanNumeral(num - symbol.decimal)}"
        } else {
            ""
        }
    }

fun toBase26(num: Int): String {
    val symbols = ('a'..'z').toList()

    if (num <= 26) return symbols[num - 1].toString()

    val quotient = num / 26
    val remainder = num % 26

    return toBase26(quotient) + symbols[remainder - 1]
}

fun toNumbered(address: List<Int>): String {
    val level = address.size
    val normalizedLevel = level % 3
    val lastNumber = address.lastOrNull() ?: return ""

    return when (normalizedLevel) {
        1 -> "${lastNumber + 1}."
        2 -> toBase26(lastNumber + 1) + "."
        0 -> intToRomanNumeral(lastNumber + 1) + "."
        else -> ""
    }
}

fun toBullets(address: List<Int>): String {
    val remainder = address.size % 3
    // '\uFEFF'
    return when (remainder) {
        1 -> "\u2022"
        2 -> "\u25e6"
        0 -> "\u25aa"
        else -> ""
    }
}

@Composable
fun renderParagraph(
    p: Fragment.Paragraph,
    attrBuilderContext: AttrBuilderContext<HTMLDivElement>?,
    caret: Caret?,
    style: TextStyle?,
    dc: DocContext,
    mode: RenderMode,
    blockVersion: Int? = null
) {
    val scope = rememberCoroutineScope()
//    console.log("RENDER PARAGRAPH", caret)
//    console.log("RENDER PARAGRAPH ${p.guid}")
//    console.log("[RENDER]: render ${p.guid} with version ${dc.domObserver.getVersion(p.guid)}")

    fun getParagraphTextStyle(paragraphStyle: ParagraphStyle?): ParagraphTextStyle? {
        val textStyle = paragraphStyle?.defaultTextStyle

        if (textStyle == null) return ParagraphTextStyle.NORMAL
        else return ParagraphTextStyle.entries.find { it.style == textStyle }
    }

    if (mode == RenderMode.DOCX) {
        P({
            p.paragraphStyle?.let {
                dc.getNamedStyle(it.name)?.combineWith(it) ?: it
            }?.buildAttributes(this, mode) ?: style {
                marginBottom(.5.em)
            }
        }) {
            for (element in p.elements) {
                when (element) {
                    is Fragment.StyledSpan -> renderStyledSpan(element, null, caret, style, dc, mode)
                    is Fragment.Paragraph -> renderParagraph(element, null, caret, style, dc, mode)
                    is Fragment.LinkedImage -> renderLinkedImage(element, null, dc)
                    is Fragment.StoredImage -> renderStoredImage(element, null, dc)
                    is Fragment.TableParagraph -> renderTableParagraph(element, null, caret, style, dc, mode)
                    is Fragment.Frame -> renderFrame(element, dc, mode)
//                else -> {
//                     most likely invisible element
//                }
                }
            }
        }
    } else {

        SideEffect {
//            dc.spellChecker.highlight(p.guid)

//            logger.warning { "Paragraph ${p.guid} recompose END" }
//            dc.domObserver.finish(p.guid)
        }

        Div({
            // We have to combine doc-wide named style (if used) with locally set
            // paragraph style (it set):
            p.paragraphStyle?.let {
                dc.getNamedStyle(it.name)?.combineWith(it) ?: it
            }?.buildAttributes(this) ?: classes("mb-2")

            attrBuilderContext?.invoke(this)

            style {
                if (caret?.blockId == p.guid && dc.showCaret) {
                    property("z-index", "3")
                    position(Position.Relative)
                }
                else property("z-index", "1")
            }
            id(p.guid)
            attr("data-bv", "$blockVersion")
            domVersion(dc.domObserver, p.guid)
        }) {
            if (caret?.blockId == p.guid && dc.showCaret) {
                Div({
                    style {
                        position(Position.Absolute)
                        property("left", "-166px")
                        property("width", "134px")
                    }
                }) {
                    Div(attrs = {
                        classes("btn-group", DOCUMENT_CONTROL_CLASS)
                        attr("role", "group")
                        style {
                            property("float", "right")
                        }
                    }) {
                        Button(attrs = {
                            classes("btn", "btn-outline-dark", "dropdown-toggle", "btn-sm")
                            attr("data-bs-toggle", "dropdown")
                            attr("aria-expanded", "false")
                        }) {
                            Span({ classes("d-xxl-none") }) {
                                Text('\u00B6'.toString())
                            }
                            Span({ classes("d-none", "d-xxl-inline") }) {
                                Text(getParagraphTextStyle(p.paragraphStyle)?.title ?: "Стиль параграфа")
                            }
                        }

                        Ul(attrs = { classes("dropdown-menu") }) {
                            ParagraphTextStyle.entries.forEach { s ->
                                Li {
                                    Button(attrs = {
                                        onClick {
                                            (it.target as HTMLElement).blur()
                                            scope.launch {
                                                dc.setNamedParagraphTextStyle(s.name, s.style)
                                            }
                                        }
                                        classes("dropdown-item")
                                    }) { Div(attrs = { s.style.buildAttributes(this) }) { Text(s.title) } }
                                }
                            }
                        }
                    }
                }
            }


            p.paragraphStyle?.firstLineIndent?.let { i ->
                if (i > 0) {
                    Div({
                        style {
                            width((i * 3).em)
//                        maxWidth((i * 3).em)
//                        minWidth((i * 3).em)
                            display(DisplayStyle.InlineBlock)
                        }
                    }) {
                        Text("$invisibleNBSP")
                    }
                }
            }

            p.paragraphStyle?.listStyle?.let { listStyle ->
                val i = p.paragraphStyle.indentLevel ?: 0
                var leftPosition = (3 * i).em
                Div({
                    style {
                        width((1.5).em)
//                        maxWidth((i * 3).em)
//                        minWidth((i * 3).em)
                        display(DisplayStyle.InlineBlock)
                        position(Position.Absolute)
                        left(0.5.em + leftPosition)
                    }
                }) {
                    when (listStyle) {
                        ParagraphStyle.List.Bullets -> Text(toBullets(p.listAddress))
                        ParagraphStyle.List.Numbers -> Text(toNumbered(p.listAddress))
                    }
                }
            }

//        println("render p, ${p.elements.size} elements: ${p.isBlank}")
            for (element in p.elements) {
                when (element) {
                    is Fragment.StyledSpan -> renderStyledSpan(element, null, caret, style, dc, mode, p.paragraphStyle)
                    is Fragment.Paragraph -> renderParagraph(element, null, caret, style, dc, mode, blockVersion)
                    is Fragment.LinkedImage -> renderLinkedImage(element, null, dc)
                    is Fragment.StoredImage -> renderStoredImage(element, null, dc)
                    is Fragment.TableParagraph -> renderTableParagraph(element, null, caret, style, dc, mode, blockVersion)
                    is Fragment.Frame -> renderFrame(element, dc, mode)
//                else -> {
//                     most likely invisible element
//                }
                }
            }
        }
    }
}