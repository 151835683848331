package net.sergeych.intecowork.tools

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import net.sergeych.boss_serialization.BossDecoder
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.mp_logger.Loggable
import net.sergeych.mp_logger.warning
import net.sergeych.mptools.truncateToSeconds

/**
 * Current system time truncated to seconds, recommended with json-serialized structures
 * to reduce size with dropping useless time precision
 */
fun nowToSeconds(): Instant = Clock.System.now().truncateToSeconds()

inline fun <reified T> T.encodeBoss(): ByteArray = BossEncoder.encode(this)

inline fun <reified T>ByteArray.decodeBoss(): T = BossDecoder.decodeFrom<T>(this)
//inline fun <reified T>UByteArray.decodeBoss(): T = BossDecoder.decodeFrom<T>(this.asByteArray())

suspend fun <T> Loggable.tryTimesOrThrow(n: Int=3, f: suspend ()->T): T {
    var attempt = 1
    while(true) {
        try {
            return f()
        }
        catch(t: Throwable) {
            warning { "failed attempt #$attempt of $n: $t" }
            if( attempt++ >= n ) throw t
        }
    }
}