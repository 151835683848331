package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.document
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.ApiUser
import net.sergeych.intecowork.api.ApiUserDetails
import net.sergeych.intecowork.api.ErrorCode
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.Passwords
import org.jetbrains.compose.web.attributes.*
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import tools.pluralize
import tools.randomId

@Composable
fun Registration(additionalContent: ContentBuilder<HTMLDivElement>) {
//    var loginName by remember { mutableStateOf("testUser") }
    var loginName by remember { mutableStateOf("") }
    var publicName by remember { mutableStateOf("") }
    var approvedPublicName by remember { mutableStateOf("") }
    var loginMessage by remember { mutableStateOf<String?>(null) }
    var codeMessage by remember { mutableStateOf<String?>(null) }
    var nickMessage by remember { mutableStateOf<String?>(null) }
    var nickOk by remember { mutableStateOf<Boolean?>(null) }
    var code by remember { mutableStateOf("OPENBETA24") }
    var loginOk by remember { mutableStateOf<Boolean?>(null) }
//    var password1 by remember { mutableStateOf("12345qwert") }
    var password1 by remember { mutableStateOf("") }
    var password1Message by remember { mutableStateOf("") }
    var password1Ok by remember { mutableStateOf<Boolean?>(null) }
//    var password2 by remember { mutableStateOf("12345qwert") }
    var password2 by remember { mutableStateOf("") }
    var password2Ok by remember { mutableStateOf<Boolean?>(null) }
    var inProgress by remember { mutableStateOf(false) }
    var agreement by remember { mutableStateOf(false) }

    val scope = rememberCoroutineScope()

    fun checkLogin() {
        when {
            loginName.trim() != loginName -> {
                loginOk = false
                loginMessage = "логин не должен содержать пробелов в начале и конце"
            }

            loginName.isEmpty() -> {
                loginOk = false
                loginMessage = "логин не может быть пустым"

            }

            loginName.length < 3 -> {
                loginOk = false
                val n = 3 - loginName.length
                loginMessage = "слишком короткое, введите еще $n ${pluralize(n, "символ", "символа", "символов")}"

            }

            loginName[0] == '.' -> {
                loginOk = false
                loginMessage = "логин не может начинаться с точки"
            }

            else -> {
                loginOk = null
                loginMessage = null
                scope.launch {
                    println("Start check login")
                    loginOk = client.isLoginNameAvailable(loginName)
                    println("end check login: $loginOk")
                    loginMessage = if (loginOk == false) "неподходящий логин, придумайте другой" else null
                }
            }
        }
    }

    fun checkNick(nick: String, checkAvailable: Boolean = true) {
        nickOk = null
        nickMessage = "проверяем"
        ApiUser.checkName(nick)?.let {
            nickMessage = "публичное имя $it"
            nickOk = false
            return
        }
        if (checkAvailable) {
            scope.launch {
                try {
                    val nick2 = client.call(client.api.getAvailableNick, nick)
                    if (nick2 != publicName) {
                        publicName = nick2
                        nickMessage = "числа в конце делают имя уникальным на сервисе"
                    } else
                        nickMessage = ""
                    approvedPublicName = nick2
                    nickOk = true
                } catch (e: Exception) {
                    nickMessage = "не удается проверить"
                }
            }
        } else nickMessage = ""
    }

    fun checkPasswords() {
        val strength = Passwords.estimateBitStrength(password1)
        when {
            password1.isEmpty() -> {
                password1Ok = false
                password1Message = "пароль не может быть пустым"
            }

            strength < 32 -> {
                password1Ok = false
                password1Message = "слишком слабый пароль (~$strength бит)"
            }

            else -> {
                password1Ok = true
                password1Message = "оценочная стойкость $strength бит"
            }
        }
        password2Ok = password1Ok == true && password1 == password2
    }

    LaunchedEffect(true) {
        checkLogin()
        checkNick(publicName)
        checkPasswords()
    }

    if (inProgress) {
        WaitMessage(
            "Идет регистрация",
            """Пожалуйста подождите, этот процесс занимает определенное время, 
                |создаются все необходимые ключи для безопасной работы.
            """.trimMargin()
        )
    } else {
        Div {
            H3 { Text("Создание учетной записи:") }
            val loginId = textField(
                loginName,
                "Логин для входа",
                isValid = loginOk,
                message = loginMessage
            ) {
                loginName = it
                checkLogin()
            }
            // Hide password popup
            Input(InputType.Password) {
                style { display(DisplayStyle.None) }
            }
            textField(
                publicName,
                "Имя, видимое для других пользователей",
                message = nickMessage,
                isValid = nickOk,
                autocomplete = false,
                id = "publicName",
                onBlur = { checkNick(publicName, true) }
            ) {
                if (publicName != it) {
                    publicName = it
                    println("publicName: $publicName approvedPublicName: $approvedPublicName")
                    if (approvedPublicName != it)
                        checkNick(it, false)
                }
            }
            textField(
                password1,
                "Пароль",
                type = InputType.Password,
                isValid = password1Ok,
                message = password1Message
            ) {
                password1 = it
                checkPasswords()
            }
            textField(
                password2,
                "Повторно пароль",
                type = InputType.Password,
                isValid = password2Ok,
                message = if (password2Ok == false && password1.isNotBlank()) "пароли не совпадают" else null
            ) {
                password2 = it
                checkPasswords()
            }
            textField(
                code,
                "Код активации",
                type = InputType.Text,
                isValid = codeMessage == null,
                message = codeMessage,
                disabled = true
            ) {
                code = it
            }

            val controlId: String = remember { randomId(11) }

            Div({
                classes("mb-3", "form-check")
                classNames("mb-3 form-check")
            }) {
                Input(InputType.Checkbox) {
                    id(controlId)
                    onInput { agreement = it.value }
                    classes("form-check-input")
                    checked(agreement)
                }
                Label(controlId, {
                    classes("form-check-label")
                    forId(controlId)
                }) {
                    +"Я согласен/на с "
                    A("https://docollabr.ru/ZZKf8PmGW2C#Lobq_zbsex3h56NqBUkIcNvs96JhhQ2zSKYeM6RHIIk", {
                        target(ATarget.Blank)
                    }) { +" правилами работы сайта" }
                    +" и "
                    A("https://docollabr.ru/De-d8c8tp70#TnshSoVLf99t3S_aq7MeXaGVFa7Hy7D64nJoDPsEXeY",{
                        target(ATarget.Blank)
                    }) { +"правилами обработки данных" }
                    +"."
                }
            }

            Bn({
                classNames("btn-primary me-2")
                if (inProgress || publicName.isBlank() || loginOk == false || password1Ok == false
                    || password2Ok != true || nickOk != true || !agreement
                )
                    disabled()
                onClick {
                    inProgress = true
                    globalLaunch {
                        val rr = client.register(
                            ApiUserDetails(
                                loginName = loginName, name = publicName
                            ),
                            if (code.isNotBlank()) code else null,
                            password1,
                            mainKeyStrength = 4096,
                            loginKeyStrength = 4096
                        )
                        inProgress = false
                        if (rr.isOk) {
                            Toaster.info("Успешная регистрация")
                            ShowSecret(rr.resultOrThrow())
                        } else {
                            loginMessage = null
                            when {
                                ErrorCode.LoginInUse in rr -> {
                                    loginOk = false
                                    loginMessage = "Имя недоступно"
                                }

                                ErrorCode.CodeIsRequired in rr -> codeMessage = "Для регистрации требуется код"
                                ErrorCode.NickInUse in rr -> nickMessage = "Имя пользователя уже использовано"
                                ErrorCode.CodeIsInvalid in rr -> codeMessage = "неправильный или неподходящий код"
                                ErrorCode.UnknownError in rr ->
                                    Toaster.error("ошибка при регистрации, попробуйте еше раз позже")
                            }
                        }
                        inProgress = false
                    }
                }
            }) {
                if (inProgress) {
                    Di("spinner-border spinner-border-sm d-inline-block me-1") {}
                }
                Text("Регистрация")
            }
            additionalContent()
            LaunchedEffect("registrationFocus") {
                (document.getElementById(loginId) as? HTMLInputElement)?.focus()
            }
        }
    }
}