package net.sergeych.intecowork.api

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import net.sergeych.intecowork.IcwkClient

@Serializable
data class ApiAgreement(
    val code: String,
    val title: String,
    val action: Action,
    val version: Int = 1,
    val minimalVersion: Int? = null,
) {

    enum class Action(val text: String,val requireUser: Boolean=true) {
        GuestAccess("гостевой доступ", false),
        UserAccess("пользоательский доступ");

        companion object {
            fun fromTextOrNull(text: String): Action? {
                val t = text.trim().lowercase()
                for (x in Action.values()) if (x.text == t) return x
                return null
            }

            fun fromText(text: String): Action =
                fromTextOrNull(text)
                    ?: throw IllegalArgumentException("неизвестная сторона соглашения: $text")
        }
    }

    suspend fun html(client: IcwkClient): String =
        client.call(IcwkApi.acptGetHtml, code)
}

@Serializable
data class ApiAcceptance(
    val agreement: ApiAgreement,
    val createdAt: Instant? = null,
    val acceptedVersion: Int? = null,
) {

    @Transient
    val accepted = createdAt?.let { acceptedVersion != null }

    @Transient
    val isAccepted = accepted == true

    @Transient
    val isDeclined = accepted == false

    @Transient
    val code = agreement.code

    /**
     * @return true if the user agreement is required to proceed
     */
    fun requiresUserDecision(action: ApiAgreement.Action): Boolean {
        // only if requested action matches:
        if( action != agreement.action) return false

        // if it is already accepted, then only if the version is changed:
        // if it is not, no action is required
        if( agreement.minimalVersion != null && acceptedVersion != null &&
            acceptedVersion < agreement.minimalVersion)
            return true

        // then if user did not process it:
        return createdAt == null
    }

    fun toAccepted(): ApiAcceptance =
        copy(acceptedVersion = agreement.version, createdAt = Clock.System.now())
    fun toDeclined(): ApiAcceptance =
        copy(acceptedVersion = null, createdAt = Clock.System.now())
    fun toRevoked(): ApiAcceptance =
        copy(acceptedVersion = null, createdAt = null)
}

@Serializable
data class UpdateAcceptanceArgs(
    val code: String,
    val accepted: Boolean?
)



