package net.sergeych.intecowork.tools

import net.sergeych.mp_tools.decodeBase64Compact
import net.sergeych.mp_tools.encodeToBase64Compact

/**
 * Encode to compact base64 form (with no trailing '=' with character substitution to use
 * inside URL parts, namely replacing '+' and '/' characters of standard Base64  by '-' and '_'.
 * Use [decodeBase64Url] to decode such representation.
 */

fun ByteArray.encodeToBase64Url(): String =
    encodeToBase64Compact().replace('+','-').replace('/', '_')

/**
 * Decode base64 url encoded binary data. See [encodeToBase64Url] for more
 */
fun String.decodeBase64Url(): ByteArray =
    replace('-','+').replace('_', '/').decodeBase64Compact()