package net.sergeych.intecowork

/**
 * Local events are used by client implementation onlu to propagate
 * state change data accross listeners in the client. There are never
 * transmitted by the networ and are always caused by some local
 * event.
 *
 * Use [IcwkClient.localEvents] to exchange local events.
 */
sealed class LocalEvent {
    sealed class Doc(): LocalEvent() {
        abstract val docId: Long

        class Dirty(override val docId: Long) : Doc()
        class Saving(override val docId: Long) : Doc()
        class Saved(override val docId: Long) : Doc()
        class SaveFailed(override val docId: Long) : Doc()
    }
}