package net.sergeych.tools

import kotlinx.serialization.Serializable

@Serializable
class Opt<T>(val value: T?) {
    override fun hashCode(): Int {
        return value.hashCode()
    }

    override fun equals(other: Any?): Boolean {
        if (other !is Opt<*>) return false
        if (value == null && other.value == null) return true
        if( value == null || other.value == null) return false
        return value == other.value
    }

    val isNull by lazy { value == null }
    val isNotNull by lazy { value != null }

    fun orElse(f: ()->T) = value ?: f()

    override fun toString(): String = "Opt[$value]"
}

