package editor.operations

import document.*
import editor.*
import views.TextStyleMixin
import kotlin.math.min

fun Chain<Block>.deleteTerminalFragment(guid: String) {
    val logger = log("deleteTerminalFragment")
    logger("run")

    val block = elements.find { it.find(guid) != null } ?: return
    val updated = block.withoutFragment(guid)

    updateBlockAndClean(updated)
}

fun Chain<Block>.updateTerminalFragment(f: Fragment) {
    val logger = log("deleteTerminalFragment")
    logger("run")

    val block = elements.find { it.find(f.guid) != null } ?: return
    val updated = block.withUpdatedFragment(f)

    updateBlockAndClean(updated)
}

suspend fun DocContext.insertTerminalFragmentAtCaret(f: Fragment) {
    replay.insertTerminalFragmentAtCaret(f)

    runTransaction("INSERT TERMINAL FRAGMENT", shouldForceRecord = true) { chain, style ->
        chain.insertTerminalFragment(f)
    }
}

suspend fun DocContext.updateTerminalFragment(f: Fragment) {
    replay.updateTerminalFragment(f)

    runTransaction("UPDATE TERMINAL FRAGMENT") { chain, style ->
        chain.updateTerminalFragment(f)
    }
}

suspend fun DocContext.deleteTerminalFragment(guid: String) {
    replay.deleteTerminalFragment(guid)

    runTransaction("DELETE TERMINAL FRAGMENT", shouldForceRecord = true) { chain, style ->
        chain.deleteTerminalFragment(guid)
    }
}

fun applyMixinL0(p: IParagraph, mixin: TextStyleMixin, shouldEnable: Boolean): IParagraph {
    val elements = p.elements.map {
        if (it is Fragment.StyledSpan) {
            var updatedStyle = (it.textStyle ?: TextStyle())
            updatedStyle = if (shouldEnable) mixin.enable(updatedStyle) else mixin.disable(updatedStyle)
            it.copy(textStyle = updatedStyle)
        } else it
    }

    return p.makeCopy(elements = elements)
}

suspend fun DocContext.toggleTextStyleMixin(mixin: TextStyleMixin) {
    doc.withLock("toggleTextStyleMixin") {
        withAsyncCaret {
            selection.range?.let {
                val rangeStyle = getStyle(it)
                setStyle(it, mixin, !mixin.isEnabled(rangeStyle))
            } ?: run {
                val style = getStyle(it)
                setNextTransactionStyle(mixin.toggle(style))
            }
        }
    }
}

fun DocChain.removeCharLeft(atCaret: Caret? = null) {
    val logger = log("removeCharLeftParagraph")
    logger("run")
    val c = atCaret ?: caret
    ?: throw BugException("Can't perform operation without caret")

    val block = caretBlock(c)
    val span = caretSpan(c)

    var updatedBlock = block
    var caretAfter: Caret? = c

    if (c.offset > 0) {
        // remove char from span
        val rightText = span.textRight(c)
        caretAfter = caretLeft(c) ?: throw BugException("can't move caret left with offset > 0")
        val leftText = span.textLeft(caretAfter)

//        console.log("OFFSET > 0, ${c.toFullString()} ${caretAfter.toFullString()}")
//        console.log("OLD TEXT = '${span.text}' new text = '${leftText}' + '${rightText}' ")
        val updatedSpan = span.copy(leftText + rightText)
        updatedBlock = block.withUpdatedFragment(updatedSpan)

        updateBlockAndClean(updatedBlock, caretAfter)
    } else {
        // clear list?
        block.paragraphStyle?.let { s1 ->
            if (s1.listStyle != null) {
                //updatedBlock = block.copy(paragraph = block.paragraph ps . copy (listStyle = null))
                val s2 = s1.copy(listStyle = null)
                setParagraphStyle(s2)
                return
            }
        }

        // first position: remove block if empty or concatenate with previous if not
        val caretContainer = block.find(c.path[c.path.size - 2]) as? Fragment.Paragraph
            ?: throw BugException("Can't find caret container")
        val prevTF = caretContainer.prevTerminalFragment(span.guid)

        if (prevTF != null) {
            // remove char from previous span or delete previous terminal fragment
            if (prevTF is Fragment.StyledSpan && !prevTF.isLink()) {
                // remove char from previous span
//                logger("PREV TF IS STYLED SPAN, UPDATE")
                if (prevTF.lastOffset == 0) throw BugException("there's another empty span. Optimize error?")

                val updatedTF = prevTF.copy(prevTF.text.substring(0, prevTF.text.length - 1))
                updatedBlock = block.withUpdatedFragment(updatedTF)
                updateBlockAndClean(updatedBlock, caretAfter)
            } else {
                // remove previous terminal fragment
//                logger("PREV TF IS NOT STYLED SPAN, DELETE")
                updatedBlock = block.withoutFragment(prevTF)

                updateBlockAndClean(updatedBlock, caretAfter)
            }
        } else {
            // caret at caret container 0 offset, glue container to previous
            when (c.rootType) {
                CaretRoot.PARAGRAPH -> {
                    if (elements.first().guid != block.guid) {
                        // concat to previous block if not table. Otherwise, delete table
//                        logger("BLOCK IS NOT ROOT, CONCAT")
                        var leftBlock = prev(block) ?: throw BugException("Can't get prev block")
                        if (leftBlock.paragraph is Fragment.TableParagraph) delete(leftBlock.guid)
                        else {
                            caretAfter = leftBlock.caretAtEnd()
                            leftBlock = leftBlock.appendContent(block)
                            updateBlockAndClean(leftBlock, caretAfter)
                            delete(block.guid)
                        }
                    } else {
                        // we are in root block, at start. Nothing to do
                    }
                }

                CaretRoot.TABLE -> {
                    // concat cell paragraphs
                    val cell = block.find(c.path[1]) as? Fragment.Paragraph
                        ?: throw BugException("Can't find cell")

                    val containerIndex = cell.elements.indexOfFirst { it.guid == caretContainer.guid }

                    if (containerIndex > 0) {
                        var previousContainer = cell.elements[containerIndex - 1] as? Fragment.Paragraph
                            ?: throw BugException("Cell is broken")

                        previousContainer += caretContainer

                        updatedBlock = updatedBlock.withoutFragment(caretContainer.guid)
                        updatedBlock = updatedBlock.withUpdatedFragment(previousContainer)
                        caretAfter = updatedBlock.caretAt(c.spanId, c.offset)

                        updateBlockAndClean(updatedBlock, caretAfter)
                    } else {
                        logger("Root paragraph of cell, nothing to do")
                    }
                }

                else -> {}
            }
        }
    }
}

suspend fun DocContext.delete(r: CRange) {
    replay.delete(r)

    runTransaction("DELETE SELECTION") { chain, style ->
        deleteSelectionIfExists(chain)
    }
}

suspend fun DocContext.onBackspace(isCtrl: Boolean) {
    replay.onBackspace(isCtrl)

    val shouldForceRecord = isCtrl || selection.range != null

    runTransaction("BACKSPACE", shouldForceRecord = shouldForceRecord) { chain, style ->
        if (isCtrl) {
            chain.caret?.let { right ->
                val left = caretToNextWord(false) ?: return@let

                getCRange(left, right)?.let {
                    chain.delete(it)
                }
            }
        } else {
            selection.range?.let {
                selection.clear()
                chain.delete(it)
            } ?: run {
                chain.removeCharLeft()
            }
        }
    }
}

fun fixParagraph(p: Fragment.Paragraph): Fragment.Paragraph {
    var updated = mutableListOf<Fragment>()

    var i = 0

    var isPrevStyledSpan = true

    while (i < p.elements.size) {
        val el = p.elements[i]
        val isStyledSpan = el is Fragment.StyledSpan

        if (i == 0 && !isStyledSpan) {
            console.log("insert span at beginning of ${p.guid}")
            updated.add(Fragment.StyledSpan(""))
        }

        if (!isStyledSpan && !isPrevStyledSpan) {
            console.log("consecutive non-text fragments in ${p.guid}, add span")
            updated.add(Fragment.StyledSpan(""))
        }

        updated.add(el)

        i++
    }

    if (updated.last() !is Fragment.StyledSpan) {
        console.log("insert span at end of ${p.guid}")
        updated.add(Fragment.StyledSpan(""))
    }

    updated = updated.map {
        if (it is Fragment.StyledSpan) {
            if (it.text.contains("$invisibleNBSP")) console.log("Remove invisible space from ${it.guid}")
            it.copy(text = it.text.replace("$invisibleNBSP", ""))
        } else it
    }.toMutableList()

    val pUpdated = p.copy(updated)

//    if (p.guid == "DLQI_MkpiXc06jPD2") {
//        var i = 0
//
//        while (i < updated.size) {
//            console.log("CHECK CHILDREN ${i}", updated[i] == p.elements[i])
//            i++
//        }
//
//        console.log("CHECK >>>>>>>>>>>>>>>>>>>.", p == pUpdated)
//        console.log("PROPS: ",
//            p.guid == pUpdated.guid,
//            p is Fragment.Paragraph, pUpdated is Fragment.Paragraph,
//            p.elements == pUpdated.elements,
//            p.paragraphStyle == pUpdated.paragraphStyle,
//            p.listAddress == pUpdated.listAddress
//            )
//    }

    return pUpdated
}

fun fixBlock(b: Block): Block {
//    console.log("Fix block ${b.guid}")
    val p = b.paragraph

    val updated = when (p) {
        is Fragment.TableParagraph -> {
            val elements = p.elements.map {
                if (it !is IParagraph) throw BugException("Broken table cell")
                var cell = it as IParagraph

                val brokenP = cell.elements.find { it !is Fragment.Paragraph }
                if (brokenP != null) throw BugException("Broken table cell content")

                val paragraphs = cell.elements as List<Fragment.Paragraph>
                val updated = paragraphs.map { fixParagraph(it) }

                cell.makeCopy(updated).toFragment()
            }

            p.copy(elements)
        }

        is Fragment.Paragraph -> {
            fixParagraph(p)
        }

        is Block -> {
            throw BugException("nested block")
        }
    }
//    console.log("Fix block ${b.guid} done")
    return b.copy(updated)
}

fun Chain<Block>.fixV1() {
    var i = 0

    val c = caret ?: throw BugException("Can't fix without caret")

    while (i < elements.size) {
        val block = elements[i]

//        if (block.guid == "DLQI_MkpiXc06jPD2") {
//            console.log("BEFORE!!!!")
//            block.printFull()
//        }
        val fixed = fixBlock(block)
//        if (block.guid == "DLQI_MkpiXc06jPD2") {
//            console.log("AFTER!!!!")
//            block.printFull()
//        }

        if (block != fixed) {
//            console.log("Block ${block.guid} != ${fixed.guid}")
            if (c.blockId == block.guid) {
                val offset = min(block.getOffset(c), fixed.lastOffset)
                caret = fixed.caretAt(offset)
            }

            updateBlockAndClean(fixed, caret)
        } else {
            val cleaned = cleanBlock(block, caret)
            if (cleaned.block != block) {
                update(cleaned.block)
                caret = cleaned.caret
            }
        }

        i++
    }
}

suspend fun DocContext.fixV1() {
    replay.fixV1()

    runTransaction("FIX V1") { chain, transactionStyle ->
        chain.fixV1()
    }
}