package net.sergeych.intecowork.tools

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import net.sergeych.mptools.truncateToSeconds

/**
 * Current system time truncated to seconds, recommended with json-serialized structures
 * to reduce size with dropping useless time precision
 */
fun nowToSeconds(): Instant = Clock.System.now().truncateToSeconds()
