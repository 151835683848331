package editor.plugins

import Browser
import controls.*
import document.*
import editor.DebugList
import editor.DocContext
import editor.PackageLogger
import editor.Point
import editor.operations.CRange
import kotlinx.browser.window
import kotlinx.datetime.Clock
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.mp_tools.decodeBase64
import net.sergeych.mp_tools.encodeToBase64Compact
import org.jetbrains.compose.web.dom.Text
import views.TextStyleMixin

const val docContextName = "dc"

val replayLogger = PackageLogger("replay", "Maroon", DebugList.replay)

fun Caret.replay(): String {
    val spath = path.map { "\"${it}\"" }.joinToString(", ")
    return "Caret(listOf($spath), ${offset})"
}

fun CRange.replay(): String {
    return "CRange(${left.replay()}, ${right.replay()})"
}

fun Point.replay(): String {
    return "Point(\"${x}\".toDouble(), \"${y}\".toDouble())"
}

fun MouseCaret.replay(): String {
    return "MouseCaret(${point.replay()}, ${caret?.replay()})"
}

fun MouseUpEvent.replay(): String {
    return "MouseUpEvent(${isDouble}, ${previous?.replay()}, ${next.replay()}, ${down.replay()})"
}

fun randomText(size: Int): String {
    val range = 'a' ..'z'
    val letter = range.random()

    return letter.toString().repeat(size)
}

val blackPixel = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=".decodeBase64()

fun Fragment.anonymousCopy(): Fragment {
    return when(this) {
        is Fragment.StyledSpan -> copy(text = randomText(text.length))
        is Fragment.LinkedImage -> copy()
        is Fragment.Frame -> {
            when (type) {
                "image" -> copy(bin = blackPixel)
                else -> {
                    console.error("unsupported type of frame")
                    copy()
                }
            }
        }

        is Fragment.StoredImage -> copy(bin = blackPixel)
        is IParagraph -> (this as IParagraph).anonymousCopy().toFragment()
    }
}

fun IParagraph.anonymousCopy(): IParagraph {
    val anonymousElements = elements.map {
        when(it) {
            is IParagraph -> (it as IParagraph).anonymousCopy().toFragment()
            else -> (it as Fragment).anonymousCopy()
        }
    }

    return makeCopy(anonymousElements)
}

fun Block.anonymousCopy(): Block {
    return copy(paragraph.anonymousCopy())
}

class Replay(val dc: DocContext, val isReplay: Boolean = false) {
    var initialBlocks: List<Block> = emptyList()
    var initialBlockVars: List<String> = emptyList()
    var initialCaret: Caret? = null

    var arguments: List<String> = emptyList()
    var operations: List<String> = emptyList()

    fun init(blocks: List<Block>, caret: Caret?) {
        if (isReplay) return

        initialBlocks = blocks
        initialCaret = caret
        arguments = emptyList()
        operations = emptyList()

        initialBlockVars = blocks.map { addArgument(it) }
    }

    fun insertTerminalFragmentAtCaret(f: Fragment) {
        if (isReplay) return
        val replay = addArgument(f)

        operations += "$docContextName.insertTerminalFragmentAtCaret(${replay})"
    }

    fun updateTerminalFragment(f: Fragment) {
        if (isReplay) return
        val fReplay = addArgument(f)

        operations += "$docContextName.updateTerminalFragment(${fReplay})"
    }

    fun deleteTerminalFragment(guid: String) {
        if (isReplay) return
        val fReplay = addArgument(guid)

        operations += "$docContextName.deleteTerminalFragment(${fReplay})"
    }

    fun delete(r: CRange) {
        if (isReplay) return
        operations += "$docContextName.delete(${r.replay()})"
    }

    fun onBackspace(isCtrl: Boolean) {
        if (isReplay) return
        operations += "$docContextName.onBackspace(${isCtrl})"
    }

    fun fixV1() {
        if (isReplay) return
        operations += "$docContextName.fixV1()"
    }

    fun runMergeTransaction(theirBlocks: List<Block>) {
        if (isReplay) return
        val blocks = theirBlocks.map { addArgument(it) }.joinToString(", ")
        operations += "$docContextName.runMergeTransaction(listOf($blocks))"
    }

    fun setParagraphStyle(style: ParagraphStyle) {
        if (isReplay) return
        val replay = addArgument(style)
        operations += "$docContextName.setParagraphStyle($replay)"
    }

    fun setParagraphTextStyle(style: TextStyle) {
        if (isReplay) return
        val replay = addArgument(style)
        operations += "$docContextName.setParagraphTextStyle($replay)"
    }

    fun setNamedParagraphTextStyle(name: String, style: TextStyle) {
        if (isReplay) return
        val replay = addArgument(style)
        operations += "$docContextName.setNamedParagraphTextStyle(\"$name\", $replay)"
    }

    fun changeIndent(step: Int) {
        if (isReplay) return
        operations += "$docContextName.changeIndent($step)"
    }

    fun cycleParagraphTextAlign() {
        if (isReplay) return
        operations += "$docContextName.cycleParagraphTextAlign()"
    }

    fun cutSelection() {
        if (isReplay) return
        operations += "$docContextName.cutSelection()"
    }

    fun copy() {
        if (isReplay) return
        operations += "$docContextName.copy()"
    }

    fun paste(text: String, isPlain: Boolean) {
        if (isReplay) return
        val textA = addArgument(text)
        operations += "$docContextName.paste($textA, $isPlain)"
    }

    fun replace(range: CRange, replaceWith: String) {
        if (isReplay) return
        val rangeA = range.replay()
        val replaceA = addArgument(replaceWith)

        operations += "$docContextName.paste($rangeA, $replaceA)"
    }

    fun setStyle(range: CRange, mixin: TextStyleMixin, shouldEnable: Boolean) {
        if (isReplay) return
        val mixinA = "TextStyleMixin.${mixin.name}"

        operations += "$docContextName.setStyle(${range.replay()}, ${mixinA}, ${shouldEnable})"
    }

    fun splitBlockAtCaret() {
        if (isReplay) return
        operations += "$docContextName.splitBlockAtCaret()"
    }

    fun tableAddRow(insertAfter: Int, tableId: String, row: List<Fragment.Paragraph>?) {
        if (isReplay) return
        val rowA = if (row == null) null else {
            val ps = row.map { addArgument(it) }.joinToString(", ")
            "listOf($ps)"
        }

        operations += "$docContextName.tableAddRow($insertAfter, \"$tableId\", $rowA)"
    }

    fun tableAddCol(insertAfter: Int, tableId: String, col: List<Fragment.Paragraph>?) {
        if (isReplay) return
        val colA = if (col == null) null else {
            val ps = col.map { addArgument(it) }.joinToString(", ")
            "listOf($ps)"
        }

        operations += "$docContextName.tableAddCol($insertAfter, \"$tableId\", $colA)"
    }

    fun tableDeleteRow(rowIndex: Int, tableId: String) {
        if (isReplay) return
        operations += "$docContextName.tableDeleteRow($rowIndex, \"$tableId\")"
    }

    fun tableDeleteCol(colIndex: Int, tableId: String) {
        if (isReplay) return
        operations += "$docContextName.tableDeleteCol($colIndex, \"$tableId\")"
    }

    fun insertTable(rows: Int, cols: Int, hasHeader: Boolean) {
        if (isReplay) return
        operations += "$docContextName.insertTable($rows, $cols, $hasHeader)"
    }

    fun insertChar(ch: String) {
        if (isReplay) return
        operations += "$docContextName.insertChar(\"$ch\")"
    }

    fun forceUndo() {
        if (isReplay) return
        if (operations.size > 0) {
            val lastOperation = operations.last()
            var withoutLast = operations.dropLast(1)

            withoutLast += "$docContextName.replayUndoForce = true"

            operations = withoutLast + lastOperation
        }
    }

    fun undo() {
        if (isReplay) return
        operations += "$docContextName.undo()"
    }

    fun redo() {
        if (isReplay) return
        operations += "$docContextName.redo()"
    }

    fun onMouseUp(ev: MouseUpEvent) {
        if (isReplay) return
        operations += "$docContextName.onMouseUp(${ev.replay()})"
    }


    fun onArrowLeft(isShift: Boolean) { operations += "$docContextName.onArrowLeft($isShift)"}
    fun onArrowLeftAlt(isShift: Boolean) { operations += "$docContextName.onArrowLeftAlt($isShift)"}
    fun onArrowLeftCtrl(isShift: Boolean) { operations += "$docContextName.onArrowLeftCtrl($isShift)"}
    fun onArrowRight(isShift: Boolean) { operations += "$docContextName.onArrowRight($isShift)"}
    fun onArrowRightAlt(isShift: Boolean) { operations += "$docContextName.onArrowRightAlt($isShift)"}
    fun onArrowRightCtrl(isShift: Boolean) { operations += "$docContextName.onArrowRightCtrl($isShift)"}
    fun onArrowUp(isShift: Boolean, isCtrl: Boolean) { operations += "$docContextName.onArrowUp($isShift, $isCtrl)"}
    fun onArrowDown(isShift: Boolean, isCtrl: Boolean) { operations += "$docContextName.onArrowDown($isShift, $isCtrl)"}

    fun addArgument(a: Fragment.Paragraph): String {
        val stringValue = BossEncoder.encode(a).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<Paragraph>()"
        return addArg(replayValue)
    }

    fun addArgument(a: ParagraphStyle): String {
        val stringValue = BossEncoder.encode(a).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<ParagraphStyle>()"
        return addArg(replayValue)
    }

    fun addArgument(a: TextStyle): String {
        val stringValue = BossEncoder.encode(a).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<TextStyle>()"
        return addArg(replayValue)
    }

    fun addArgument(b: Block): String {
        val stringValue = BossEncoder.encode(b.anonymousCopy()).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<Block>()"
        return addArg(replayValue)
    }

    fun addArgument(a: Fragment): String {
        val stringValue = BossEncoder.encode(a.anonymousCopy()).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<Fragment>()"
        return addArg(replayValue)
    }

    fun addArgument(s: String): String {
        val replayValue = "\"${randomText(s.length)}\""
        return addArg(replayValue)
    }

    fun addArg(replayValue: String): String {
        val name = "arg${arguments.size}"

        val row = "val ${name} = ${replayValue}"

        arguments += row

        return name
    }

    fun getCase(): String {
        var case = ""

        arguments.forEach {
            case += it + '\n'
        }

        val blockList = initialBlockVars.joinToString(", ")

        case += "val doc = Doc()\n"
        case += "doc.initializeWithBlocks(listOf($blockList))\n"
        case += "val $docContextName = DocContext(doc, isReplay=true)\n"
        case += "$docContextName.caret = ${initialCaret?.replay()}\n"

        operations.forEach {
            case += it + '\n'
        }

        return case
    }

    fun saveCase(err: String) {
        if (isReplay) return

        val errMessage = "/*\n$err\n*/\n"
        val content = errMessage + getCase()

        val browser = Browser.browser.name
        val now = Clock.System.now().toEpochMilliseconds()
        val caseName = "Replay.${browser}.${now}.kt"
        val blob = js("new Blob([content], {type: \"text/plain;charset=utf-8\"});")
        js("saveAs(blob, caseName)")
    }

    fun onError(err: String, e: Throwable?) {
        replayLogger.log(err)
        e?.let { replayLogger.error(it) }
        if (isReplay) return

        dc.setIsActive(false)

        Router.pushModal { doClose ->
            Dialog("Ошибка во время выполнения операции") {
                staticBackdrop()

                body {
                    Di("container-fluid") {
                        Row {
                            Di("col") { Text("Вы можете скачать отчет об ошибке на компьютер, и вернуться к работе, обновив страницу") }
                        }
                    }
                }
                footer {
                    Bn({
                        classes(Variant.Primary.buttonClass)
                        onClick {
                            saveCase(err)
                        }
                    }) {
                        Text("Скачать отчет")
                    }
                    Bn({
                        classes(Variant.Secondary.buttonClass)
                        onClick {
                            window.location.reload()
                        }
                    }) {
                        Text("Обновить страницу")
                    }
                }
                onClose {
                    window.location.reload()
                }
            }
        }
    }
}