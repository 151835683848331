package net.sergeych.parsec3

import io.ktor.client.*
import io.ktor.client.plugins.websocket.*
import io.ktor.websocket.*
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.debug
import net.sergeych.mp_logger.exception
import net.sergeych.mp_logger.info
import net.sergeych.mp_tools.globalLaunch

/**
 * Construct websocket-based client with client-side API (called _from server_). This form is universal
 * and basically is needed when client is accepting synchronous data calls, e.g. pushes, from the server.
 * There is a simpler constructor when it is not needed.
 *
 * @param url server url to connect to
 * @param api client api to implement in the builder
 * @param exceptionsRegistry the registry of supported exceptions that can be safely transmitted over the network
 * @param builder client side api builder, called over api instance. Here client can _implement_ the commands that server
 *          could call.
 */
class Parsec3WSClient<S : WithAdapter, H : CommandHost<S>>(
    val url: String,
    api: H,
    override val exceptionsRegistry: ExceptionsRegistry = ExceptionsRegistry(),
    builder: AdapterBuilder<S, H>.() -> Unit,
) : LogTag("P3WSC"), Parsec3Transport<S> {

    private lateinit var connectionJob: Job
    val builder = AdapterBuilder(api, exceptionsRegistry, builder)

    private val _connectionFlow = MutableStateFlow(false)
    private val closeFlow = MutableStateFlow(false)

    // This one is used to send reconnection signal
    private val reconnectFlow = MutableStateFlow(false)
    override val connectedFlow: StateFlow<Boolean> = _connectionFlow


    init {
        start()
    }

    override fun close() {
        if (closeFlow.value == false) closeFlow.value = true
    }

    override fun reconnect() {
        reconnectFlow.value = true
    }

    var deferredAdapter = CompletableDeferred<Adapter<S>>()
        private set

    override suspend fun adapter(): Adapter<S> = deferredAdapter.await()

    fun start() {
        connectionJob = globalLaunch {
            while (closeFlow.value != true) {
                try {
                    reconnectFlow.value = false
                    debug { "trying to connect to $url" }
                    client.webSocket(url) {
                        info { "Connected to $url" }
                        val a = builder.create { send(Frame.Binary(true, it)) }
                        a.onCancel = { close() }
                        _connectionFlow.value = true
                        launch { closeFlow.collect { if (it == true) close() } }
                        launch { reconnectFlow.collect { if (it == true) close() } }
                        deferredAdapter.complete(a)
                        for (f in incoming)
                            if (f is Frame.Binary) a.receiveFrame(f.data)
                        debug { "disconnecting $url" }
                        _connectionFlow.value = false
                        deferredAdapter = CompletableDeferred()
                        a.close()
                        cancel()
                    }
                    info { "connection to $url is closed normally" }
                }
                catch(x: CancellationException) {
                    info { "parsec3 connector job cancelled" }
                    return@globalLaunch
                }
                catch(t: Throwable) {
                    exception { "connection process failed, will try to reconnect" to t }
                    _connectionFlow.value = false
                    if( !deferredAdapter.isActive)
                        deferredAdapter = CompletableDeferred()
                    delay(200)
                }
            }
            debug { "exiting ws connection loop" }
        }
    }

    companion object {
        private val client = HttpClient {
            install(WebSockets) {
                // Configure WebSockets
            }
        }

        /**
         * Simplified client constructor for the case when client does not receives commands (e.g. pushes)
         * from the server.
         * @param url server url
         * @param exceptionsRegistry converter of exceptions that can be received from the remote.
         */
        operator fun invoke(url: String, exceptionsRegistry: ExceptionsRegistry = ExceptionsRegistry())
                : Parsec3WSClient<WithAdapter, CommandHost<WithAdapter>> {
            return Parsec3WSClient(url, CommandHost<WithAdapter>(), exceptionsRegistry) {}
        }

        @Suppress("unused")
        fun <S: WithAdapter>withSession(url: String): Parsec3WSClient<S, CommandHost<S>> {
            return Parsec3WSClient(url, CommandHost(), ExceptionsRegistry()) {}
        }

    }
}
