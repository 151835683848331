package editor.operations

import document.*
import editor.Chain
import editor.DocContext
import editor.updateBlockAndClean
import net.sergeych.mp_logger.debug
import net.sergeych.mp_logger.warning

suspend fun DocContext.changeFirstLineIndent(step: Int) {
    if( step == 0) {
        warning { "changeFirstLineIndent: запрос на нулевое изменение игнорируется" }
        return
    }

    doc.withLock("changeFirstLineIndent") {
        var block = caretBlock()
        val s1 = (block.paragraphStyle ?: ParagraphStyle.normal)
        val s2 = s1.copy(firstLineIndent = step + (s1.firstLineIndent ?: 0))
        if (s2.indentLevel in 0..ParagraphStyle.MaxFirstLineIndent) {
            setParagraphStyle(s2)
        } else {
            debug { "не могу изменить отступ первой строки, сейчас ${s1.indentLevel}" }
        }
    }
}

suspend fun DocContext.toggleListStyle(listStyle: ParagraphStyle.List) {
    doc.withLock("toggleListStyle") {
        var block = caretBlock()

        if (caret?.rootType != CaretRoot.TABLE) {
            val s1 = (block.paragraphStyle ?: ParagraphStyle.normal)
            val newListStyle = if (listStyle == s1.listStyle) null else listStyle
            val s2 = s1.copy(listStyle = newListStyle)

            setParagraphStyle(s2)
        }
    }
}

suspend fun DocContext.setParagraphStyle(style: ParagraphStyle) {
    replay.setParagraphStyle(style)

    runTransaction("SET PARAGRAPH STYLE") { chain, transactionStyle ->
        chain.setParagraphStyle(style)
    }
}

suspend fun DocContext.setParagraphTextStyle(style: TextStyle) {
    replay.setParagraphTextStyle(style)

    runTransaction("SET PARAGRAPH TEXT STYLE", shouldForceRecord = true) { chain, transactionStyle ->
        val existingStyle = chain.getParagraphStyle() ?: ParagraphStyle()
        val pStyle = existingStyle.copy(defaultTextStyle = style)

        chain.setParagraphStyle(pStyle)
    }
}

suspend fun DocContext.setNamedParagraphTextStyle(name: String, style: TextStyle) {
    replay.setNamedParagraphTextStyle(name, style)

    runTransaction("SET NAMED PARAGRAPH TEXT STYLE", shouldForceRecord = true) { chain, transactionStyle ->
        val existingStyle = chain.getParagraphStyle() ?: ParagraphStyle()
        val pStyle = existingStyle.copy(defaultTextStyle = style, name = name)

        chain.setParagraphStyle(pStyle)
    }
}

fun Chain<Block>.changeIndent(step: Int, atCaret: Caret? = null) {
    if( step == 0) {
        warning { "changeIndent: запрос на нулевое изменение игнорируется" }
        return
    }

    val logger = log("changeIndent")
    logger("run")

    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    // can't change indent in table cell
    if (c.rootType != CaretRoot.PARAGRAPH) return

    val block = caretBlock(c)

    val s1 = (block.paragraphStyle ?: ParagraphStyle.normal)
    val s2 = s1.copy(indentLevel = step + (s1.indentLevel ?: 0))
    if( s2.indentLevel in 0..ParagraphStyle.MaxIndentLevel ) {
        setParagraphStyle(s2)
    }
    else {
        debug { "не могу изменить отступ, сейчас ${s1.indentLevel}" }
    }
}

suspend fun DocContext.changeIndent(step: Int) {
    replay.changeIndent(step)

    runTransaction("CHANGE INDENT") { chain, style ->
        chain.changeIndent(step)
    }
}

fun Chain<Block>.getParagraphStyle(atCaret: Caret? = null): ParagraphStyle? {
    val logger = log("getParagraphStyle")
    logger("run")

    val c = atCaret ?: caret
    ?: throw BugException("Can't perform operation without caret")

    val block = caretBlock(c)
    val paragraph = block.find(c.path[c.path.size - 2]) as Fragment.Paragraph?
        ?: throw BugException("Can't find caret container")

    return paragraph.paragraphStyle
    logger("done")
}

fun Chain<Block>.setParagraphStyle(style: ParagraphStyle, atCaret: Caret? = null) {
    val logger = log("setParagraphStyle")
    logger("run")

    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    val block = caretBlock(c)
    val paragraph = block.find(c.path[c.path.size - 2]) as Fragment.Paragraph?
        ?: throw BugException("Can't find caret container")

    if (paragraph.paragraphStyle != style) {
        val updated = paragraph.copy(paragraphStyle = style)
        val updatedBlock = if (updated.guid == block.guid) {
            block.withUpdatedParagraph(updated)
        } else {
            block.withUpdatedFragment(updated)
        }

        updateBlockAndClean(updatedBlock)
    }

    logger("done")
}

fun Chain<Block>.setParagraphTextAlign(align: ParagraphStyle.TextAlign, atCaret: Caret? = null) {
    val logger = log("setParagraphTextAlign")
    logger("run")

    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    val block = caretBlock(c)
    val paragraph = block.find(c.path[c.path.size - 2]) as Fragment.Paragraph?
        ?: throw BugException("Can't find caret container")

    val currentStyle = paragraph.paragraphStyle ?: ParagraphStyle.normal

    setParagraphStyle(currentStyle.copy(textAlign = align))
    logger("done")
}

fun Chain<Block>.cycleParagraphTextAlign(atCaret: Caret? = null) {
    val logger = log("cycleParagraphTextAlign")
    logger("run")

    val c = atCaret ?: caret
    ?: throw BugException("Can't perform operation without caret")

    val block = caretBlock(c)
    val paragraph = block.find(c.path[c.path.size - 2]) as Fragment.Paragraph?
        ?: throw BugException("Can't find caret container")

    val currentStyle = paragraph.paragraphStyle ?: ParagraphStyle.normal
    val currentAlign = currentStyle.textAlign ?: ParagraphStyle.TextAlign.Left
    val aligns = listOf(
        ParagraphStyle.TextAlign.Left,
        ParagraphStyle.TextAlign.Center,
        ParagraphStyle.TextAlign.Right,
        ParagraphStyle.TextAlign.Justify
    )
    val currentIndex = aligns.indexOf(currentAlign)
    val nextIndex = (currentIndex + 1) % 4

    setParagraphTextAlign(aligns[nextIndex])
    logger("done")
}

suspend fun DocContext.cycleParagraphTextAlign() {
    replay.cycleParagraphTextAlign()

    runTransaction("CYCLE TEXT ALIGN") { chain, style ->
        chain.cycleParagraphTextAlign()
    }
}

suspend fun DocContext.onTab() {
    doc.withLock("onTab") {
        withAsyncCaret { caretBefore ->
            when(caretBefore.rootType) {
                CaretRoot.PARAGRAPH -> {
                    changeIndent(+1)
                }
                CaretRoot.TABLE -> {
                    val caretBox = caretBoxDOM(caretBefore) ?: return@withAsyncCaret
                    val x = lastMove.x ?: caretBox.center.x
                    val block = caretBlock(caretBefore)
                    val p = block.paragraph as Fragment.TableParagraph
                    val caretAfter = p.caretToNextCell(caretBefore) ?: caretDownBlockDOM(x)
                    moveCaret(caretAfter)
                }
                else -> {}
            }
        }
    }
}

suspend fun DocContext.onShiftTab() {
    doc.withLock("onShiftTab") {
       withAsyncCaret { caretBefore ->
           when(caretBefore.rootType) {
               CaretRoot.PARAGRAPH -> {
                   changeIndent(-1)
               }
               CaretRoot.TABLE -> {
                   val caretBox = caretBoxDOM(caretBefore) ?: return@withAsyncCaret
                   val x = lastMove.x ?: caretBox.center.x
                   val block = caretBlock(caretBefore)
                   val p = block.paragraph as Fragment.TableParagraph
                   val caretAfter = p.caretToPrevCell(caretBefore) ?: caretUpBlockDOM(x)
                   moveCaret(caretAfter)
               }
               else -> {}
           }
       }
    }

}