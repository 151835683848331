@file:Suppress("unused")

package net.sergeych.intecowork

import kotlinx.coroutines.async
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.coroutines.yield
import kotlinx.serialization.Serializable
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.boss_serialization_mp.decodeBoss
import net.sergeych.collections.ExpirableAsyncCache
import net.sergeych.intecowork.api.*
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.intecowork.tools.Debouncer
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.debug
import net.sergeych.mp_logger.error
import net.sergeych.mp_logger.exception
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.mptools.CachedExpression
import net.sergeych.parsec3.CommandDescriptor
import net.sergeych.parsec3.Parsec3WSClient
import net.sergeych.parsec3.WithAdapter
import net.sergeych.superlogin.PasswordDerivationParams
import net.sergeych.superlogin.client.ClientState
import net.sergeych.superlogin.client.LoginState
import net.sergeych.superlogin.client.Registration
import net.sergeych.superlogin.client.SuperloginClient
import net.sergeych.unikrypto.*
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

class ClientSession : WithAdapter() {

}

/**
 * Password processing results.
 */
enum class PasswordResult {
    /**
     * Password is OK, UI could be closed
     */
    OK,

    /**
     * Password is not OK, user should be instructed to retry it
     */
    WrongPassword,

    /**
     * Some network error causes the failure. Password could be good or wrong, this is
     * not deterined. User should be instructed to try again later.
     */
    NetworkError
}

/**
 * Password consumer receives passwords from the user input and attempts to decrypt necessary data.
 * It returns [PasswordResult] to describe the result f trying the password. In particular, [PasswordResult.OK]
 * means that the password is good and UI could be closed.
 */
typealias PasswordConsumer = suspend (String?) -> PasswordResult
/**
 * Password provider ia a suspend function called when the system needs main user password to decrypt something
 * critical. It receives [PasswordConsumer] callback to repeatedly (if need) feed passwords from the user input
 * utntil it accepts it. See [PasswordResult] for result codes.
 *
 * To cancel password input, give null to consumer and it will throw [PasswordRequiredException] to some caller
 */
typealias PasswordProvider = suspend (PasswordConsumer) -> Unit

@Serializable
internal data class SavedState(
    val loginToken: ByteArray,
    val user: ApiUserDetails,
    val dataKey: SymmetricKey,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as SavedState

        if (!loginToken.contentEquals(other.loginToken)) return false
        if (user != other.user) return false
        if (dataKey != other.dataKey) return false

        return true
    }

    override fun hashCode(): Int {
        var result = loginToken.contentHashCode()
        result = 31 * result + user.hashCode()
        result = 31 * result + dataKey.hashCode()
        return result
    }
}

@Suppress("UNCHECKED_CAST")
class IcwkClient(
    url: String,
    packedStateData: ByteArray? = null,
    private val savePackedStateData: (ByteArray) -> Unit = {},
) :
    LogTag("CCLI") {

    val api = IcwkApi
    private var savedState: SavedState? = packedStateData?.decodeBoss<SavedState>()
        set(value) {
            field = value
            savePackedStateData(BossEncoder.encode(value))
            _userFlow.value = value?.user
            if (value == null) {
                _mainPrivateKey = null
                globalLaunch {
                    debug { "clearing ring and keys" }
                    _mainPublicKey.clearCache()
                    _ring.clearCache()
                }
            }
        }

    private val _storage = MutableStateFlow<ApiStorage?>(null)
    val storageState = _storage.asStateFlow()

    private val _userFlow = MutableStateFlow(savedState?.user)
    internal val _events = MutableSharedFlow<ApiEvent>()

    val userFlow: StateFlow<ApiUserDetails?> = _userFlow.asStateFlow()
    val events: SharedFlow<ApiEvent> = _events.asSharedFlow()

    val currentUser: ApiUserDetails? get() = savedState?.user

    val requiredUser: ApiUserDetails get() = currentUser ?: throw NotAuthenticatedException()

    private val slc =
        SuperloginClient<ApiUserDetails, ClientSession>(
            Parsec3WSClient(url, ClientApi(), icwkExceptions) {
                newSession { ClientSession() }
                on(api.push) { event ->
                    if (event !is ApiEvent.Ping)
                        debug { "push: $event" }
                    globalLaunch {
                        _events.emit(event)
                    }
                }
            },
            savedData = savedState?.let { ss ->
                ClientState(ss.user.loginName, ss.loginToken, ss.user, ss.dataKey)
            }
        ).apply {
            registerExceptinos(
                icwkExceptions
            )
        }

    val isLoggedIn: Boolean get() = currentUser != null

    val localEvents = MutableSharedFlow<LocalEvent>()

    fun requireNotLoggedIn() {
        if (isLoggedIn) throw NotAuthenticatedException("недопустимая операция: пользователь авторизован")
    }

    fun requireLoggedIn() {
        if (isLoggedIn) throw NotAuthenticatedException("недопустимая операция: пользователь не авторизован")
    }

    private fun checkPublicKey() {
        // Later on we should check for partially created users
        // without main provate key and create one
        // TODO: implement or remove
    }

    val _mainPublicKey = CachedExpression<PublicKey>()
    suspend fun mainPublicKey(): PublicKey {
        return _mainPublicKey.get {
            call(api.getMyPublicKey)
        }
    }

    private var _mainPrivateKey: PrivateKey? = null

    private val _ring = CachedExpression<Keyring>()

    suspend fun mainRing(): Keyring = _ring.get {
        Keyring(dataKey, mainPrivateKey())
    }

    /**
     * Retrieve main data key. Main data key is only available in logged in state, so
     * you might check [isLoggedIn]
     */
    val dataKey: SymmetricKey
        get() = slc.dataKey ?: throw NotAuthenticatedException(
            "ключ данных недоступен, требуется аутентификация"
        )

    val dataKeyOrNull by slc::dataKey

    /**
     * Get the main private key, a random key created at the registration time.
     * It is cached in the client
     */
    suspend fun mainPrivateKey(): PrivateKey {
        // could be cached
        _mainPrivateKey?.let { return it }
        // otherwise we need data key that might be already known:
        return Container.decrypt<PrivateKey>(call(api.getMyMainKey), dataKey)
            ?.also { _mainPrivateKey = it }
            ?: throw InternalError("ошибка расшифровки главного ключа")
    }

    suspend fun getStorageStats(): ApiStorage {
        if (!isLoggedIn) throw NotAuthenticatedException()
        try {
            return call(api.userGetStorage)
        } catch (e: NotAuthenticatedException) {
            e.printStackTrace()
            throw IllegalStateException("should be logged in at this point")
        } catch (e: Exception) {
            exception { "не удается проверить свободное место" to e }
            throw e
        }
    }

    suspend fun setMyName(newName: String): String {
        val currentName = currentUser?.name
        return if (currentName != newName) {
            ApiUser.ensureNameIsValid(newName)
            val result = call(api.userSetName, newName)
            savedState?.let { savedState = it.copy(user = it.user.copy(name = result.name)) }
            result.name
        } else currentName
    }

    fun enumerateAllApiDocHeaders(
        group: ApiDoc.Group = ApiDoc.Group.All,
        parentId: Long?,
        fromAllFolders: Boolean = false,
        type: DocType? = null
    ): Flow<ApiDocHeader> = flow {
        var position = 0
        do {
            val headers =
                call(IcwkApi.docEnumerate, ApiEnumerateDocsArgs(
                    type, position, group = group, parentId = parentId, fromAllFolders = fromAllFolders))
            for (dh in headers) {
                emit(dh)
                position += 1
            }
        } while (headers.isNotEmpty())
    }


    /**
     * Enumerate all docs in order of appearance
     */
    fun enumerateDocs(
        type: DocType = DocType.Text,
        parentId: Long? = null,
    ): Flow<IcwkDocument> = flow {
        enumerateAllApiDocHeaders(type = type, parentId = parentId).collect {
            IcwkDocument.openById(this@IcwkClient, it.id)
                ?.let { emit(it) }
                ?: error { "не удалось открыть документ ${it.id}" }
        }
    }

    suspend fun getDocHeader(id: Long): ApiDocHeader? = call(api.docGetHeader, id)

    suspend fun getDoc(id: Long): IcwkDocument? = IcwkDocument.openById(this, id)

    suspend fun moveDocTo(docId: Long,newParentId: Long?): ApiDoc =
        call(IcwkApi.docMoveTo, ApiDocMoveArgs(listOf(docId), newParentId)).first()

    suspend fun moveAllTo(docIds: List<Long>,newParentId: Long?): List<ApiDoc> =
        call(IcwkApi.docMoveTo, ApiDocMoveArgs(docIds, newParentId))
    /**
     * Enumerate all docs, most recently updated first
     */
    fun enumerateMostRecentDocs(
        type: DocType = DocType.Text,
        parentId: Long? = null,
    ): Flow<IcwkDocument> = flow {
        enumerateAllApiDocHeaders(type = type,parentId = parentId).toList()
            .sortedBy { -it.updatedAt.epochSeconds } // thus, MRU first
            .forEach { header ->
                IcwkDocument.openById(this@IcwkClient, header.id)
                    ?.let { emit(it) }
                    ?: error { "не удалось открыть документ ${header.id}" }
            }
    }

    /**
     * List all docs in unpredictable order (opening documents is being done
     * in parallel so network lag could change order)
     */
    fun listDocs(group: ApiDoc.Group = ApiDoc.Group.All,
                 parentId: Long? = null,fromAllFolders: Boolean = false): Flow<IcwkDocument> {
        return flow {
            coroutineScope {
                // The problem: we should emit from this coroutine
                // so we read into channel:
                val c = Channel<IcwkDocument>(128)
                launch {
                    // The job "load all docs"
                    val jj = launch {
                        enumerateAllApiDocHeaders(group,parentId,fromAllFolders).collect {
                            // open the doc in parallel (long, requires more network activity)
                            launch {
                                IcwkDocument.openById(this@IcwkClient, it.id)
                                    // pass it through the channel
                                    ?.let { c.send(it) }
                                    ?: error { "не удалось открыть документ ${it.id}" }
                            }
                        }
                    }
                    // wait when all docs are scanned and passed to the channel
                    jj.join()
                    // only now we can close the channel - all docs are in:
                    c.close()
                }
                // to safely emit in the proper context:
                for (d in c)
                    emit(d)
            }
        }
    }

    private val usersCache = ExpirableAsyncCache<Long, ApiUser?>(1.minutes)

    suspend fun getUserById(id: Long): ApiUser? {
        return usersCache.getOrPut(id) {
            call(api.userGet, id)
        }
    }

    suspend fun createFolder(parentId: Long?, name: String): IcwkDocument {
        return IcwkDocument.create(this, DocType.Folder, parentId, name)
    }

    init {
//        println("packed saved state:\n${packedStateData?.toDump()}")
//        println("decoded saved state:\n${packedStateData?.decodeBoss<SavedState>()}")
//        println("Initial loaded saved state: $savedState")
//        println("Initial loaded saved user: ${savedState?.user}")
        globalLaunch {
            slc.state.collect {
                when (it) {
                    is LoginState.LoggedIn<*> -> {
                        val ld = it.loginData as ClientState<ApiUserDetails>
                        debug { "service state is now logged in as ${ld.data?.loginName}" }
                        savedState = SavedState(
                            ld.loginToken!!, ld.data!!,
                            ld.dataKey
                        )
                        launch {
                            runCatching { if (isLoggedIn) _storage.value = getStorageStats() }
                        }
                        checkPublicKey()
                    }

                    LoginState.LoggedOut -> {
                        debug { "service state changed: logged out" }
                        savedState = null
                        _storage.value = null
                    }
                }
            }
        }
        globalLaunch {
            val sdb = Debouncer(this, 1.seconds, 15.seconds) {
                _storage.value = getStorageStats()
            }
            events.collect {
//                if(it !is ApiEvent.Ping) println("** collected ev $it")
                if (it is ApiEvent.User.LimitsChanged) {
                    _storage.value = it.storage
                } else if (isLoggedIn && it.isStorageChangingEvent) {
                    sdb.schedule()
                }
            }
        }
    }

    /**
     * Simplified registration call, usually for testing purposes. It is advised
     * to use version that takes [ApiUserDetails] in productino code
     */
    suspend fun register(
        loginName: String,
        password: String,
        name: String = loginName,
        code: String? = null,
        pbkdfRounds: Int = 50000,
        loginKeyStrength: Int = 4096,
        mainKeyStrength: Int = 4096,
    ): Multiresult<String> =
        register(ApiUserDetails(loginName, name), code, password, pbkdfRounds, loginKeyStrength, mainKeyStrength)

    /**
     * Register new user. If is a time-consuming suspend function.
     */
    suspend fun register(
        newUser: ApiUserDetails,
        code: String? = null,
        password: String,
        pbkdfRounds: Int = 50000,
        loginKeyStrength: Int = 4096,
        mainKeyStrength: Int = 4096,
    ): Multiresult<String> {
        requireNotLoggedIn()
        val loginName = newUser.loginName
        return coroutineScope {
            try {
                val publicKeyDeferred = async { AsymmetricKeys.generate(mainKeyStrength) }
                code?.let { slc.call(api.setSecretCode, it) }
                val r = slc.register(loginName, password, newUser, loginKeyStrength, pbkdfRounds)
                when (r) {
                    is Registration.Result.Success -> {
                        debug { "setting up main key" }
                        val key = publicKeyDeferred.await()
                        val packedSr = SignedRecord.pack(
                            key, SetPublicKeyPayload(
                                key.publicKey,
                                Container.encrypt(key, slc.dataKey!!)
                            )
                        )
                        call(api.setMyPublicKey, packedSr)
                        debug { "public key is created" }
                        savedState = SavedState(
                            r.loginToken,
                            r.data<ApiUserDetails>()
                                ?: throw InternalError("сервис не предоставил данные пользователя"),
                            r.dataKey
                        )
                        Multiresult(r.secret)
                    }

                    Registration.Result.InvalidLogin -> Multiresult(ErrorCode.LoginInUse)
                    is Registration.Result.OtherError -> {
                        Multiresult(ErrorCode.valueOf(r.code))
                    }

                    is Registration.Result.NetworkFailure -> Multiresult(ErrorCode.UnknownError)
                }
            } catch (x: NickNotAvailableException) {
                Multiresult(ErrorCode.NickInUse)
            } catch (x: Throwable) {
                x.printStackTrace()
                Multiresult(ErrorCode.UnknownError.apply { cause = x })
            }
        }
    }

    /**
     * Tries to log in (must be logged out). On succcess, returns true and set [userFlow]
     * to the logged-in user details instance.
     *
     * @return true on successful login
     */
    suspend fun login(loginName: String, password: String): Boolean {
        requireNotLoggedIn()
        return slc.loginByPassword(loginName, password)?.also {
            if (it.loginToken == null)
                throw InternalError("сервис не предоставил токен после логина")
            if (it.data == null)
                throw InternalError("сервис не предоставил данные пользователя после логина")
            savedState = SavedState(it.loginToken!!, it.data!!, it.dataKey)
        } != null
    }

    /**
     * Perform log out, If not logged in, does nothing
     */
    suspend fun logout() {
        // clear local state
        if (isLoggedIn) {
            slc.logout()
            // We want state to be collected before leaving, it will
            // drop saved state and clear all necessary:
            savedState = null
            yield()
        } else if (savedState != null) {
            // We are logged out but by some mistake we still have saved state
            debug { "clearing saved state for the forced logout request" }
            savedState = null
            debug { "saved state cleared" }
        }
    }

    suspend fun deleteAccount() {
        slc.call(api.deleteAccount)
        savedState = null
    }

    suspend fun resetPasswordAndLogin(
        secret: String, newPassword: String, pbkdfRounds: Int = 50000,
        loginKeyStrength: Int = 4096,
    ): Boolean {
        requireNotLoggedIn()
        return slc.resetPasswordAndSignIn(
            secret,
            newPassword,
            PasswordDerivationParams(pbkdfRounds),
            loginKeyStrength
        ).also {
            if (it != null) {
                savedState = SavedState(it.loginToken!!, it.data!!, it.dataKey)
            }
            yield()
        } != null
    }

    suspend fun changePassword(
        currentPassword: String, newPassword: String,
        pbkdfRounds: Int = 50000,
        loginKeyStrength: Int = 4096,
    ): Boolean {
        return slc.changePassword(
            currentPassword,
            newPassword,
            PasswordDerivationParams(pbkdfRounds),
            loginKeyStrength
        )
    }

    suspend fun <A, R> call(cmd: CommandDescriptor<A, R>, args: A): R = slc.call(cmd, args)

    suspend fun <R> call(cmd: CommandDescriptor<Unit, R>): R = slc.call(cmd)

    /**
     * Checks login name
     * @return value or null on communications error (meanining state is unknown)
     */
    suspend fun isLoginNameAvailable(name: String): Boolean? {
        return try {
            call(api.isLoginNameAvailable, name)
        } catch (x: Throwable) {
            x.printStackTrace()
            return null
        }
    }

    /**
     * Create a new block with unique per-user, user block utag.
     * Note that user blocks are not the same as the document blocks and utag namespace is different.
     *
     * @return null if such a block already exists.
     */
    suspend fun userBlockCreate(utag: String, data: ByteArray): ApiUserBlock? =
        call(IcwkApi.userCreateBlock, ApiUserBlock(utag, data))

    /**
     * Get a user block for its unique tag. See [userBlockCreate] and [userBlockDelete].
     *
     * @return null if such a block does not exist
     */
    suspend fun userBlockGet(utag: String): ApiUserBlock? =
        call(IcwkApi.userGetBlock, utag)

    /**
     * Update existing user block.
     * Note there is version control: you can update only the latest version of the block, controlled by
     * [ApiUserBlock.serial].
     * If the block is modified on the server, this method will return null,
     * in which case you need to get the latest version using [userBlockGet] and merge or replace
     * its [ApiUserBlock.data], then update.
     *
     * @return updated version: [ApiUserBlock.serial] will be greater, or null if the block was modified
     *      or deleted on the server.
     */
    suspend fun userBlockUpdate(block: ApiUserBlock): ApiUserBlock? =
        call(IcwkApi.userUpdateBlock, block)

    /**
     * Delete user block by tag. Does nothing if the block does not exist.
     */
    suspend fun userBlockDelete(utag: String) {
        call(IcwkApi.userDeleteBlock, utag)
    }

    fun getBlocks(
        docId: Long? = null,
        type: BlockType? = null,
        utag: String? = null,
        tag: String? = null,
        prev: String? = null,
        next: String? = null,
        sinceSerial: Long? = null,
        chunkSize: Int = 50,
        accessHandle: String? = null,
    ): Flow<ApiBlock> {
        if (chunkSize < 1)
            throw IllegalArgumentException("недопустимый размер буфера ($chunkSize), требуется >= 1")
        return flow {
            // TODO: is it a bug? it is not changed?
            val offset = 0
            do {
                val list = call(
                    IcwkApi.docGetBlocks, ApiGetBlocksArgs(
                        docId, type, utag, tag, prev, next, chunkSize, offset,
                        sinceSerial = sinceSerial,
                        accessHandle = accessHandle
                    )
                )
                emitAll(list.asFlow())
            } while (list.size == chunkSize)
        }
    }

//    suspend fun agreements(): Flow<ApiAgreement> {
//
//    }

}
