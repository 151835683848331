package tools

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import net.sergeych.intecowork.tools.Debouncer
import kotlin.time.Duration

/**
 * Composable-aware debouncer.
 * @param pause pause between [Debouncer.schedule] calls after which it executes the block
 * @param maxDelay maximum delay between first [Debouncer.schedule] invocation after which debouncer will
 *                 be executed despite any [Debouncer.schedule] calls.
 * @param executeOnDispose if true (default), when composable will be finally disposed
 *        debouncer will be executed. See [DisposableEffect] to understand composable lifetime
 * @return debouncer instance remembere in the context of the composable.
 */
@Composable
fun rememberDebouncer(
    pause: Duration,
    maxDelay: Duration = pause * 10,
    executeOnDispose: Boolean = true,
    block: suspend () -> Unit,
): Debouncer {
    val scope = rememberCoroutineScope()
    val debouncer = remember { Debouncer(scope, pause, maxDelay, block) }

    DisposableEffect("debouncer") {
        onDispose {
            // and we check our param only there
            if( executeOnDispose ) debouncer.executeNow()
            debouncer.cancel()
        }
    }
    return debouncer
}
