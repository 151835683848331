package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLHeadingElement

@Composable
fun h1(text: String, attrs: AttrBuilderContext<HTMLHeadingElement>? = null) {
    H1(attrs) { Text(text) }
}
@Composable
fun h2(text: String, attrs: AttrBuilderContext<HTMLHeadingElement>? = null) {
    H2(attrs) { Text(text) }
}
@Composable
fun h3(text: String, attrs: AttrBuilderContext<HTMLHeadingElement>? = null) {
    H3(attrs) { Text(text) }
}
@Composable
fun h4(text: String, attrs: AttrBuilderContext<HTMLHeadingElement>? = null) {
    H4(attrs) { Text(text) }
}
@Composable
fun h5(text: String, attrs: AttrBuilderContext<HTMLHeadingElement>? = null) {
    H5(attrs) { Text(text) }
}
