package net.sergeych.intecowork.tools

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.datetime.Instant
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.exception
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.mptools.Now
import kotlin.time.Duration

/**
 * Class to delayed ativate a block after a timeout after a [schedule] was called.
 *
 * - [executeNow] could actually wait up to [pause] time, we need to find a way to gracefully cancel delay
 * 
 * - [schedule] could be called multiple times
 * 
 * @param scope coroutine scope to execute the block with
 * @param block the block to execute
 * @param pause time to wait before executing the block after the last [schedule] call
 * @param maxPause maximum time to wait before executing the block after the __first__ [schedule] call
 */
class Debouncer(private val scope: CoroutineScope,
                val pause: Duration,
                val maxPause: Duration = pause*10,
                private val block: suspend () -> Unit) : LogTag("DEBNCR") {

    private var activateAt: Instant? = null
    private var activateNoLaterThan: Instant? = null
    private val mutex = Mutex()

    fun schedule() {
        globalLaunch {
            mutex.withLock {
                if (activateNoLaterThan == null)
                    activateNoLaterThan = Now() + maxPause
                val nextActivation = Now() + pause
                activateAt = if (nextActivation > activateNoLaterThan!!) activateNoLaterThan else nextActivation
            }
        }
    }

    fun executeNow() {
        scope.launch {
            activateAt = Now()
            executeBlock()
        }
    }

    private var job: Job? = null

    /**
     * Cancels the debouncer. Does not execute even if scheduled. Execute it manually if needed.
     */
    @Suppress("unused")
    fun cancel() {
        job?.cancel()
        job = null
    }

    init {
        job = scope.launch {
            while (true) {
                val left = activateAt?.let { t ->
                    t - Now()
                } ?: pause
//                if (left != pause) debug { "extra pause $left" }
                delay(left)
                val at = activateAt
                if (at != null) {
                    if (at <= Now()) {
                        executeBlock()
                    }
                }
            }
        }
    }

    private suspend fun executeBlock() {
        try {
            block()
        } catch (t: Throwable) {
            exception { "unexpected error in debouncer block" to t }
        }
        mutex.withLock {
            activateAt = null
            activateNoLaterThan = null
        }
    }
}
