package net.sergeych.parsec3

import kotlinx.coroutines.flow.StateFlow

/**
 * Transport level protocol (conforms to parsec 3.0). It is not specially secured could be used as is, as it provides bidirectional
 * asynchronous (push capable) calls, but normally it should be used as a transport for parsec 3.1 secure
 * protocol which uses this transport interface to run.
 */
interface Parsec3Transport<S: WithAdapter> {
    val connectedFlow: StateFlow<Boolean>
    fun close()

    fun reconnect()

    suspend fun adapter(): Adapter<S>

    val exceptionsRegistry: ExceptionsRegistry

    fun registerExceptinos(otherRegistry: ExceptionsRegistry) {
        exceptionsRegistry.putAll(otherRegistry)
    }
}