package net.sergeych.intecowork.api

enum class DocRole(val text: String,
                   val canWrite: Boolean=false,
                   val canComment: Boolean = false,
                   val canRead: Boolean=true,
    ) {
    Owner("владелец",true,true, true),
    Writer("редактор",true, true, true),
    Commenter("комментатор", false, true, true),
    Reader("читатель", false, false, true),
    Inaccessible("нет доступа", false, false, false),
    Anonymous("анонимный читатель");

    val isOwner: Boolean get() = this == Owner

    val isInaccessible: Boolean get() = this == Inaccessible

    override fun toString() = text

    companion object {
        val collaborators = setOf(
            Writer, Reader, Commenter
        )
    }
}