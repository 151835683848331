package net.sergeych.intecowork.api

import net.sergeych.parsec3.ExceptionsRegistry

enum class ErrorCode(val text: String) {
    LoginInUse("логин недоступен"),
    NickInUse("ник недоступен"),
    CodeIsRequired("требуется скретч-код"),
    CodeIsInvalid("этот скретч-код неприменим для регистрации"),
    UnknownError("ошибка, пожалуйста, повторите попытку позже");

    var cause: Throwable? = null
    override fun toString(): String = text
}


open class IcwkException(message: String, cause: Throwable? = null) : Exception(message, cause)

class StorageLimitException(text: String? = null) : IcwkException(text ?: "лимит на хранение исчерпан")

class NotFoundException(text: String? = null) : IcwkException(text ?: "объект не найден")

class NickNotAvailableException(text: String?) : IcwkException(text ?: "ник недоступен")
class InvalidServiceCodeException(text: String?) : IcwkException(text ?: "invalid service code")

class InternalError(text: String?, cause: Throwable? = null) : IcwkException(text ?: "внутренняя ошибка", cause)

class NotAuthenticatedException(text: String? = null) : IcwkException(text ?: "требуется аутентификация")

class PasswordRequiredException() : IllegalArgumentException("требуется пароль")

class BadIcwkException(t: String? = null) : IcwkException(t ?: "сбойная структура документа")

class AccessForbiddenException(t: String? = null) :
    IcwkException(t ?: "отказано в доступе")

class InvalidUserNameException(reason: String? = null) :
    IllegalArgumentException(reason ?: "недопустимое имя пользователя")

class ErrorsException(vararg code: ErrorCode) : IcwkException(code.joinToString(", ") { it.text }) {
    val codes: Set<ErrorCode> = code.toSet()
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ErrorsException

        if (codes != other.codes) return false

        return true
    }

    override fun toString(): String {
        return codes.joinToString(", ") { it.text }
    }

    override fun hashCode(): Int {
        return codes.hashCode()
    }
}

class CodeIsRequiredException: IllegalArgumentException("code is required for authentication")

class ModificationFailedException(val result: ApiDocUpdateResult) :
    IcwkException("не удлаось изменить документ: $result")

val icwkExceptions = ExceptionsRegistry().apply {
    register { IcwkException(it ?: "неспецифицированная ошибка") }
    register { StorageLimitException(it) }
    register { NotFoundException(it) }
    register { NickNotAvailableException(it) }
    register { ErrorsException() }
    register { InternalError(it) }
    register { NotAuthenticatedException(it) }
    register { PasswordRequiredException() }
    register { BadIcwkException(it) }
    register { AccessForbiddenException(it) }
    register { InvalidUserNameException(it) }
    register { InvalidServiceCodeException(it) }
    register { CodeIsRequiredException() }
}



