package editor

import document.*
import editor.operations.*
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.warning

fun Chain<Block>.insertBlockAndClean(block: Block, afterBlockGuid: String, c: Caret? = null) {
    val oldCaret = c ?: caret
    val cleaned = cleanBlock(block, oldCaret)

    caret = cleaned.caret
    insert(cleaned.block, afterBlockGuid)
}

fun Chain<Block>.updateBlockAndClean(block: Block, c: Caret? = null) {
    val currentCaret = c ?: caret
    val cleaned = cleanBlock(block, currentCaret)
//    console.log("CARET AFTER CLEAN", currentCaret?.toFullString(), cleaned.caret?.toFullString())
    caret = cleaned.caret
    update(cleaned.block)
}

open class PartialChain<T: L2Element<T>>(
    orderedElements: List<T>,
    open val initialCaret: Caret? = null,
    open val name: String = "default chain",
    open val debug: Boolean = false
): LogTag("[CHAIN]") {
    val initialElements = fixLinks(orderedElements)
    var elements = initialElements
    var inserted = emptySet<String>()
    var updated = emptySet<String>()
    var deleted = emptySet<String>()

    val nextGuid get() = elements.last().nextGuid
    val prevGuid get() = elements.first().prevGuid
    var caret = initialCaret

    open fun fixLinks(els: List<T>): List<T> {
        return Companion.fixLinks(els, true)
    }

    fun log(operationName: String): (String) -> Unit {
        return { if (debug) console.log("=================== [$name] [$operationName]: $it") }
    }

    fun isChanged(): Boolean {
        return inserted.isNotEmpty() || updated.isNotEmpty() || deleted.isNotEmpty()
    }

    fun indexOf(guid: String): Int {
        return elements.indexOfFirst { it.guid == guid }
    }

    fun getInserted(): List<T> {
        return elements.filter { inserted.contains(it.guid) }
    }

    fun getInsertedChains(): List<PartialChain<T>> {
        return getChains(getInserted())
    }

    fun getDeleted(): List<T> {
        return initialElements.filter { deleted.contains(it.guid) }
    }

    fun getDeletedChains(): List<PartialChain<T>> {
        return getChains(getDeleted())
    }

    fun getUpdated(): List<T> {
        return elements.filter { updated.contains(it.guid) }
    }

    fun isOrphan(): Boolean {
        if (elements.size != 1) return false
        val onlyBlock = elements.first()
        return onlyBlock.prevGuid == null && onlyBlock.nextGuid == null
    }

    open fun insert(el: T, afterGuid: String) {
        insert(el, afterGuid, true)
    }

    fun insert(el: T, afterGuid: String, isPartial: Boolean) {
        if (elements.find { it.guid == el.guid } != null)
            throw BugException("CHAIN: trying to insert already existing element=${el.guid} after=${afterGuid}")

        val copy = mutableListOf<T>()
        var i = 0

        while(i < elements.size && elements[i].guid != afterGuid) {
            copy.add(elements[i])
            i += 1
        }

        val left = elements[i]
        val right = if (left.nextGuid != null && i < elements.size - 1) elements[i + 1] else null

        copy.add(left.copyListElement(next = el.guid) as T)
        i += 1

        val nextId = if (isPartial) el.nextGuid else right?.guid
        copy.add(el.copyListElement(prev = left.guid, next = nextId))

        right?.let {
            copy.add(right.copyListElement(prev = el.guid))
            i += 1
        }

        while(i < elements.size) {
            copy.add(elements[i])
            i += 1
        }

        elements = copy

        markInserted(el.guid)
        markUpdated(left.guid, left)

        right?.let { markUpdated(it.guid, it) }
    }

    fun markInserted(guid: String) {
        if (deleted.contains(guid)) deleted -= guid
        else inserted += guid
    }

    fun markUpdated(guid: String, updatedElement: T) {
        if (!inserted.contains(guid) && !deleted.contains(guid)) {
            val existing = getInitial(guid) ?: throw BugException("trying to update non-existing element in chain")
            val isEquals = existing.prevGuid == updatedElement.prevGuid &&
                    existing.nextGuid == updatedElement.nextGuid &&
                    existing.contentEquals(updatedElement)

            if (isEquals) updated -= guid
            else updated += guid
        }
    }

    fun markDeleted(guid: String) {
        updated -= guid
        if (inserted.contains(guid)) inserted -= guid
        else deleted += guid
    }

    fun update(el: T) { update(el, false) }

    fun updateAndSetLinks(el: T) { update(el, true) }

    private fun update(el: T, updateLinks: Boolean) {
        if (elements.find { it.guid == el.guid } == null)
            throw BugException("Chain: trying to update non-existed element guid=${el.guid}")

        elements = elements.map {
            if (it.guid == el.guid) {
                val actual = if (updateLinks) el else el.copyListElement(prev = it.prevGuid, next = it.nextGuid)
                markUpdated(el.guid, actual)
                actual
            } else it
        }
    }

//    fun delete(guid: String) {
//        delete(guid, true)
//    }

    fun delete(guid: String) {
        val block = get(guid) ?: return
        // can be, when merge table vectors
//        if (elements.size == 1) throw BugException("Can't delete the only element chain")
        val nextId = block.nextGuid
        val prevId = block.prevGuid

        val nextBlock = if (nextId != null) get(nextId) else null
        val prevBlock = if (prevId != null) get(prevId) else null

        if (prevBlock != null) {
            if (nextBlock != null) {
                update(prevBlock.copyListElement(next = nextBlock.guid), true)
                update(nextBlock.copyListElement(prev = prevBlock.guid), true)
            }
            else {
                update(prevBlock.copyListElement(next = block.nextGuid), true)
            }
        } else {
            if (nextBlock != null) {
                update(nextBlock.copyListElement(prev = block.prevGuid), true)
            }
            else {
//                printCase("delete_${guid}")
//                throw BugException("Can't delete orphan")
            }
        }

        markDeleted(block.guid)

        elements = elements.filter { it.guid != block.guid }
    }

    fun get(guid: String): T? { return elements.find { it.guid == guid } }
    fun getInitial(guid: String): T? { return initialElements.find { it.guid == guid } }

    fun getIn(fromId: String?, toId: String?): List<T> {
        val fromIndex = elements.indexOfFirst { it.prevGuid == fromId }
        val toIndex = elements.indexOfFirst { it.nextGuid == toId }

        return elements.slice(fromIndex..toIndex)
    }

    open fun insertChain(chain: PartialChain<T>, afterBlockGuid: String) {
        insertChain(chain, afterBlockGuid, true)
    }

    fun insertChain(chain: PartialChain<T>, afterBlockGuid: String, isPartial: Boolean) {
        if (chain.isOrphan()) throw Exception("Chain ${name} insert err: Can't insert orphan")
        chain.elements.forEach { e ->
            if (elements.find { it.guid == e.guid } != null)
                throw BugException("Chain ${name} insert err: element guid=${e.guid} already present in chain")
        }

        val prevIndex = elements.indexOfFirst { it.guid == afterBlockGuid }
        if (prevIndex == -1) throw BugException("Insert chain ${name}: can't find afterBlock")

        val afterBlock = elements[prevIndex]
        update(afterBlock.copyListElement(next = chain.first().guid), true)

        val insertElements = chain.elements.toMutableList()
        val insertTotal = insertElements.size
        val insertLast = insertElements[insertTotal - 1]
        insertElements[0] = insertElements[0].copyListElement(prev = afterBlockGuid)

        if (prevIndex != elements.size - 1) {
            val nextBlock = elements[prevIndex + 1]
            update(nextBlock.copyListElement(prev = chain.last().guid), true)
            insertElements[insertTotal - 1 ] = insertLast.copyListElement(next = nextBlock.guid)

            val blocksBefore = elements.slice(0..prevIndex)
            val blocksAfter = elements.slice(prevIndex + 1 until elements.size)
            elements = blocksBefore + insertElements + blocksAfter
            insertElements.forEach { markInserted(it.guid) }
        } else {
            if (!isPartial)
                insertElements[insertTotal - 1] = insertLast.copyListElement(next = null)
            val blocksBefore = elements.slice(0..prevIndex)
            elements = blocksBefore + insertElements
            insertElements.forEach { markInserted(it.guid) }
        }
    }

    fun last(): T { return elements.last() }

    fun first(): T { return elements.first() }

    fun canJoinLeft(chain: Chain<T>): Boolean {
        return chain.nextGuid == elements.first().guid
    }

    fun canJoinRight(chain: Chain<T>): Boolean {
        return chain.prevGuid == elements.last().guid
    }

    fun join(chain: Chain<T>): Chain<T> {
        if (canJoinLeft(chain)) return Chain(chain.elements + elements)
        else if (canJoinRight(chain)) return Chain(elements + chain.elements)
        else throw Exception("chains cannot be joined")
    }

    fun containerIndex(c: Caret): Int? {
        val guid = c.path.find { guid -> elements.find { it.guid == guid } != null }

        return guid?.let { indexOf(it) }
    }

    fun <T> ensureCaret(c: Caret? = caret, f: (Caret) -> T): T {
        if (c == null) throw Exception("caret required")

        return f(c)
    }

    fun caretBlock(c: Caret?): T {
        return ensureCaret(c) {
            get(it.blockId) ?: throw BugException("Block (${it.blockId}) not in chain")
        }
    }

    fun next(from: T?): T? {
        return from?.let { it.nextGuid?.let { get(it) } }
    }

    fun prev(from: T?): T? {
        return from?.let { it.prevGuid?.let { get(it) } }
    }

    fun check() {
        fun checkReferences(a: T, b: T) {
            val isOk = a.nextGuid == b.guid && b.prevGuid == a.guid
            if (!isOk)
                throw BugException("[CHAIN]: broken refs ${a.guid} -> [${a.nextGuid}] but [${b.prevGuid}] <- ${b.guid}")
        }

        if (elements.size == 1) return
        if (elements.size == 2) {
            return checkReferences(elements.first(), elements.last())
        }

        var i = 1

        while (i < elements.size) {
            checkReferences(elements[i - 1], elements[i])
            i++
        }
    }

    open fun isLinked(): Boolean {
        return isLinked(true)
    }

    fun isLinked(isPartial: Boolean): Boolean {
        var isLinked = true

        fun checkReferences(a: T, b: T): Boolean {
            val isOk = a.nextGuid == b.guid && b.prevGuid == a.guid

            if (!isOk) warning { "[CHAIN]: broken refs ${a.guid} -> [${a.nextGuid}] but [${b.prevGuid}] <- ${b.guid}" }

            return isOk
        }

        fun checkEnds(): Boolean {
            if (isPartial) return true

            val isOk = prevGuid == null && nextGuid == null
            if (!isOk) warning { "[CHAIN]: broken ends prevGuid = ${prevGuid}, nextGuid = ${nextGuid}" }

            return isOk
        }

        if (elements.size > 1) {
            var i = 1

            while (i < elements.size && isLinked) {
                isLinked = isLinked && checkReferences(elements[i - 1], elements[i])
                i++
            }
        }

        return isLinked && checkEnds()

        return isLinked
    }

    fun printShort() {
        val blocks = elements.map { it.guid }.joinToString(" ")
        console.log("${name}: $prevGuid <- $blocks -> $nextGuid")
    }

    open fun printCase(type: String) {
        val initial = initialElements.map {
            val elString = "DummyL2(prevGuid=\"${it.prevGuid}\", guid=\"${it.guid}\", nextGuid=\"${it.nextGuid}\")"
            elString
        }.joinToString(",\n            ")

        val els = elements.map {
            val elString = "DummyL2(prevGuid=\"${it.prevGuid}\", guid=\"${it.guid}\", nextGuid=\"${it.nextGuid}\")"
            elString
        }.joinToString(",\n            ")

        val result = """
        val initial = listOf(
            ${initial}
        )
        val ${type} = Chain(listOf(
            ${els}
        ))
        """

        console.log(result)
    }

    fun contentEquals(other: PartialChain<T>): Boolean {
        return this.elements == other.elements
    }

    companion object {
        fun <T: L2Element<T>> fixLinks(elementsOrdered: List<T>, isPartial: Boolean): List<T> {
            if (elementsOrdered.size == 1) return elementsOrdered

            var fixed = mutableListOf<T>()
            var i = 0

            while (i < elementsOrdered.size) {
                val el = elementsOrdered[i]

                val prev = if (i == 0) {
                    if (isPartial) el.prevGuid else null
                } else elementsOrdered[i - 1].guid

                val next = if (i == elementsOrdered.size - 1) {
                    if (isPartial) el.nextGuid else null
                } else elementsOrdered[i + 1].guid

                fixed.add(el.copyListElement(prev = prev, next = next))
                i++
            }

            return fixed.toList()
        }

        fun <T: L2Element<T>> getChains(blocks: List<T>): List<PartialChain<T>> {
            var previous = mutableMapOf<String?, List<T>>()

            blocks.forEach {
                previous[it.prevGuid] = listOf(it)
            }

            var isFinished = false
            while (!isFinished) {
                isFinished = true
                val add = mutableMapOf<String?, List<T>>()
                val remove = mutableSetOf<String?>()
                val processed = mutableSetOf<String?>()

                previous.forEach { entryA ->
                    if (!processed.contains(entryA.key)) previous.forEach { entryB ->
                        if (entryA.value.last().guid == entryB.key && !processed.contains(entryB.key)) {
                            isFinished = false
                            processed.add(entryA.key)
                            processed.add(entryB.key)
                            add[entryA.key] = entryA.value + entryB.value
                            remove.add(entryB.key)
                        }
                    }
                }

                add.forEach { previous[it.key] = it.value }
                remove.forEach { previous.remove(it) }
            }

            val chains = mutableListOf<PartialChain<T>>()

            previous.forEach { chains.add(PartialChain(it.value)) }

            return chains.toList()
        }
    }
}

open class Chain<T: L2Element<T>>(
    orderedElements: List<T>,
    initialCaret: Caret? = null,
    name: String = "default chain",
    debug: Boolean = false
): PartialChain<T>(orderedElements, initialCaret, name, debug) {
    fun isEmpty(): Boolean {
        return elements.isEmpty() && initialCaret == null
    }

    override fun fixLinks(els: List<T>): List<T> {
        return Companion.fixLinks(els, false)
    }

    override fun isLinked(): Boolean {
        return isLinked(false)
    }

    override fun insert(el: T, afterGuid: String) {
        insert(el, afterGuid, false)
    }

    override fun insertChain(chain: PartialChain<T>, afterGuid: String) {
        insertChain(chain, afterGuid, false)
    }
}

class DocChain(
    orderedElements: List<Block>,
    initialCaret: Caret? = null,
    name: String = "default chain",
    debug: Boolean = false
): Chain<Block>(orderedElements, initialCaret, name, debug), CaretSelector<Block> {

    fun caretOffset(): Int {
        val c = caret ?: throw BugException("no caret in chain")

        var offset = 0
        var block = elements.first()

        while (block.guid != c.blockId) {
            offset += block.plainText.length
            block = elements.find { it.guid == block.nextGuid } ?: throw BugException("broken chain")
        }

        offset += block.getOffset(c)

        return offset
    }

    fun copy(copyName: String? = null): DocChain {
//        console.log("copy chain", caret?.toFullString())
        val transaction = getAUDTransaction()
        val chain = DocChain(initialElements, initialCaret, copyName ?: "$name copy", debug)
        chain.applyAUDTransaction(transaction)
//        console.log("copied", chain.caret?.toFullString())
        return chain
    }

    override fun printCase(type: String) {
        val initial = initialElements.map {
            val elString = "DummyL2(prevGuid=\"${it.prevGuid}\", guid=\"${it.guid}\", nextGuid=\"${it.nextGuid}\", content=\"${it.plainText}\")"
            elString
        }.joinToString(",\n            ")

        val els = elements.map {
            val elString = "DummyL2(prevGuid=\"${it.prevGuid}\", guid=\"${it.guid}\", nextGuid=\"${it.nextGuid}\", content=\"${it.plainText}\")"
            elString
        }.joinToString(",\n            ")

        val result = """
        val initial = listOf(
            ${initial}
        )
        val ${type} = Chain(listOf(
            ${els}
        ))
        """

        console.log(result)
    }

    fun copyInitial(): DocChain {
        return DocChain(initialElements, initialCaret, name, debug)
    }

    fun diff(newerChain: DocChain): AUDTransaction<Block> {
        val deleted = elements.filter { !newerChain.elements.map { it.guid }.contains(it.guid) }
        val updated = newerChain.elements.filter { u ->
            val sibling = elements.find { it.guid == u.guid }
            sibling != null && sibling != u
        }
        val added = newerChain.elements.filter { !elements.map { it.guid }.contains(it.guid) }

        return AUDTransaction(null, newerChain.caret, added, updated, deleted)
    }

    fun nextBlockFocusable(from: Block?): Block? {
        var b = next(from)
        while(b != null && b.isFocusable == false) b = next(b)

        return b
    }

    fun prevBlockFocusable(from: Block?): Block? {
        var b = prev(from)
        while(b != null && b.isFocusable == false) b = prev(b)

        return b
    }

    override fun caretLeftBlockWide(c: Caret?): Caret? {
        return ensureCaret(c) { caretBlock(c).caretLeft(it) }
    }

    override fun caretRightBlockWide(c: Caret?): Caret? {
        return ensureCaret(c) { caretBlock(c).caretRight(it) }
    }

    override fun caretLeft(c: Caret?): Caret? {
        return ensureCaret(c) { caretLeftBlockWide(it) ?: caretAtPrevBlock(c) }
    }

    override fun caretRight(c: Caret?): Caret? {
        return ensureCaret(c) { caretRightBlockWide(it) ?: caretAtNextBlock(c) }
    }

    fun caretAtNextBlock(c: Caret?): Caret? {
        return ensureCaret(c) {
            val nextBlock = nextBlockFocusable(caretBlock(c))

            nextBlock?.caretAtStart()
        }
    }

    fun caretAtPrevBlock(c: Caret?): Caret? {
        return ensureCaret(c) {
            val prevBlock = prevBlockFocusable(caretBlock(c))

            prevBlock?.caretAtEnd()
        }
    }

    override fun caretLeftCellWide(c: Caret?): Caret? {
        return ensureCaret(c) {
            val left = caretBlock(c).caretLeft(it)
            if (left?.cellId != c?.cellId) null
            else left
        }
    }

    override fun caretRightCellWide(c: Caret?): Caret? {
        return ensureCaret(c) {
            val right = caretBlock(c).caretRight(it)
            if (right?.cellId != c?.cellId) null
            else right
        }
    }

    override fun indexOfBlock(guid: String): Int {
        return elements.indexOfFirst { it.guid == guid }
    }

    fun caretSpan(c: Caret): Fragment.StyledSpan {
        val block = caretBlock(c)

        return block.find(c.spanId) as? Fragment.StyledSpan ?:
        throw BugException("StyledSpan (${c.spanId}) not in block (${block.guid}")
    }

    companion object {
        fun empty(): DocChain {
            return DocChain(emptyList(), null)
        }
    }
}