package views

import androidx.compose.runtime.Composable
import controls.Di
import controls.Variant
import net.sergeych.parsec3.stored
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import settingsStore
import tools.signedCrc32

/**
 * Shows the help panel until the user closes it with a button. Next time the panel is shown
 * only if the text changes.
 */
@Composable
fun ShowHelpOnce(
    id: String,
    text: String,
    variant: Variant = Variant.Warning,
) {
    ShowHelpOnce(id, signedCrc32(text.trim()), variant) {
        var isFirst = true
        for( t in text.trim().split("\n\n")) {
            if( t.isNotBlank()) {
                Div({
                    if (!isFirst) classes("mt-2")
                    isFirst = false
                    classes("small")
                }) {
                    +t
                }
            }
        }
    }
}


@Composable
fun ShowHelpOnce(
    id: String, version: Int,
    variant: Variant,
    builder: ContentBuilder<HTMLDivElement>
) {
    var shownVersion by settingsStore.stored(-1, "__showOnceHelp:$id")

    if (version != shownVersion) {
        Di("alert ${variant.alertClass} alert-dismissible fade show") {
            builder()
            Button({
                classes("btn-close","btn-close-sm")
                attr("data-bs-dismiss", "alert")
                onClick { shownVersion = version }
            }){}
        }
    }
}