package net.sergeych.parsec3

import kotlinx.coroutines.flow.Flow
import net.sergeych.mp_tools.globalLaunch

open class WithAdapter {
    internal var _adapter: Adapter<*>? = null

    val adapter: Adapter<*> get() = _adapter ?: throw IllegalStateException("adapter is not yet initialized")
}

@Suppress("UNCHECKED_CAST")
class AdapterBuilder<S : WithAdapter, H : CommandHost<S>>(
    val api: H,
    val exceptionRegistry: ExceptionsRegistry = ExceptionsRegistry(),
    f: AdapterBuilder<S, H>.() -> Unit,
) {

    internal var sessionProducer: (suspend () -> S) = { WithAdapter() as S }
        private set


    fun newSession(f: suspend () -> S) {
        sessionProducer = f
    }

    /**
     * Register command implementation.
     * @param ca command to implement
     * @param overwrite allow replacing existing command implementation with a new block
     * @param block command handler that implements the command
     */
    fun <A, R> on(ca: CommandDescriptor<A, R>,overwrite: Boolean=false, block: suspend S.(A) -> R) {
        api.on(ca, overwrite, block)
    }

    val onCancelHandlers = mutableListOf<suspend () -> Unit>()


    /**
     * Set a onCancel handler that will be called upon adapter cancelling. Several adapters
     * could be registered and will be called in order of registration
     */
    fun onCancel(f: suspend () -> Unit) {
        onCancelHandlers += f
    }

    @Suppress("unused")
    inline fun <reified T : Throwable> addError(code: String, noinline handler: (String?) -> T) {
        exceptionRegistry.register(code, handler)
    }

    @Suppress("unused")
    fun addErrors(otherRegistry: ExceptionsRegistry) {
        exceptionRegistry.putAll(otherRegistry)
    }

    suspend fun createWith(input: Flow<ByteArray>, f: suspend (ByteArray) -> Unit): Adapter<S> {
        val s = sessionProducer()
        return Adapter<S>(
            s, api, exceptionRegistry
        ) { f(it) }
            .also { a ->
                s._adapter = a
                if (onCancelHandlers.isNotEmpty()) {
                    a.onCancel = {
                        for (h in onCancelHandlers) try {
                            h()
                        } catch (t: Throwable) {
                            t.printStackTrace()
                        }
                    }
                }
                globalLaunch { input.collect { a.receiveFrame(it) } }
            }
    }

    suspend fun create(f: suspend (ByteArray) -> Unit): Adapter<S> {
        val s = sessionProducer()
        return Adapter(s, api, exceptionRegistry, f).also { s._adapter = it }
    }

    init {
        f(this)
    }

}