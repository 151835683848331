package net.sergeych.parsec3

import kotlin.reflect.KType

class CommandDescriptor<A, R>(
    val name: String,
    val ass: KType,
    val rss: KType,
) {
    suspend operator fun invoke(adapter: Adapter<*>, args: A): R =
        adapter.invokeCommand(this, args)

    @Suppress("UNCHECKED_CAST")
    suspend operator fun invoke(adapter: Adapter<*>): R = adapter.invokeCommand(this,Unit as A)

    operator fun <I: WithAdapter>invoke(commandHost: CommandHost<I>, overwrite: Boolean = false,block: suspend I.(A)->R) {
        commandHost.on(this, overwrite, block)
    }
}

