package net.sergeych.parsec3

/**
 * The base class for exceptions that pass through parsec3 channel. Application exceptinos
 * intended to be transmitted through this channel should inherit from it and be registered
 * with proper [ExceptionsRegistry] instance provided to the exceptions-receiving adaper (better
 * to both sides the same).
 */
open class ParsecException(val code: String, val text: String?=null, reason: Throwable?=null) : Exception(
    text?.let { "($code): $text" } ?: code, reason
)

class CommandNotFoundException(name: String): ParsecException(ExceptionsRegistry.commandNotFoundCode,name)

class InvalidFrameException(text: String): ParsecException(ExceptionsRegistry.invalidFrameCode, text)

class UnknownCodeException(code: String,message: String?): ParsecException(code, message)

class UnknownException(message: String?): ParsecException(ExceptionsRegistry.unknownErrorCode, message)

/**
 * This exception is server-side, not intended to arise while processing commands, so it is not
 * a parsec exception. It means some adapter is trying to redefine existing command, which is
 * generally a fault.
 */
class DuplicateCommandDefinition(message: String) : RuntimeException(message)
