package net.sergeych.intecowork.api

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable


@Serializable
data class ApiCodeActivation(
    val id: Long,
    val title: String,
    val createdAt: Instant,
    val description: String,
    val validUntil: Instant
)

@Serializable
sealed class ApiCodeActivationResult {
    @Serializable
    object InvalidCode: ApiCodeActivationResult()

    @Serializable
    class AlreadyActivated(val activation: ApiCodeActivation): ApiCodeActivationResult()

    @Serializable
    class Success(val activation: ApiCodeActivation): ApiCodeActivationResult()

    @Serializable
    class TryAgainLater(val after: Instant): ApiCodeActivationResult()
}
