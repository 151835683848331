package editor

import androidx.compose.runtime.Composable
import document.*
import editor.views.CaretCSS
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.hasClass
import net.sergeych.intecowork.doc.DocBlock
import net.sergeych.unikrypto.toByteArray
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.ElementBuilder
import org.jetbrains.compose.web.dom.TagElement
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine
import kotlin.math.abs
import kotlin.math.log
import kotlin.math.min
import kotlin.math.round

val invisibleNBSP = '\uFEFF'
val ellipsis = '…'

enum class Platform {
    Windows, Mac, iPhone, Android, Linux, UNKNOWN
};

val platform: Platform = run {
    val px = window.navigator.platform
    when {
        px.startsWith("Mac") -> Platform.Mac
        px.startsWith("Win") -> Platform.Windows
        px.startsWith("Linux") -> Platform.Linux
        px.startsWith("iPhone") -> Platform.iPhone
        px.startsWith("Andro") -> Platform.Android
        else -> Platform.UNKNOWN
    }
}

class PackageLogger(val packageName: String, val packageNameColor: String, val isOn: Boolean) {
    fun logColored(msg: String, msgColor: String) {
        if (isOn)
            console.log("%c ${packageName.uppercase()} %c: %s", "background: $packageNameColor; color: white; font-weight: bold", "color: ${msgColor}", msg)
    }

    fun error(err: Throwable) {
        if (isOn) console.log("%c ${packageName.uppercase()} : ", "background: $packageNameColor; color: white; font-weight: bold", err)
    }

    fun error(msg: String) {
        logColored(msg, "red")
    }

    fun success(msg: String) {
        logColored(msg, "green")
    }

    fun log(msg: String) {
        logColored(msg, "white")
    }
}

class AlgoLogger(val pLogger: PackageLogger, val prefix: String, val prefixColor: String = "white") {
    fun logColored(msg: String, msgColor: String) {
        if (pLogger.isOn)
            console.log("%c ${pLogger.packageName.uppercase()} %c - $prefix: %c%s", "background: ${pLogger.packageNameColor}; color: white; font-weight: bold", "font-weight: bold; color: ${prefixColor}", "color: ${msgColor}", msg)
    }

    fun error(err: Throwable) {
        if (pLogger.isOn)
            console.log("%c ${pLogger.packageName.uppercase()} %c - $prefix: ", "background: ${pLogger.packageNameColor}; color: white; font-weight: bold", "font-weight: bold; color: ${prefixColor}", err)
    }

    fun error(msg: String) {
        logColored(msg, "red")
    }

    fun success(msg: String) {
        logColored(msg, "green")
    }

    fun log(msg: String) {
        logColored(msg, "white")
    }
}

fun Block.debugSimplified(): String {
    return "$prevBlockGuid <- $guid -> $nextBlockGuid : ${plainText.substring(0 until plainText.length) }"
}

fun DocBlock.debugSimplified(): String {
    return "$prevGuid <- $guid -> $nextGuid"
}

val metaKeyName: String = when(platform) {
    Platform.Mac -> "⌘"
    Platform.Windows -> "Win"
    else -> "Meta" // most often they use win kbd
}

val winCtrlOrMetaName: String = when(platform) {
    Platform.Mac -> "⌘"
    Platform.Windows -> "Ctrl"
    else -> "Meta"
}

val backspaceKeyName: String = when(platform) {
    Platform.Mac -> "⌫"
    else -> "Backspace"
}

val altKeyName: String = when(platform) {
    Platform.Mac -> "⌥"
    Platform.Windows -> "Alt"
    else -> "Alt"
}


fun String.truncateEnd(maxLength: Int): String =
    if (length < maxLength) this
    else substring(0, maxLength - 1) + ellipsis

/**
 * Get the body origin point, useful to normalize client rects for screen coords
 */
fun bodyOrigin() = window.document.body?.getBoundingClientRect()?.let { Point(it.left, it.top) }
    ?: Point.ZERO


fun findBlockAndFragmentDOM(doc: Doc, element: HTMLElement): Pair<Block, Fragment> {
    if (element.getAttribute("data-ftype") == null) {
        console.log("wrong element", element)
        throw Exception("element is not a fragment (no data-ftype)")
    }
    val id = element.id
    if (id.isNullOrBlank() || id.length < 5)
        throw Exception("element hs an invalid id: $id")
    var e = element.parentElement
    while (e != null) {
        if (e.getAttribute("data-ftype") == "block") {
            val blockId = e.getAttribute("data-blockid") ?: throw Exception("block element has no data-blokcid")
            val block = doc[blockId]
            val fragment = block.paragraph.find(id)
                ?: throw Exception("structure error: parent block can't find a fragment with id=$id")
            return block to fragment
        }
        e = e.parentElement
    }
    throw Exception("parent block does not exist")
}

fun IParagraph.box(): Box = Box(elemRect(this as Fragment))
fun Fragment.box(): Box = Box(elemRect(this))
fun Block.box(): Box = paragraph.box()

internal fun elemRect(f: Fragment): DOMRect {
    val element = window.document.getElementById(f.guid)
        ?.getElementsByClassName("blinking-cursor")?.get(0)
    if (element == null)
        throw Exception("failed to find element for fragment id: ${f.guid}")
    val elemRect = element.getBoundingClientRect()
    return elemRect
}


//fun findNEarestTextOffset(elem: HTMLElement,)

/**
 * This function does not cares about caret position and many other factors, so
 * we will elimitate it soon.
 */
fun textOffset(elem: Node, p: Point, start: Int, stop: Int): Int? {
    var range = window.document.createRange()
    range.setStart(elem, start)
    range.setEnd(elem, stop)
    // not getBoundingClientRect as word could wrap
    return if (pointInRects(p, range.getClientRects()) == null) {
        null
    } else findStep(elem, p, start, stop, null)
}

fun isCaretNode(node: Node?): Boolean {
    return node is HTMLElement && node.hasClass(CaretCSS)
}

data class NodePosition(
    val offset: Int,
    val dX: Double,
    val dY: Double
)

data class ClientRectReference(
    val offsetStart: Int,
    val offsetEnd: Int,
    val box: Box
) {
    fun centerYDistance(p: Point): Double {
        val yCenter = box.top/2 + box.bottom/2
        return abs(yCenter - p.y)
    }
}

fun StyledSpanClosestOffset(span: Fragment.StyledSpan, p: Point): Int? {
    if (span.text.length == 0) return 0
    val spanElement = document.getElementById(span.guid) ?: return null

//    console.log("StyledSpanClosestOffset: got span element")
    var node1 = spanElement.childNodes[0]
    var node2: Node? = null
    if (isCaretNode(node1)) node1 = spanElement.childNodes[1]
    else {
        node2 = spanElement.childNodes[1]
        if (isCaretNode(node2)) node2 = spanElement.childNodes[2]
    }

    if (node1 == null) {
//        warning { "expected text content element is null" }
        return null
    }
    if (node1.nodeName != "#text") {
//        warning { "findSpanAndCharacter: element that is to be span has wrong first node: ${node1?.nodeName}: ${spanElement.outerHTML}" }
        return null
    }
    val s = node1.textContent
    if (s == null) {
//        warning { "findSpanAndCharacter: text content of an element is null" }
        return null
    }

    fun getRects(node: Node, start: Int, end: Int): List<DOMRect> {
        var range = window.document.createRange()
        range.setStart(node, start)
        range.setEnd(node, end)
        return range.getClientRects().toList()
    }

    fun getClosestPosition(node: Node?): NodePosition? {
        if (node == null) return null

        val references = mutableMapOf<String, ClientRectReference>()

        val text = node.textContent
        if (text == null) return null

        val end = text.length
        var offset = 0

        while(offset < end) {
            var rects = getRects(node, offset, offset+1)
            rects.forEachIndexed { index, domRect ->
                references["${offset}-${offset+1}-${index}"] = ClientRectReference(offset, offset+1, Box(domRect))
            }
            offset += 1
        }

        val refs = references.values

        val closestYRef = refs.minBy { it.centerYDistance(p) }
        val closestYRefs = refs.filter { it.centerYDistance(p) == closestYRef.centerYDistance(p) }

        val closestXRef = closestYRefs.minBy {
            min(abs(p.x - it.box.left), abs(p.x - it.box.right))
        }

        val distanceRight = abs(p.x - closestXRef.box.right)
        val distanceLeft = abs(p.x - closestXRef.box.left)

        val distanceY = closestXRef.centerYDistance(p)
//        console.log("DISTANCE: ${closestXRef.box.top} : ${closestXRef.box.bottom} : ${p.y} = ${distanceY}")

        if (distanceLeft < distanceRight) return NodePosition(closestXRef.offsetStart, distanceLeft, distanceY)
        return NodePosition(closestXRef.offsetEnd, distanceRight, distanceY)
    }

    val position1 = getClosestPosition(node1)
    val position2 = getClosestPosition(node2)

    if (position1 == null) return null
    if (position2 == null) {
        return position1.offset
    } else {
        if (position2.dY < position1.dY) return (node1.textContent?.length ?: 0) + position2.offset - 2
        else if (position2.dY > position1.dY) return position1.offset
        else {
            if (position2.dX > position1.dX) return position1.offset
            else return (node1.textContent?.length ?: 0) + position2.offset - 2
        }
    }
}

fun findClosestTextOffset(elem: Node, p: Point, onlyIn: Box? = null): Int {
    val text = elem.textContent ?: throw IllegalArgumentException("node must have text content")
    return findStep(elem, p, 0, text.length + 1, onlyIn)
}


private fun getRectsBySubstring(elem: Node, start: Int, stop: Int): Array<DOMRect> {
    var range = window.document.createRange()
    range.setStart(elem, start)
    range.setEnd(elem, stop)

    return range.getClientRects()
}

private fun findStep(elem: Node, p: Point, start: Int, stop: Int, onlyIn: Box? = null): Int {
    val prefix = "findStep:${start}:${stop}"

    if (start == stop) {
//        console.log("${prefix} start == stop return ${start}")
        return start
    }
    if (start == stop - 1) {
//        console.log("${prefix} 1rect")
        val rects = getRectsBySubstring(elem, start, stop)
        val rect = pointInRects(p, rects, onlyIn)
        rect?.let {
            if (it.isCloserToRightBorder(p)) {
//                console.log("${prefix} 1rect found return right (${stop})")
                return stop
            }
//            console.log("${prefix} 1rect found return left (${start})")
            return start
         } ?: run {
//            console.log("${prefix} 1rect not found return left (${start})")
            return start
        }
    }

    val half = round((stop - start) / 2.0 + start).toInt()
    // not getBoundingClientRect as word could wrap
    var rects = getRectsBySubstring(elem, start, half)
//    for( r in rects) { drawDebugBorder(Box(r),"gray") }
    if (pointInRects(p, rects, onlyIn) != null) {
        // click is in first half
//        console.log("${prefix} '${elem.textContent?.substring(start, stop)}' -> '${elem.textContent?.substring(start, half)}'")
        return findStep(elem, p, start, half, onlyIn)
    } else {
//        console.log("${prefix} '${elem.textContent?.substring(start, stop)}' -> '${elem.textContent?.substring(half, stop)}'")
        return findStep(elem, p, half, stop, onlyIn)
    }
}

private fun pointInRects(p: Point, rects: Array<DOMRect>, onlyIn: Box? = null): DOMRect? {
    for (r in rects) {
        if (onlyIn != null && r !in onlyIn)
            continue
        if (p in r)
            return r
    }
    return null;
}


/**
 * negative offset means from the end, e.g. -1 - last character. Caret-aware.
 */
fun Fragment.StyledSpan.yRangeAtDOM(signedOffset: Int): ClosedRange<Double> {
    var offset = signedOffset
    if (offset < 0) offset = this.text.length + offset
    if (offset < 0) offset = 0
    if (offset > text.length)
        throw Exception("invalid offset $signedOffset for text of length ${text.length}: $text")

    val fe = window.document.getElementById(guid) ?: throw Exception("element not found for fragment")

    for (i in 0..3) {
        var elem = fe.childNodes[i]

        if (elem == null) throw Exception("childNodes not found for offset, this is a bug ($offset, ${fe.outerHTML}")

        if (elem is HTMLElement && elem.hasClass("blinking-cursor")) continue

        elem.textContent?.let { s ->
            if (s.length > offset) {
                // finally we've cound an elem which contains requested offset
                var range = window.document.createRange()
                range.setStart(elem, offset)
                range.setEnd(elem, offset + 1)

                // not getBoundingClientRect as word could wrap
                return range.getClientRects().firstOrNull()?.let { it.top..it.bottom }
                    ?: throw Exception("failed to get a range for fragment at $offset")
            }
            // else: this text os too short (normal when span is splitted by the cursor
            // accumulate the shift and continue...
            offset -= s.length
        }
    }
    // we normally should not get there
    throw Exception("failed to calculate yRangeAt: inner bug")
}

fun drawDebugBorder(box: Box, color: String, width: Double? = null) {
    val tableRectDiv = window.document.createElement("div") as HTMLDivElement;
    tableRectDiv.style.position = "absolute"
    tableRectDiv.style.border = "${width ?: 1}px solid ${color}"

//    val scrollPoint = Point(window.scrollX, window.scrollY)
//    println("scrollpoint! $scrollPoint")

    if (width == null) {
        tableRectDiv.style.background = "$color"
        tableRectDiv.style.opacity = "0.5"
    } else {
        tableRectDiv.style.opacity = "0.7"

    }
//    val scrollTop = window.document.documentElement?.scrollTop ?: window.document.body?.scrollTop ?: 0.0;
//    val scrollLeft = window.document.documentElement?.scrollLeft ?: window.document.body?.scrollLeft ?: 0.0;
    tableRectDiv.style.margin = "0"
    tableRectDiv.style.padding = "0"
    tableRectDiv.style.top = "${box.y0}px"
    tableRectDiv.style.left = "${box.x0}px"
    // We want rect.width to be the border width, so content width is 2px less.
    tableRectDiv.style.width = "${box.width}px"
    tableRectDiv.style.height = "${box.height}px"
    tableRectDiv.classList.add("debug-box-marker")
    window.document.body?.appendChild(tableRectDiv);
}

fun deleteAllDebugMarkers() {
    val elements = mutableListOf<Element>()
    window.document.body?.getElementsByClassName("debug-box-marker")?.let { x ->
        for (i in 0 until x.length) {
            x.get(i)?.let { elements.add(it) }
        }
    }
    for (e in elements) e.remove()
}

fun isClickTargetId(id: String, el: Element): Boolean {
    return el.id == id || el.parentElement?.let { isClickTargetId(id, it) } ?: false
}
fun isClickTargetId(id: String, ev: Event): Boolean {
    if (ev.target == null) return false
    return isClickTargetId(id, ev.target as Element)
}

fun isClickTargetClass(className: String, el: Element): Boolean {
    return el.classList.contains(className) || el.parentElement?.let { isClickTargetClass(className, it) } ?: false
}

fun isClickTargetClass(className: String, ev: Event): Boolean {
    if (ev.target == null) return false
    return isClickTargetClass(className, ev.target as Element)
}

suspend fun readFile(file: File) = suspendCoroutine { continuation ->
    val reader = FileReader()

    reader.onload = { e: Event ->
        val bin = reader.result as ArrayBuffer
        continuation.resume(Uint8Array(bin).toByteArray())
    }

    reader.onerror = { e: Event ->
        continuation.resumeWithException(BugException(e.toString()))
    }

    reader.readAsArrayBuffer(file)
}

//fun BlockBox(block: IParagraph): Box? {
//    val blockElement = window.document.getElementById(block.guid)
//    return blockElement?.let {
//        Box(it.getBoundingClientRect())
//    }
//}

fun ElementBox(element: Element): Box {
    return Box(element.getBoundingClientRect())
}

fun BlockBox(block: IParagraph): Box? {
    val blockElement = window.document.getElementById(block.guid)
    return blockElement?.let { ElementBox(it) }
}

fun ParentBlockBox(block: IParagraph): Box? {
    val blockElement = window.document.getElementById(block.guid)
    return blockElement?.parentElement?.let { ElementBox(it) }
}

class TagBuilderImplementation<TElement : Element>(private val tagName: String) : ElementBuilder<TElement> {
    private val el: Element by lazy { document.createElement(tagName) }
    override fun create(): TElement = el.cloneNode() as TElement
}

val U: ElementBuilder<HTMLElement> = TagBuilderImplementation("u")

@Composable
fun U(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null
) {
    TagElement(
        elementBuilder = U,
        applyAttrs = attrs,
        content = content
    )
}