package net.sergeych.parsec3

import net.sergeych.boss_serialization.BossDecoder
import net.sergeych.boss_serialization_mp.BossEncoder
import kotlin.reflect.typeOf

/**
 * The class that provides parsec3 commands for remote callers. Could be used as is oar as a base
 * class. For more information see [Adapter] class. The basic usage pattern is:
 * ~~~
 *     class Api1: CommandHost<Unit>() {
 *     // create command `foo` that takes a string argument and
 *     // returns a string:
 *        val foo by command<String,String>()
 *     }
 * ~~~
 *
 * Then somewhere else (usually before initializing network connection) provide implementation for
 * the declared commands:
 * ~~~
 *      val api1 = Api1()
 *      val api2 = Api2()
 *      api1.on(api1.foo) {
 *         it + "foo"
 *      }
 * ~~~
 *
 * The good strategy is to create _one Api implementation_, if need using session type [T] to provide
 * state-aware behavior, and share it when creating adapters on, say, incoming connections.
 *
 * @param T the type of the `state` instance used to hold state, use `Unit` for stateless interfaces
 */
open class CommandHost<T: WithAdapter> {
    private val handlers = mutableMapOf<String, suspend T.(ByteArray) -> ByteArray>()

    /**
     * Provide implementation for a specific command in a type-safe compile-time checked manner.
     * The command should be declared with [command] invocation.
     *
     * Normally, existing command can't be replaced by new handlers and attempt to do so will throw
     * [DuplicateCommandDefinition] at runtime. If it is done intentionally, set [overwrite] to
     * true.
     */
    fun <A, R> on(ca: CommandDescriptor<A, R>, overwrite: Boolean = false, block: suspend T.(A) -> R) {
        if( ca.name in handlers && !overwrite )
            throw DuplicateCommandDefinition("command already defined: ${ca.name}")
        handlers[ca.name] = {args ->
            val decodedArgs = BossDecoder.decodeFrom<A>(ca.ass, args)
            BossEncoder.encode(ca.rss, block(decodedArgs))
        }
    }

    fun handler(name: String) = handlers.get(name) ?: throw CommandNotFoundException(name)

    /**
     * Provide a command delegate that creates type-safe command descriptor containint command name and
     * types of it arguments and return value.
     */
    inline fun <reified A, reified R> command(name: String? = null): AdapterDelegate<T, A, R> {
        return AdapterDelegate(
            name,
            typeOf<A>(),
            typeOf<R>()
        )
    }
}