package net.sergeych.intecowork.doc

import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.boss_serialization_mp.decodeBoss
import net.sergeych.intecowork.api.ApiBlock
import net.sergeych.intecowork.api.BlockItem
import net.sergeych.intecowork.api.BlockType
import net.sergeych.unikrypto.randomId

/**
 * Decrypted (plaintext) block. This is what application software normally uses.
 *
 * It should not get out of the client process, so it is not
 * serializable. It implements [BlockItem] interface and add [plainData] field which
 * is non-encrypted data (while [data] is still encrypted, or null).
 *
 */
class DocBlock(
    val block: ApiBlock,
    val plainData: ByteArray?,
) : BlockItem by block {

    constructor(
        type: BlockType,
        plainData: ByteArray,
        utag: String? = null,
        tag: String? = null,
        prevGuid: String? = null,
        nextGuid: String? = null,
        serial: Long = 0,
        guid: String = randomId(18),
    ) : this(
        ApiBlock(type, serial, guid, nextGuid, prevGuid, tag, utag, null, 0),
        plainData
    )

    /**
     * Encrypt [plainData] using specified [doc] keys. Sets [docId] to the proper document's
     * id, if it is null, or throw exception to prevent crossdocument usage of blocks.
     *
     * @param doc document to encrypt block for.
     * @return encrypted and properly set [ApiBlock]
     * @throws [IllegalArgumentException]
     */
    fun encryptFor(doc: IcwkDocument): ApiBlock {
        val b = block
        if( b.docId != null && doc.docId != b.docId )
            throw IllegalArgumentException("попытка зашифровать блок принадлежащий другому документу")
        return ApiBlock.encrypt(
            b.type, doc.docKey, plainData, prevGuid = b.prevGuid, nextGuid = b.nextGuid,
            tag = b.tag, utag = b.utag, guid = b.guid, docId = doc.docId
        )
    }

    /**
     * Who created this version of the block
     */
    val authorId: Long by lazy { block.authorId }

    /**
     * Create a copy of the block with new payload (changes [plainData] accordingly)
     */
    inline fun <reified T: Any>withPayload(payload: T): DocBlock {
        return DocBlock(block.copy(data=null), BossEncoder.encode(payload))
    }

    /**
     * Decode data contents from plainData
     */
    inline fun <reified T : Any> decode(): T? = plainData?.decodeBoss<T>()

    override fun toString(): String =
        "DBk:$guid:$serial:$type"

    companion object {
        /**
         * Create a block by encoding a specified [payload] into [plainData].
         */
        inline fun <reified T : Any> pack(
            type: BlockType,
            utag: String? = null,
            tag: String? = null,
            prev: String? = null,
            next: String? = null,
            serial: Long = 0,
            guid: String = randomId(18),
            docId: Long? = null,
            payload: () -> T,
        ): DocBlock {
            return DocBlock(
                ApiBlock(type, serial, guid, next, prev, tag, utag, null, docId),
                BossEncoder.encode(payload())
            )
        }

        /**
         * Creates simply list block structure from a list of docblocks, useful to
         * create small documents for testing, etc.
         */
        fun link(vararg source: DocBlock): List<DocBlock> = link(source.toList())
        /**
         * Creates simply list block structure from a list of docblocks, useful to
         * create small documents for testing, etc.
         */
        fun link(source: List<DocBlock>): List<DocBlock> =
            source.mapIndexed { i, b ->
                DocBlock(
                    b.block.copy(
                        prevGuid = if (i > 0) source[i - 1].guid else null,
                        nextGuid = if (i < source.size - 1) source[i + 1].guid else null
                    ),
                    b.plainData
                )
            }

    }
}