package editor.operations

import document.*
import editor.*
import tools.randomId
import views.TextStyleMixin

suspend fun DocContext.cutSelection() {
    replay.cutSelection()

    runTransaction("CUT") { chain, style ->
        selection.range?.let { range ->
            chain.copySelection(range)?.let {
                selection.setCopy(it)
                selection.clear()
                chain.delete(range)
            }
        }
    }
}

// only for use in transaction
fun DocContext.deleteSelectionIfExists(chain: DocChain) {
    selection.range?.let {
        selection.clear()
        chain.delete(it)
    }
}

fun DocContext.select(r: CRange) {
    selection.select(r)
}

suspend fun DocContext.selectAll() {
    doc.withLock("selectAll") {
        getCRange(doc.firstBlock.caretAtStart(), doc.lastBlock.caretAtEnd())?.let {
            selection.select(it)
        }
    }
}

suspend fun DocContext.charAtPointDOM(p: Point, pCaret: Caret): CRange? {
    val isReady = domObserver.waitAll("charAtPointDOM")
    if (!isReady) return null

    val caretBox = caretBoxDOM(pCaret) ?: return null
    val block = caretBlock(pCaret)

    // caret is to the left from target
    return if (caretBox.center.x < p.x) {
        block.caretRight(pCaret)?.let { getCRange(pCaret, it) }
    } else {
        block.caretLeft(pCaret)?.let { getCRange(it, pCaret) }
    }
}

fun shouldStop(initialChar: Char, nextChar: Char): Boolean {
    if (initialChar.isLetter() || initialChar.isDigit()) {
        val stops = listOf(
            ' ', '.', ',', ':', ';', '!', '?', '$', '(', ')',
            '@', '#', '$', '%', '^', '&', '*', '=', '+',
            '\'', '"', '/', '\\', '|', '`', '~', '>', '<',
            '”', '“',
            '\n'
        )

        return stops.contains(nextChar)
    } else {
        if (initialChar == ' ') {
            return nextChar != ' '
        }

        return nextChar.isLetter() || nextChar.isDigit() || nextChar == '\n'
    }
}

fun DocContext.wordAtChar(charRange: CRange): CRange? {
    console.log("word at char")
    val block = caretBlock(charRange.left)
    val plainText = block.plainText
    val offsetLeft = block.getLocalOffsetCaret(charRange.left)

    var wordStart = offsetLeft.offset
    var wordEnd = offsetLeft.offset + 1
    val initialChar = plainText[wordStart]

    var leftSpan: Fragment.StyledSpan = block.caretSpan(charRange.left)
    var leftOffset = charRange.left.offset
    var leftStop = false

    if (leftSpan.isLink()) return null

    fun checkFragment(t: Fragment): Boolean {
        val path = block.getAddress(t)

        return when(charRange.left.rootType) {
            CaretRoot.TABLE -> path.contains(charRange.left.cellId)
            else -> true
        }
    }

    fun getNextText(guid: String): Fragment.StyledSpan? {
        var found: Fragment.StyledSpan? = null
        var done = false
        var current = guid

        while(!done) {
            val tf = block.nextTerminalFragment(current)
            if (tf == null) done = true
            else if (tf !is Fragment.StyledSpan) done = true
            else if (!checkFragment(tf)) done = true
            else if (tf.text.isNotEmpty()) {
                found = tf
                done = true
            } else current = tf.guid
        }

        return found
    }

    fun getPrevText(guid: String): Fragment.StyledSpan? {
        var found: Fragment.StyledSpan? = null
        var done = false
        var current = guid

        while(!done) {
            val tf = block.prevTerminalFragment(current)
            if (tf == null) done = true
            else if (tf !is Fragment.StyledSpan) done = true
            else if (!checkFragment(tf)) done = true
            else if (tf.text.isNotEmpty()) {
                found = tf
                done = true
            } else current = tf.guid
        }

        return found
    }

    while(!leftStop) {
        val char = leftSpan.text[leftOffset]

        if (!shouldStop(initialChar, char)) {
            if (leftOffset == 0) {
                val prev = getPrevText(leftSpan.guid)

                prev?.let {
                    if (it.isLink()) {
                        leftStop = true
                    } else {
                        leftSpan = it
                        leftOffset = leftSpan.lastOffset - 1
                    }
                } ?: run { leftStop = true }

            } else leftOffset -= 1
        } else {
            leftStop = true
            leftOffset += 1
        }
    }

    var rightSpan: Fragment.StyledSpan = block.caretSpan(charRange.right)
    var rightOffset = charRange.right.offset
    var rightStop = false

    while(!rightStop) {
        val char = rightSpan.text[rightOffset]

        if (!shouldStop(initialChar, char)) {
            if (rightOffset >= rightSpan.lastOffset - 1) {
                val next = getNextText(rightSpan.guid)

                if (next != null) {
                    if (next.isLink()) {
                        rightStop = true
                    }
                    else {
                        rightSpan = next
                        rightOffset = 0
                    }
                } else {
                    rightOffset = rightSpan.lastOffset
                    rightStop = true
                }

            } else rightOffset += 1
        } else {
            rightStop = true
        }
    }

    val left = block.caretAt(leftSpan.guid, leftOffset) ?: throw BugException("Can't get left word caret")
    val right = block.caretAt(rightSpan.guid, rightOffset) ?: throw BugException("Can't get right word caret")

    return getCRange(left, right)
}

suspend fun DocContext.wordAtPointDOM(p: Point, pCaret: Caret): CRange? {
    return charAtPointDOM(p, pCaret)?.let {
        wordAtChar(it)
    }
}

fun deleteInParagraphL0(paragraph: IParagraph, range: CRange): IParagraph {
    val left = range.left
    val right = range.right

    var leftSpan = paragraph.caretSpan(left)
    var rightSpan = paragraph.caretSpan(right)
    val leftText = leftSpan.textLeft(left)
    val rightText = rightSpan.textRight(right)

    if (leftSpan.guid == rightSpan.guid) {
        leftSpan = leftSpan.copy(text = leftText + rightText)

        return paragraph.replaceFragment(leftSpan)
    }

    var updated = paragraph
    var terminalFragment = paragraph.nextTerminalFragment(leftSpan)

    while (terminalFragment != null && terminalFragment.guid != right.spanId) {
        updated = updated.removeFragment(terminalFragment.guid)
        terminalFragment = paragraph.nextTerminalFragment(terminalFragment)
    }

    leftSpan = leftSpan.copy(text = leftText)
    rightSpan = rightSpan.copy(text = rightText)
    updated = updated.replaceFragment(leftSpan)
    updated = updated.replaceFragment(rightSpan)

    return updated
}

fun DocChain.deleteInParagraph(range: CRange) {
    val left = range.left
    var right = range.right

    val leftBlock = caretBlock(left)
    var resultBlock = leftBlock

    if (left.blockId != right.blockId) {
        val rightBlock = caretBlock(right)
        var cursorBlock = next(leftBlock)

        while (cursorBlock != null && cursorBlock.guid != rightBlock.guid) {
            delete(cursorBlock.guid)
            cursorBlock = next(cursorBlock)
        }

        resultBlock = leftBlock.appendContent(rightBlock)
        update(resultBlock)
        delete(rightBlock.guid)
        // right is in left block now, but other path fragments moved as is
        right = right.copy(path = right.path.drop(1)).withParent(leftBlock.guid)
    }

    getCRange(left, right)?.let {
        val paragraph = deleteInParagraphL0(resultBlock.paragraph, it)
        resultBlock = resultBlock.withUpdatedParagraph(paragraph)

        updateBlockAndClean(resultBlock, left)
    }
}

fun DocChain.deleteInCell(range: CRange) {
    var left = range.left
    var right = range.right

    if (left.blockId != right.blockId || left.path[1] != right.path[1])
        throw BugException("range in different cells")

    var block = caretBlock(left)
    val leftContainer = block.caretContainer(left)

    var resultContainer = leftContainer as IParagraph

    if (leftContainer.guid != right.containerId) {
        val rightContainer = block.caretContainer(right)
        var cell = block.find(left.path[1]) as? IParagraph
            ?: throw BugException("Can't find cell paragraph in table")

        val leftIndex = cell.indexOf(left.containerId)
        val rightIndex = cell.indexOf(right.containerId)
        val cellElements = cell.elements

        for (i in (leftIndex + 1) .. rightIndex) {
            cell = cell.removeFragment(cellElements[i].guid)
        }

        var updatedLeft: IParagraph = leftContainer

        var lEnd = leftContainer.caretAtEnd()
        lEnd = lEnd.copy(path = listOf(left.blockId, left.cellId) + lEnd.path)
        getCRange(left, lEnd)?.let {
            left = it.left
            updatedLeft = deleteInParagraphL0(leftContainer, it)
        }

        var updatedRight: IParagraph = rightContainer
        var rStart = rightContainer.caretAtStart()
        rStart = rStart.copy(path = listOf(right.blockId, right.cellId) + rStart.path)
        getCRange(rStart, right)?.let {
            updatedRight = deleteInParagraphL0(rightContainer, it)
        }

        updatedLeft += updatedRight
        cell = cell.replaceFragment(updatedLeft.toFragment())
        resultContainer = cell
    } else {
        resultContainer = deleteInParagraphL0(leftContainer, range)
    }

    block = block.withUpdatedFragment(resultContainer.toFragment())
    updateBlockAndClean(block, left)
}

fun DocChain.delete(range: CRange?) {
    if (range == null || range.left == range.right) return
    val logger = log("delete")
    logger("run")

    if (range.left.rootType != range.right.rootType)
        throw BugException("range of carets with different root type")

    when(range.left.rootType) {
        CaretRoot.TABLE -> deleteInCell(range)
        CaretRoot.PARAGRAPH -> deleteInParagraph(range)
        CaretRoot.TEMPORARY -> throw BugException("Can't use temporary caret in operations")
    }
    logger("done")
}

fun copyTerminalFragment(tf: Fragment): Fragment {
    val guid = randomId(11)

    return when (tf) {
        is Fragment.StyledSpan -> tf.copy(guid = guid)
        is Fragment.Frame -> tf.copy(guid = guid)
        is Fragment.StoredImage -> tf.copy(guid = guid)
        is Fragment.LinkedImage -> tf.copy(guid = guid)
        else -> { throw BugException("Paragraph L0 contains IParagraph") }
    }
}

fun copyFragment(f: Fragment): Fragment {
    val guid = randomId(11)

    return when (f) {
        is Fragment.StyledSpan -> f.copy(guid = guid)
        is Fragment.Frame -> f.copy(guid = guid)
        is Fragment.StoredImage -> f.copy(guid = guid)
        is Fragment.LinkedImage -> f.copy(guid = guid)
        is Fragment.Paragraph -> {
            val nested = f.elements.map { copyFragment(it) }
            f.makeCopy(nested, guid = guid)
        }
        is Fragment.TableParagraph -> {
            val nested = f.elements.map { copyFragment(it) }
            f.makeCopy(nested, guid = guid)
        }
    }
}

data class ParagraphCleanResult(
    val paragraph: IParagraph,
    val caret: Caret? = null
)

data class BlockCleanResult(
    val block: Block,
    val caret: Caret? = null
)

fun cleanBlock(b: Block, c: Caret? = null, debug: Boolean = DebugList.cleaner): BlockCleanResult {
    val cleaned = clean(b, c, debug)

    return BlockCleanResult(cleaned.paragraph as Block, cleaned.caret)
}

/**
 * Glues neighbour styled spans with identical style and remove empty spans
 */
fun clean(paragraph: IParagraph, caret: Caret? = null, debug: Boolean = DebugList.cleaner): ParagraphCleanResult {
    fun log(msg: String) {
        if (debug) console.warn("[CLEAN]: $msg")
    }

    return when (paragraph) {
        is Fragment.Paragraph -> {
            if (paragraph.elements.size == 1) ParagraphCleanResult(paragraph, caret)
            else {
                val updated = mutableListOf<Fragment>()
                var i = 1
                val firstElement = paragraph.elements[0]
                if (firstElement !is Fragment.StyledSpan)
                    throw BugException("First element of L0 ${paragraph.guid} is not StyledSpan ${firstElement}")

                log("Before BEGIN")
                paragraph.elements.forEach { log(it.guid) }
                log("Before CARET: ${caret?.toFullString()}")
                log("Before END")

                var sum: Fragment.StyledSpan? = firstElement

                while (i < paragraph.elements.size) {
                    val el = paragraph.elements[i]

                    if (el is Fragment.StyledSpan) {
                        if (sum != null) {
                            val canGlueEmpty = sum.isEmpty || el.isEmpty
                            val canGlueStyle = sum.textStyle == el.textStyle && sum.href == el.href

                            if ((canGlueStyle || canGlueEmpty) && sum.href == el.href) {
                                if (sum.isEmpty) {
                                    sum = sum.copy(text = el.text, textStyle = el.textStyle, href = el.href)
                                } else if (el.isEmpty) {
                                    sum = sum.copy(text = sum.text + el.text)
                                } else sum = sum.copy(text = sum.text + el.text)
                            }
                            else {
//                                if (sum != null && sum.text.isEmpty() && sum.isLink()) {
//                                    sum = el
//                                } else {
                                    updated.add(sum)
                                    sum = el
//                                }
                            }
                        } else {
                            sum = el
                        }
                    } else {
                        sum?.let { updated.add(it) }
                        sum = null
                        updated.add(el)
                    }
                    i++
                }

                if (sum != null) updated.add(sum)

//                console.log("OPTIMIZE UPDATED BEGIN")
//                updated.forEach {
//                    if (it is Fragment.StyledSpan) console.log("UPDATED ${it.guid}, '${it.text}', ${it.textStyle}")
//                    else console.log("terminal")
//                }
//                console.log("OPTIMIZE UPDATED END")

                var newCaret: Caret? = null
                if (caret != null && caret.path.indexOf(paragraph.guid) != -1) {
                    val updatedCaretSpan = updated.find { it.guid == caret.spanId } as Fragment.StyledSpan?

                    if (updatedCaretSpan == null || updatedCaretSpan.lastOffset < caret.offset) {
                        val oldSpan = paragraph.find(caret.spanId)
                        val indexOfP = caret.path.indexOf(paragraph.guid)
                        val localOffset = paragraph.getLocalOffsetCaret(caret).offset
                        val updatedP = paragraph.makeCopy(updated)
                        var localCaret = updatedP.caretAt(
                            localOffset,
                            caret.offset == 0 && oldSpan?.lastOffset != 0
                        ) ?: throw BugException("Can't restore local caret")

                        // sometimes we need right version of caret of the same offset

                        val newPath = caret.path.slice(0 until indexOfP) + localCaret.path
                        newCaret = Caret(newPath, localCaret.offset)
                    }
//                    console.log("OPTIMIZE NEW CARET ${newCaret?.toFullString()}")
                }

                log("After BEGIN")
                updated.forEach { log(it.guid) }
                log("After CARET: ${(newCaret ?: caret)?.toFullString()}")
                log("After END")

//                console.log("OPTIMIZE DONE")
                ParagraphCleanResult(paragraph.makeCopy(updated), newCaret ?: caret)
            }

        }
        is Fragment.TableParagraph -> {
            var newCaret: Caret? = null

            val elements = paragraph.elements.map {
                if (it !is IParagraph) throw BugException("Broken table cell")
                var cell = it as IParagraph

                val brokenP = cell.elements.find { it !is Fragment.Paragraph }
                if (brokenP != null) throw BugException("Broken table cell content")

                val paragraphs = cell.elements as List<Fragment.Paragraph>
                val updated = paragraphs.map {
                    val op = clean(it, caret, debug)
                    if (op.caret != null && op.caret != caret) newCaret = op.caret
                    op.paragraph.toFragment()
                }

                cell.makeCopy(updated).toFragment()
            }
            ParagraphCleanResult(paragraph.makeCopy(elements), newCaret ?: caret)
        }
        is Block -> {
            val cleaned = clean(paragraph.paragraph, caret, debug)
            ParagraphCleanResult(paragraph.withUpdatedParagraph(cleaned.paragraph), cleaned.caret)
        }
    }
}

/**
 * Copies content in range of IParagraph L1 (all elements are IParagraph lvl0)
 * IParagraph L0 - all elements are terminal fragments
 */
fun copyL0Selection(p: IParagraph): IParagraph {
    val elements = p.elements.map { copyTerminalFragment(it) }

    return p.makeCopy(elements, guid = randomId(11))
}

/**
 * Copies content in range of IParagraph L1 (all elements are IParagraph lvl0)
 * IParagraph L0 - all elements are terminal fragments
 */
fun copyL0Selection(p: IParagraph, range: CRange): IParagraph {
    val left = range.left
    val right = range.right
    val elements = mutableListOf<Fragment>()

    val leftSpan = p.find(left.spanId) as Fragment.StyledSpan?
        ?: throw BugException("caret span not in paragraph")
    val rightSpan = p.find(right.spanId) as Fragment.StyledSpan?
        ?: throw BugException("caret span not in paragraph")

    if (leftSpan.guid == rightSpan.guid) {
        val text = leftSpan.text.substring(left.offset, right.offset)
        elements.add(leftSpan.copy(guid = randomId(11), text = text))
    } else {
        val rightText = leftSpan.textRight(left)
        if (rightText.length > 0) {
            elements.add(leftSpan.copy(guid = randomId(11), text = rightText))
        }

        var tf = p.nextTerminalFragment(leftSpan)

        while(tf != null && tf.guid != right.spanId) {
            elements.add(copyTerminalFragment(tf))

            tf = p.nextTerminalFragment(tf)
        }

        if (tf !is Fragment.StyledSpan) throw BugException("Paragraph L0 last element is not StyledSpan")

        val leftText = tf.textLeft(right)

        if (leftText.length > 0) {
            elements.add(rightSpan.copy(guid = randomId(11), text = leftText))
        }
    }

    return p.makeCopy(elements, guid = randomId(11))
}

/**
 * Copies content in range of IParagraph L1 (all elements are IParagraph L0)
 * IParagraph L0 (all elements are terminal fragments)
 */
fun copyL1Selection(p: IParagraph, range: CRange): List<IParagraph> {
    val left = range.left
    val right = range.right
    val elements = mutableListOf<IParagraph>()

    val brokenChild = p.elements.find { it !is IParagraph }
    if (brokenChild != null) throw BugException("IParagraph lvl1: child is not IParagraph")

    val pElements = p.elements as List<IParagraph>

//    val leftContainer = p.find(left.path[left.path.size - 2]) as? IParagraph
//        ?: throw BugException("Can't find left container")
//    val rightContainer = p.find(left.path[left.path.size - 2])  as? IParagraph
//        ?: throw BugException("Can't find left container")

    val leftContainerIndex = pElements.indexOfFirst { it.guid == left.path.dropLast(1).last() }
    val rightContainerIndex = pElements.indexOfFirst { it.guid == right.path.dropLast(1).last() }
    var leftContainer = pElements[leftContainerIndex]
    var rightContainer = pElements[rightContainerIndex]

    if (leftContainerIndex == rightContainerIndex) {
        console.log("Copy L1: same container")
        elements.add(copyL0Selection(leftContainer, CRange(left, right)))
    } else {
        console.log("Copy L1: diff container")
        elements.add(
            copyL0Selection(leftContainer,
            CRange(left, leftContainer.caretAtEnd())
        )
        )

        for (i in (leftContainerIndex + 1) until rightContainerIndex) {
            elements.add(copyL0Selection(p.elements[i] as IParagraph))
        }

        elements.add(
            copyL0Selection(rightContainer,
            CRange(rightContainer.caretAtStart(), right)
        )
        )
    }

    return elements
}

fun Chain<Block>.copyCellSelection(range: CRange): List<IParagraph> {
    val logger = log("copyCellSelection")
    logger("run")
    val left = range.left
    val right = range.right

    val cellId = left.path[1]

    if (left.blockId != right.blockId) throw BugException("Cell CRange are in different blocks")
    if (cellId != right.path[1]) throw BugException("Cell CRange are in different cells")

    val block = caretBlock(left)
    val cell = block.find(cellId) as IParagraph? ?: throw BugException("Cell is not in block")

    return copyL1Selection(cell, range).also { logger("done") }
}

fun copy(block: Block): Block {
    val p = copyFragment(block.paragraph.toFragment())

    return Block(p as IParagraph, null, null)
}

fun Chain<Block>.copyParagraphSelection(range: CRange): List<IParagraph> {
    val logger = log("copyParagraphSelection")
    logger("run")
    val left = range.left
    val right = range.right

    val leftBlock = caretBlock(left)
    val leftParagraph = leftBlock.paragraph

    if (left.blockId == right.blockId) {
        val copy = copyL0Selection(leftParagraph, range)
        return listOf(copy)
    }

    val paragraphs = mutableListOf<IParagraph>()
    val leftCopy = copyL0Selection(leftParagraph,
        CRange(left, leftParagraph.caretAtEnd())
    )
    paragraphs.add(leftCopy)
    var next = next(leftBlock)

    while (next != null && next.guid != right.blockId) {
        // TODO: copy table and how to insert table in table?
        if (next.paragraph !is Fragment.TableParagraph) paragraphs.add(copyL0Selection(next.paragraph))
//        paragraphs.add(copyFragment(next.paragraph.toFragment()) as IParagraph)
        next = next(next)
    }

    if (next == null) throw BugException("Can't reach right caret block")

    val rightParagraph = next.paragraph
    val rightCopy = copyL0Selection(rightParagraph,
        CRange(rightParagraph.caretAtStart(), right)
    )
    paragraphs.add(rightCopy)

    return paragraphs.also { logger("done") }
}

fun Chain<Block>.copySelection(range: CRange?): List<IParagraph>? {
    val logger = log("copySelection")
    logger("run")
    if (range == null) return null

    logger("COPY RANGE: ${range.left.toFullString()} to ${range.right.toFullString()}")

    val left = range.left

    if (left.rootType != range.right.rootType)
        throw BugException("CRange carets has different root types")

    return when (left.rootType) {
        CaretRoot.TABLE -> copyCellSelection(range)
        CaretRoot.PARAGRAPH -> copyParagraphSelection(range)
        CaretRoot.TEMPORARY -> throw BugException("Can't use temporary caret in operations")
    }.also { logger("done") }
}

fun Chain<Block>.pasteInCell(copied: List<IParagraph>, c: Caret) {
    val logger = log("pasteInCell")
    logger("run")
    val leftPId = c.path[2]
    val rightPId = splitTableCellParagraph(c)
    var tableBlock = caretBlock(c)
    var leftParagraph = tableBlock.find(leftPId) as IParagraph?
        ?: throw BugException("Can't find cell left paragraph")
    var rightParagraph = tableBlock.find(rightPId) as IParagraph?
        ?: throw BugException("Can't find cell right paragraph")

    if (copied.size == 1) {
        leftParagraph = leftParagraph + copied.first() + rightParagraph
        tableBlock = tableBlock.withUpdatedFragment(leftParagraph.toFragment())
        tableBlock = tableBlock.withoutFragment(rightPId)

        val firstText = rightParagraph.firstTextFragment()
        var caretAfter = tableBlock.caretAt(firstText.guid, 0)

        updateBlockAndClean(tableBlock, caretAfter)
    } else {
        val first = copied.first()
        val last = copied.last()

        leftParagraph += first
        tableBlock = tableBlock.withUpdatedFragment(leftParagraph.toFragment())

        rightParagraph = rightParagraph.makeCopy(elements = last.elements + rightParagraph.elements)
        tableBlock = tableBlock.withUpdatedFragment(rightParagraph.toFragment())

        val lastInsertedText = last.lastTextFragment()
        val lastInsertedTerminal = last.lastTerminalFragment()
        val firstRightText = tableBlock.nextTextFragment(lastInsertedText)
            ?: throw BugException("right paragraph has no text fragments")

        var caretAfter =
            if (lastInsertedTerminal is Fragment.StyledSpan)
                tableBlock.caretAt(lastInsertedTerminal.guid, lastInsertedTerminal.lastOffset)
            else
                tableBlock.caretAt(firstRightText.guid, 0)

        if (caretAfter == null) throw BugException("Can't calculate after caret")

        val cell = tableBlock.find(c.path[1]) as Fragment.Paragraph? ?: throw BugException("Can't find cell")
        val leftPIndex = cell.elements.indexOfFirst { it.guid == leftParagraph.guid }
        val rightPIndex = cell.elements.indexOfFirst { it.guid == rightParagraph.guid }
        val leftCellElements = cell.elements.slice(0 .. leftPIndex)
        val rightCellElements = cell.elements.slice(rightPIndex until cell.elements.size)
        val insertParagraphs = copied.slice(1 until (copied.size - 1)) as List<Fragment>
        val updatedCell = cell.copy(leftCellElements + insertParagraphs + rightCellElements)
        tableBlock = tableBlock.withUpdatedFragment(updatedCell)

        updateBlockAndClean(tableBlock, caretAfter)
    }
}

fun makeNewCopy(f: Fragment): Fragment {
    val guid = randomId(11)

    return when (f) {
        is Fragment.StyledSpan -> f.copy(guid = guid)
        is Fragment.LinkedImage -> f.copy(guid = guid)
        is Fragment.StoredImage -> f.copy(guid = guid)
        is Fragment.Frame -> f.copy(guid = guid)
        is Fragment.Paragraph -> makeNewCopy(f as IParagraph).toFragment()
        is Fragment.TableParagraph -> makeNewCopy(f as IParagraph).toFragment()
    }
}

fun makeNewCopy(p: IParagraph): IParagraph {
    val guid = randomId(11)
    val elements = p.elements.map { makeNewCopy(it) }

    return when(p) {
        is Fragment.Paragraph -> p.makeCopy(elements, guid = guid)
        is Fragment.TableParagraph -> p.makeCopy(elements, guid = guid)
        is Block -> { throw BugException("Can't make new copy of block") }
    }
}

fun makeNewCopy(copied: List<IParagraph>): List<IParagraph> {
    return copied.map { makeNewCopy(it) }
}

fun Chain<Block>.pasteInParagraph(copied: List<IParagraph>, c: Caret) {
    val logger = log("pasteInParagraph")
    logger("run")
    val leftBlockId = c.blockId
    val rightBlockId = split(c)

    var leftBlock = get(leftBlockId) ?: throw BugException("Failed to split block")
    var rightBlock = get(rightBlockId) ?: throw BugException("Failed to split block")

    if (copied.size == 1) {
        val content = copied.first()
        val lastContentText = content.lastTextFragment()

        val leftParagraph = leftBlock.paragraph + copied.first() + rightBlock.paragraph
        leftBlock = leftBlock.withUpdatedParagraph(leftParagraph)
        val caretAfter = leftBlock.caretAt(lastContentText.guid, lastContentText.lastOffset)

        delete(rightBlockId)
        updateBlockAndClean(leftBlock, caretAfter)
    } else {
        val first = copied.first()
        val last = copied.last()

        val leftParagraph = leftBlock.paragraph + first
        leftBlock = leftBlock.withUpdatedParagraph(leftParagraph)
        updateBlockAndClean(leftBlock)
        val rightParagraph = rightBlock.paragraph.makeCopy(elements = last.elements + rightBlock.paragraph.elements)
        rightBlock = rightBlock.withUpdatedParagraph(rightParagraph)

        val lastInsertedText = last.lastTextFragment()
        val lastInsertedTerminal = last.lastTerminalFragment()
        val firstRightText = rightBlock.nextTextFragment(lastInsertedText)
            ?: throw BugException("right block has no text fragments")

        var caretAfter =
            if (lastInsertedTerminal is Fragment.StyledSpan)
                rightBlock.caretAt(lastInsertedTerminal.guid, lastInsertedTerminal.lastOffset)
            else
                rightBlock.caretAt(firstRightText.guid, 0)

        if (caretAfter == null) throw BugException("Can't calculate after caret")

        updateBlockAndClean(rightBlock, caretAfter)

        var lastBlockId = leftBlock.guid

        for (i in 1..(copied.size - 2)) {
            val p = copied[i]
            val newBlock = Block(p)
            insertBlockAndClean(newBlock, lastBlockId)
            lastBlockId = newBlock.guid
        }
    }

    logger("done")
}

fun Chain<Block>.paste(copied: List<IParagraph>, atCaret: Caret? = null) {
    val logger = log("paste")
    logger("run")
    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")
//    console.log("INSERT AT ${c.toFullString()}")
    if (copied.isEmpty()) return

    val newCopy = makeNewCopy(copied)

    when(c.rootType) {
        CaretRoot.TABLE -> pasteInCell(newCopy, c)
        CaretRoot.PARAGRAPH -> pasteInParagraph(newCopy, c)
        CaretRoot.TEMPORARY -> throw BugException("Can't use temporary caret in operations")
    }
    logger("done")
}

suspend fun DocContext.copy() {
    replay.copy()

    runTransaction("COPY") { chain, style ->
        chain.copySelection(selection.range)?.let { copied ->
//            console.log("COPY BEGIN")
//            copied.forEach {
//                console.log("P BEGIN")
//                it.elements.forEach {
//                    console.log(it)
//                }
//                console.log("P END")
//            }
//            console.log("COPY END")
            selection.setCopy(copied)
        }
    }
}

suspend fun DocContext.paste(text: String, isPlain: Boolean = false) {
    replay.paste(text, isPlain)

    runTransaction("PASTE", shouldForceRecord = true) { chain, style ->
        val copied = selection.copied

        deleteSelectionIfExists(chain)

        if (copied == null || selection.getCopiedText(copied) != text) {
            chain.insertMultilineText(text, style)
        } else {
            if (isPlain) chain.insertMultilineText(selection.getCopiedText(copied), style)
            else chain.paste(copied)
        }
    }
}

suspend fun DocContext.replace(range: CRange, replaceWith: String) {
    replay.replace(range, replaceWith)

    runTransaction("REPLACE") { chain, style ->
        chain.delete(range)
        chain.insertString(replaceWith, style)
    }
}

suspend fun DocContext.setStyle(range: CRange, mixin: TextStyleMixin, shouldEnable: Boolean) {
    replay.setStyle(range, mixin, shouldEnable)

    runTransaction("SET RANGE STYLE", shouldForceRecord = true) { chain, transactionStyle ->
        chain.copySelection(range)?.let { copied ->
            selection.clear()
            val updatedCopy = copied.map { applyMixinL0(it, mixin, shouldEnable) }
            chain.delete(range)
            chain.paste(updatedCopy)
        }
    }
}